import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { randomId } from '/lib/utils';

const withPropTypes = setPropTypes({
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      text: PropTypes.node.isRequired,
    }),
  ),
});

const Select = ({ value, onChange, labelText, items, wrapStyle = {}, ...props }) => {
  const id = `select-${randomId()}`;
  return (
    <label className="bp3-label" htmlFor={id}>
      {labelText}
      <div className="bp3-select" style={{ ...wrapStyle }}>
        <select id={id} value={value} onChange={onChange} {...props}>
          {items.map(({ value: v, text }, i) => (
            <option key={`bnioreijno;n${i}`} value={v}>
              {text}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

export default compose(withPropTypes)(Select);
