import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
  Card,
  FormGroup,
  InputGroup,
  Button,
  ButtonGroup,
  Collapse,
  Intent,
  Tag,
} from '@blueprintjs/core';
import TabularResource from '/components/TabularResource';
import Tabs from '/components/Tabs';
import ResourceSelect from '/components/ResourceSelect';
import ClipboardInput from '/components/ClipboardInput';
import NodeSelect from '/components/NodeSelect';
import Debug from '/components/Debug';
import wizard from '/lib/wizard';
import { useProjects } from '/hooks';

import { AppFields, AppMutable } from '/data/fragments';

const APPS = gql`
  ${AppFields}
  query Menus {
    items: apps {
      ...AppFields
    }
  }
`;

const CREATE = gql`
  ${AppFields}
  mutation CreateApp($input: CreateAppInput!) {
    createApp(input: $input) {
      app {
        ...AppFields
      }
    }
  }
`;

const UPDATE = gql`
  ${AppFields}
  mutation UpdateApp($input: UpdateAppInput!) {
    updateApp(input: $input) {
      app {
        ...AppFields
      }
    }
  }
`;

const editDomains = ({ domain, domains }) => {
  return wizard.init({
    title: 'Edit Domains',
    steps: [
      {
        title: 'Primary Domain',
        schema: {
          type: 'object',
          properties: {
            domain: { title: 'Primary Domain', type: 'string' },
          },
        },
        uiSchema: {
          domain: {
            'ui:autofocus': true,
          },
        },
      },
      {
        title: 'Secondary Domains',
        schema: {
          type: 'object',
          properties: {
            domains: {
              title: 'Secondary Domains',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  domain: { title: null, type: 'string' },
                  redirect: { title: 'Redirect to primary domain', type: 'boolean' },
                },
              },
            },
          },
        },
      },
    ],
    value: [
      {
        domain: domain || '',
      },
      {
        domains: domains || [],
      },
    ],
  });
};

const editScripts = ({ scripts }) => {
  return wizard.init({
    title: 'Edit Scripts',
    steps: [
      {
        schema: {
          type: 'object',
          properties: {
            scripts: {
              title: 'Scripts',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { title: 'Name', type: 'string' },
                  script: { title: 'Script', type: 'string' },
                  location: {
                    title: 'Location',
                    type: 'string',
                    default: 'head',
                    enum: ['head', 'body_start', 'body_end'],
                    enumNames: ['Head', 'Body Start', 'Body End'],
                    oneOf: [
                      {
                        type: 'string',
                        title: 'head',
                        enum: ['head'],
                      },
                      {
                        type: 'string',
                        title: 'body_start',
                        enum: ['body_start'],
                      },
                      {
                        type: 'string',
                        title: 'body_end',
                        enum: ['body_end'],
                      },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    ],
    value: [
      {
        scripts: scripts || [],
      },
    ],
  });
};

const Script = ({ script }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ButtonGroup style={{ marginBottom: '4px' }}>
        <Button onClick={() => setOpen(!open)}>{script.name}</Button>
        <Tag>{script.location}</Tag>
      </ButtonGroup>
      <Collapse isOpen={open}>
        <Card
          style={{
            marginBottom: '4px',
            width: '100%',
            fontFamily: 'Courier, monospace',
            fontSize: '12px',
          }}
        >
          {script.script}
        </Card>
      </Collapse>
    </>
  );
};

export default function AppsView() {
  const [showConnectionInfo, setShowConnectionInfo] = useState(false);
  const { activeProjectId } = useProjects();
  const handleEditDomainsClick = (app, onChange) => () => {
    editDomains(app).then(data => {
      const [{ domain }, { domains }] = data;
      onChange({ ...app, domain, domains });
    });
  };

  const handleEditScriptsClick = (app, onChange) => () => {
    editScripts(app).then(data => {
      const [{ scripts }] = data;
      onChange({ ...app, scripts });
    });
  };

  return (
    <TabularResource
      resourceName="App"
      tabInfo={app => ({ title: app.name })}
      actionButtons={app =>
        !app.slug ? null : (
          <Button
            icon="share"
            onClick={() => window.open(`https://${app.slug}.apps.wings.dev`, '_blank')}
          >
            Visit
          </Button>
        )
      }
      mutableFragment={AppMutable}
      queries={{
        create: {
          query: CREATE,
          variables: { input: { app: { name: 'My App' } } },
        },
        read: { query: APPS },
        update: {
          query: UPDATE,
          variables: (id, { menu, webhooks, theme, home, translation, ...rest }) => ({
            input: {
              id,
              app: {
                menuId: menu && menu.id,
                webhooks: {
                  all:
                    (webhooks &&
                      webhooks.all &&
                      webhooks.all.length &&
                      webhooks.all.filter(w => !!w)) ||
                    [],
                },
                themeId: theme && theme.id,
                translationId: translation && translation.id,
                home: {
                  nodeId: home?.node?.id,
                  nodeType: home?.node?.nodeType,
                },
                ...rest,
              },
            },
          }),
        },
      }}
    >
      {(app, { onChange }) => (
        <>
          <Tabs titles={['General', 'Translations', 'Domains', 'Scripts']}>
            <div>
              <FormGroup
                helperText="A good name helps identify this app (example: Main Website)"
                label="Name"
                labelFor="name-input"
                labelInfo="(required)"
              >
                <InputGroup
                  id="name-input"
                  value={app.name || ''}
                  onChange={e => onChange({ ...app, name: e.target.value })}
                />
              </FormGroup>

              <FormGroup
                helperText="This app's theme (which can be created in the project's Theme settings)"
                label="Theme"
                labelFor="theme-input"
              >
                <ResourceSelect
                  resourceName="Theme"
                  onSelect={theme => onChange({ ...app, theme })}
                  selected={app.theme ? app.theme.id : undefined}
                  nameProp="name"
                  query={gql`
                    {
                      items: themes {
                        id
                        name
                      }
                    }
                  `}
                />
              </FormGroup>
              <FormGroup
                helperText="This app's main menu (which can be created in the project's Menu settings)"
                label="Main Menu"
                labelFor="menu-input"
              >
                <ResourceSelect
                  resourceName="Menu"
                  onSelect={({ id }) => onChange({ ...app, menu: { id } })}
                  selected={app.menu ? app.menu.id : undefined}
                  nameProp="name"
                  query={gql`
                    {
                      items: menus {
                        id
                        name
                      }
                    }
                  `}
                />
              </FormGroup>

              <FormGroup
                label="Homepage"
                helperText="The page, entry or campaign that is set as the homepage for this app"
              >
                <NodeSelect
                  onSelect={node => onChange({ ...app, home: { node } })}
                  selected={app.home?.node?.id || undefined}
                />
              </FormGroup>
              <div>
                <Button
                  onClick={() => setShowConnectionInfo(!showConnectionInfo)}
                  style={{ margin: '24px 0' }}
                >
                  {showConnectionInfo ? 'Hide' : 'Show'} Connection Info
                </Button>
                <Collapse isOpen={showConnectionInfo} keepChildrenMounted>
                  <FormGroup label="Project ID">
                    <ClipboardInput value={activeProjectId} />
                  </FormGroup>
                  <FormGroup label="App ID">
                    <ClipboardInput value={app.id} />
                  </FormGroup>
                  <FormGroup label="App Key">
                    <ClipboardInput value={app.auth.key} />
                  </FormGroup>
                </Collapse>
              </div>
            </div>
            <div>
              <h3>Translations</h3>
              <FormGroup
                helperText="Choose the configuration for this app's translations (which can be created in the project's Language settings)."
                label="Translations"
                labelFor="translations-input"
              >
                <ResourceSelect
                  resourceName="Translations"
                  onSelect={translation => onChange({ ...app, translation })}
                  selected={app.translation?.id}
                  nameProp="name"
                  query={gql`
                    {
                      items: translations {
                        id
                        name
                      }
                    }
                  `}
                />
              </FormGroup>
            </div>
            <div>
              {!app.slug ? null : (
                <>
                  <h3>Domains</h3>
                  <FormGroup
                    label="App Domain"
                    helperText="The wings subdomain assigned to the app upon creation."
                  >
                    <ClipboardInput value={`${app.slug}.apps.wings.dev`} />
                  </FormGroup>
                  {!app.domain && !app.domains?.length ? null : (
                    <FormGroup
                      label="Custom Domains"
                      helperText="Custom domains for the app (probably configured externally)"
                    >
                      {!app.domain ? null : (
                        <ClipboardInput
                          value={app.domain}
                          labels={['primary']}
                          style={{ marginBottom: '4px', display: 'block' }}
                        />
                      )}
                      {!app.domains
                        ? null
                        : app.domains.map(domain => (
                            <ClipboardInput
                              value={domain.domain}
                              labels={domain.redirect ? ['redirect'] : []}
                              style={{ marginBottom: '4px', display: 'block' }}
                            />
                          ))}
                    </FormGroup>
                  )}
                  <Button intent={Intent.WARNING} onClick={handleEditDomainsClick(app, onChange)}>
                    Edit domains
                  </Button>
                </>
              )}
            </div>
            <div>
              <h3>Scripts</h3>
              <FormGroup
                helperText="Scripts should include include the <script> tags and any required attriubutes (e.g. 'src')."
                labelFor="edit-scripts-button"
              >
                {!app.scripts?.length
                  ? null
                  : app.scripts.map(script => <Script script={script} />)}
                <Button
                  id="edit-scripts-button"
                  intent={Intent.WARNING}
                  onClick={handleEditScriptsClick(app, onChange)}
                >
                  Edit scripts
                </Button>
              </FormGroup>
            </div>
          </Tabs>
          <Debug data={app} />
        </>
      )}
    </TabularResource>
  );
}
