import { DatePicker as _DatePicker } from '@blueprintjs/datetime';
import styled from 'styled-components';
import React from 'react';
import { randomId } from '/lib/utils';

const DatePicker = styled(_DatePicker)`
  .bp3-timepicker {
    max-width: 220px;
  }
`;

export default ({ value, onChange, children, labelText, ...props }) => {
  const id = `input-${randomId()}`;
  return (
    <label className="bp3-label" htmlFor={id}>
      {children || labelText}
      <DatePicker
        id={id}
        value={new Date(value.start)}
        onChange={v => onChange({ start: v.valueOf() })}
        {...props}
        maxDate={new Date(2030, 12, 31)}
      />
    </label>
  );
};
