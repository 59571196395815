import React from 'react';
import styled from 'styled-components';
import ProjectMenuButton from '/components/ProjectMenuButton';
import EnsureLoggedIn from '/components/EnsureLoggedIn';
import Loading from '/components/Loading';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class ProjectSelect extends React.Component {
  render() {
    return (
      <EnsureLoggedIn component={Loading}>
        <Wrapper className="bp3-dark">
          <ProjectMenuButton />
        </Wrapper>
      </EnsureLoggedIn>
    );
  }
}
