import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { SectionButton, MarkupButton } from 'react-mobiledoc-editor';
import { Button, Popover, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import {
  CampaignCard,
  CollectionCard,
  CTACard,
  DataCard,
  EmbedCard,
  ImageCard,
  InsightCard,
  NodesCard,
  QACard,
  QuoteCard,
  TestimonialCard,
  TextCard,
} from './cards';

export const Sections = compose(setDisplayName('Sections'))(({ className }) => (
  <React.Fragment>
    <SectionButton className={classNames('bp3-button bp3-icon-list', className)} tag="ul" />
    <SectionButton className={classNames('bp3-button bp3-icon-list', className)} tag="ol" />
  </React.Fragment>
));

export const Markups = compose(setDisplayName('Markups'))(({ className }) => (
  <React.Fragment>
    <MarkupButton className={classNames('bp3-button bp3-icon-bold', className)} tag="strong" />
    <MarkupButton className={classNames('bp3-button bp3-icon-italic', className)} tag="em" />
    <MarkupButton className={classNames('bp3-button bp3-icon-code', className)} tag="code" />
  </React.Fragment>
));

export const HeadingDropdown = compose(setDisplayName('HeadingDropdown'))(({ className }) => (
  <Dropdown icon="header" text="Heading" className={className}>
    <SectionButton tag="h2" />
    <SectionButton tag="h3" />
    <SectionButton tag="h4" />
    <SectionButton tag="h5" />
    <SectionButton tag="h6" />
  </Dropdown>
));

const Dropdown = ({
  children,
  icon,
  righticon = 'caret-down',
  text,
  position = Position.BOTTOM_LEFT,
  className,
}) => (
  <Popover
    content={
      <div className="bp3-popover-content">
        <ul className="bp3-menu">
          {React.Children.map(children, child => {
            const childicon = child.props.icon || icon;
            return (
              <li>
                {React.cloneElement(child, {
                  className: `bp3-menu-item bp3-icon-${childicon}`,
                })}
              </li>
            );
          })}
        </ul>
      </div>
    }
    position={position}
    minimal
  >
    <Button className={className} icon={icon} righticon={righticon} text={text} />
  </Popover>
);

export const CardsDropdown = ({ ChapterCard }) => (
  <Dropdown icon="insert" text="Card">
    <ChapterCard.Button icon="header" />
    <TextCard.Button icon="label" edit />
    <ImageCard.Button icon="media" edit />
    <QuoteCard.Button icon="citation" />
    <CampaignCard.Button icon="form" edit />
    <NodesCard.Button icon="control" edit />
    <EmbedCard.Button icon="video" />
    <InsightCard.Button icon="lightbulb" />
    <TestimonialCard.Button icon="comment" edit />
    <QACard.Button icon="help" edit />
    <CTACard.Button icon="selection" edit />
    <CollectionCard.Button icon="grid-view" edit />
    <DataCard.Button icon="database" edit />
  </Dropdown>
);
