import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, EditableText } from '@blueprintjs/core';
import { createCard } from '@wingsplatform/react';
import ChapterCardView from './ChapterCardView';
import Actions from './Actions';
import withClickableHighlight from '/styles/withClickableHighlight';

class ChapterCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = { payload: this.props.payload, isEditing: true };

  setPayloadState = state =>
    this.setState(({ payload }) => ({
      isEditing: undefined,
      payload: { ...payload, ...state },
    }));

  render() {
    const { payload, save } = this.props;
    const {
      payload: { title, intro, marker, displayArticleTop, displaySideMenu },
      isEditing,
    } = this.state;
    return (
      <div>
        <ChapterCardView
          {...this.state}
          marker={
            <EditableText
              onChange={v => this.setPayloadState({ marker: v })}
              placeholder="Chapter marker"
              selectAllOnFocus
              value={marker}
              multiline
              minLines={1}
            />
          }
          title={
            <EditableText
              onChange={v => this.setPayloadState({ title: v })}
              placeholder="Edit title..."
              selectAllOnFocus
              value={title}
              multiline
              minLines={1}
              isEditing={isEditing}
            />
          }
          intro={
            <EditableText
              onChange={v => this.setPayloadState({ intro: v })}
              placeholder="Edit intro..."
              selectAllOnFocus
              value={intro}
              multiline
              minLines={2}
            />
          }
          options={
            <>
              <Checkbox
                label="Display at top of article"
                checked={displayArticleTop}
                onChange={() =>
                  this.setPayloadState({
                    displayArticleTop: !displayArticleTop,
                  })
                }
              />
              <Checkbox
                label="Display in side menu"
                checked={displaySideMenu}
                onChange={() => this.setPayloadState({ displaySideMenu: !displaySideMenu })}
              />
            </>
          }
        />

        <Actions onCancel={() => save(payload)} onSave={() => save(this.state.payload)} />
      </div>
    );
  }
}

const cfg = {
  name: 'ChapterCard',
  renderWith: withClickableHighlight(ChapterCardView),
  editWith: ChapterCardEditor,
  buttonText: 'Chapter',
  payload: {
    title: 'Section Title',
    intro: 'An eloquent introduction to this section',
    marker: 'The chapter marker',
    displayArticleTop: false,
    displaySideMenu: false,
  },
};
export default createCard(cfg);

export const HeaderCard = createCard({ ...cfg, name: 'HeaderCard' });
