import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as _Dialog,
  Button,
  Intent,
  Callout as _Callout,
  HTMLSelect,
} from '@blueprintjs/core';
import styled from 'styled-components';
import JSON5 from 'json5';
import gql from 'graphql-tag';
import { createCard } from '@wingsplatform/react';
import CodeEditor from '/components/CodeEditor';
import Form from '/components/Form';
import withClickableHighlight from '/styles/withClickableHighlight';
import DataCardView from './DataCardView';
import { withSchemas } from './withSchemas';

export const SCHEMAS_QUERY = gql`
  {
    schemas(selector: { locations: { match: { eq: "editor.datacard" } } }) {
      id
      key
      name
      definition
    }
  }
`;

const Wrapper = styled.div`
  padding: 12px;
`;

const Dialog = styled(_Dialog)`
  min-width: 640px;
`;

const Callout = styled(_Callout)`
  display: inline;
  float: right;
  width: 340px;
`;

const Actions = styled.div`
  min-width: 120px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

class DataCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = { valid: true, payload: { ...this.props.payload } };

  handleSchemaChange = schema =>
    this.setState(({ ...state }) => ({
      ...state,
      payload: {
        json: '{}',
        json5: '{}',
        data: {},
        schema,
      },
      valid: true,
    }));

  setPayload = fn =>
    this.setState(({ payload }) => ({
      payload: { ...payload, ...fn(payload) },
    }));

  handleChange = v => {
    let valid;
    let data;
    let json;
    try {
      const parsed = JSON5.parse(v);
      data = parsed;
      json = JSON.stringify(parsed);
      valid = true;
    } catch (err) {
      data = {};
      json = '{}';
      valid = false;
    }
    this.setState(({ payload }) => ({
      payload: {
        ...payload,
        json5: v,
        data,
        json,
      },
      valid,
    }));
  };

  handleFormChange = ({ formData: v }) => {
    this.setPayload(() => ({ data: v }));
  };

  availableSchemas = () => this.props.schemas.filter(({ definition }) => !!definition);

  hasSchema = () =>
    this.state.payload.schema !== 'json' &&
    this.availableSchemas().some(({ key }) => key === this.state.payload.schema);

  getSchemaDef = n => this.props.schemas.find(({ key }) => key === n)?.definition;

  render() {
    const { payload: initialPayload, save, loading } = this.props;
    const { valid, payload } = this.state;

    return (
      <>
        <Dialog
          title="Data"
          icon="database"
          isOpen
          onClose={() => save(initialPayload)}
          canOutsideClickClose
        >
          <Wrapper>
            <HTMLSelect
              options={[
                {
                  label: 'JSON',
                  value: 'json',
                },
              ].concat(
                this.availableSchemas().map(({ name, key }) => ({
                  label: name,
                  value: key,
                })),
              )}
              onChange={e => this.handleSchemaChange(e.target.value)}
              value={payload.schema}
            />
            {this.hasSchema() ? (
              <Form
                liveValidate
                schema={JSON.parse(this.getSchemaDef(payload.schema))}
                onChange={v => this.handleFormChange(v)}
                formData={payload.data}
              />
            ) : (
              <CodeEditor value={payload.json5} onChange={this.handleChange} />
            )}

            <hr />
            <Footer>
              <Actions>
                <Button disabled={!valid} intent={Intent.PRIMARY} onClick={() => save(payload)}>
                  Save
                </Button>
                <Button onClick={() => save(initialPayload)}>Cancel</Button>
              </Actions>
              {!valid && (
                <>
                  <Callout intent={Intent.WARNING}>Invalid input.</Callout>
                </>
              )}
            </Footer>
          </Wrapper>
        </Dialog>
        {!loading && <DataCardView {...this.state.payload} />}
      </>
    );
  }
}

export default createCard({
  name: 'DataCard',
  renderWith: withClickableHighlight(DataCardView),
  editWith: withSchemas(DataCardEditor),
  buttonText: 'Data',
  payload: {
    schema: 'json',
    data: {},
    json5: '{}',
  },
});
