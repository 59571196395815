import AppRouting from '/services/appRouting';

export default class App {
  constructor(app) {
    this.app = app;
    this.routing = new AppRouting(app);
  }

  url(node) {
    return this.routing.getUrl(node);
  }
}
