import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { EditableText } from '@blueprintjs/core';
import { createCard, QuoteCard } from '@wingsplatform/react';
import Input from '/components/Input';
import Select from '/components/Select';
import Actions from './Actions';
import withClickableHighlight from '/styles/withClickableHighlight';

class QuoteCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = { ...this.props.payload };
  eventToState = key => e => this.setState({ [key]: e.target.value });
  hasFloat = () => _.includes([QuoteCard.View.TYPE.PULLQUOTE], this.state.type);

  render() {
    const { payload, save } = this.props;
    const { text, source, sourceUrl, type, float } = this.state;
    return (
      <div>
        <QuoteCard.View
          {...this.state}
          text={
            <EditableText
              onChange={v => this.setState({ text: v })}
              placeholder="Edit quote..."
              selectAllOnFocus
              defaultValue={text}
              multiline
              minLines={3}
            />
          }
        />
        <div className="bp3-form-group bp3-inline" style={{ clear: 'both' }}>
          <Input
            name="source"
            placeholder="Quote Source"
            onChange={this.eventToState('source')}
            value={source}
          >
            Source <span className="bp3-text-muted">(optional)</span>
          </Input>

          <Input
            name="source-url"
            placeholder="Quote Source URL"
            onChange={this.eventToState('sourceUrl')}
            value={sourceUrl}
          >
            Source Link <span className="bp3-text-muted">(optional)</span>
          </Input>

          <Select
            onChange={e => this.setState({ type: parseInt(e.target.value, 10) })}
            labelText="Layout"
            items={[
              { value: QuoteCard.View.TYPE.BLOCKQUOTE, text: 'Blockquote' },
              { value: QuoteCard.View.TYPE.PULLQUOTE, text: 'Pullquote' },
            ]}
            value={type}
          />

          {!this.hasFloat() ? null : (
            <Select
              onChange={e => this.setState({ float: parseInt(e.target.value, 10) })}
              labelText="Float"
              items={[
                { value: QuoteCard.View.FLOAT.NONE, text: 'None' },
                { value: QuoteCard.View.FLOAT.LEFT, text: 'Left' },
                { value: QuoteCard.View.FLOAT.RIGHT, text: 'Right' },
              ]}
              value={float}
            />
          )}
        </div>

        <Actions onCancel={() => save(payload)} onSave={() => save(this.state)} />
      </div>
    );
  }
}

export default createCard({
  name: 'QuoteCard',
  renderWith: withClickableHighlight(QuoteCard.View),
  editWith: QuoteCardEditor,
  buttonText: 'Quote',
  payload: {
    text: 'Your quote here.',
    source: '',
    sourceUrl: '',
    type: QuoteCard.View.TYPE.BLOCKQUOTE,
    float: QuoteCard.View.FLOAT.NONE, // for pullquote
  },
});
