import React from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Breadcrumb from '/components/Breadcrumb';
import withProjects from '/containers/withProjects';
import PetitionEditor from '/components/PetitionEditor';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import Debug from '/components/Debug';
import withRouter from '/containers/withRouter';
import ResourceNotFound from '/components/ResourceNotFound';
import {
  PetitionFields,
  PetitionMutable,
  prepareNodeMutation,
  ProjectLocales,
} from '/data/fragments';

const QUERY = gql`
  query Petition($id: String!) {
    petition(selector: { id: { eq: $id } }) {
      ...PetitionFields
    }
    project: currentProject {
      ...ProjectLocales
    }
  }
  ${PetitionFields}
  ${ProjectLocales}
`;

const UPDATE_PETITION = gql`
  ${PetitionFields}
  mutation UpdatePetition($id: String!, $petition: PetitionInput!) {
    updatePetition(input: { id: $id, petition: $petition }) {
      petition {
        ...PetitionFields
      }
    }
  }
`;

const DELETE_PETITION = gql`
  ${PetitionFields}
  mutation DeletePetition($id: String!) {
    deletePetition(input: { id: $id }) {
      petition {
        ...PetitionFields
      }
    }
  }
`;

class Petition extends React.Component {
  goBack = () => this.props.history.push(this.props.projectUrl(`/campaigns`));

  handleGoBackClick = () => this.goBack();

  render() {
    const {
      match: {
        params: { id },
      },
      projectUrl,
      historyPush,
    } = this.props;
    return (
      <Query query={QUERY} variables={{ id }} pollInterval={10000}>
        {({ data: { petition = {}, project = {} } = {}, loading, error }) =>
          loading ? (
            <Loading fill />
          ) : error ? (
            <ErrorCallout message="Something went wrong." />
          ) : !petition ? (
            <ResourceNotFound name="Petition" onGoBackClick={this.handleGoBackClick} />
          ) : (
            <React.Fragment>
              <Breadcrumb
                items={[
                  { text: 'Campaigns', url: projectUrl('/campaigns') },
                  { text: 'Petitions' },
                  { text: petition.title || id },
                ]}
              />
              <Mutation mutation={UPDATE_PETITION}>
                {(updatePetition, { loading: saving }) => (
                  <Mutation mutation={DELETE_PETITION}>
                    {(deletePetition, { loading: deleting }) => (
                      <PetitionEditor
                        petition={petition}
                        locales={project?.settings?.i18n?.locales}
                        signatures={[]}
                        saving={saving}
                        deleting={deleting}
                        onSave={v =>
                          updatePetition({
                            variables: {
                              id: petition.id,
                              petition: prepareNodeMutation(filter(PetitionMutable, v)),
                            },
                          })
                        }
                        onDelete={async () => {
                          await deletePetition({
                            variables: { id: petition.id },
                          });
                          historyPush(projectUrl(`/campaigns`));
                        }}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
              <Debug data={petition} />
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}
export default withRouter(withProjects(Petition));
