import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';

const ArrayFieldItem = ({
  hasMoveDown,
  hasMoveUp,
  hasRemove,
  index,
  onDropIndexClick,
  onReorderClick,
  children,
}) => (
  <>
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ flex: 1 }}>{children}</div>
      <div>
        <ButtonGroup>
          <Button
            disabled={!hasMoveUp}
            icon="arrow-up"
            intent={Intent.PRIMARY}
            onClick={onReorderClick(index, index - 1)}
          />
          <Button
            disabled={!hasMoveDown}
            icon="arrow-down"
            intent={Intent.PRIMARY}
            onClick={onReorderClick(index, index + 1)}
          />
          <Button
            disabled={!hasRemove}
            icon="delete"
            intent={Intent.DANGER}
            onClick={onDropIndexClick(index)}
          />
        </ButtonGroup>
      </div>
    </div>
    <hr />
  </>
);

const ArrayFieldTemplate = ({ ...props }) => (
  <div>
    <div>{props.title}</div>
    {props.items.map(element => (
      <ArrayFieldItem key={`array-field-item-${element.index}`} {...element} />
    ))}
    {/* {console.log(props)} */}
    {props.canAdd && (
      <Button icon="add" intent={Intent.PRIMARY} onClick={props.onAddClick}>
        Add
      </Button>
    )}
  </div>
);

export default compose(setDisplayName('ArrayFieldTemplate'))(ArrayFieldTemplate);
