import React from 'react';
import NodeList from './NodeList';
import styled from 'styled-components';
import { Tag as _Tag } from '@blueprintjs/core';

const Tag = styled(_Tag)`
  margin-right: 8px;
`;

export default ({ campaigns }) => (
  <NodeList
    nodes={campaigns}
    fields={fields => [
      {
        columnName: 'Type',
        render: campaign => <Tag>{campaign.nodeType}</Tag>,
      },
      ...fields,
    ]}
  />
);
