import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { hot } from 'react-hot-loader';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Provider } from 'react-redux';

import { theme } from '/styles';
import Dashboard from '/views/Dashboard';
import Media from '/views/Media';
import MediaDetail from '/views/MediaDetail';
import Campaigns from '/views/Campaigns';
import Signup from '/views/Signup';
import Petition from '/views/Petition';
import EventView from '/views/Event';
import Fundraiser from '/views/Fundraiser';
import Entries from '/views/Entries';
import EntryView from '/views/Entry';
import ProjectSelect from '/views/ProjectSelect';
import LoginCallback from '/views/LoginCallback';
import Playground from '/views/Playground';
import Settings from '/views/Settings';
import Commerce from '/views/Commerce';
import DForm from '/views/DForm';

import Loading from '/components/Loading';

import Container from '/components/Container';
import Navbar from '/components/Navbar';
import EnsureLoggedIn from '/components/EnsureLoggedIn';
import history from '/data/history';

import store from './data/store';

import GlobalStyles from './styles/global';

const projectRoutes = [
  { path: '', component: Dashboard },
  { path: '/content', component: Entries },
  { path: '/entries/:id', component: EntryView },
  { path: '/campaigns', component: Campaigns },
  { path: '/signups/:id', component: Signup },
  { path: '/petitions/:id', component: Petition },
  { path: '/events/:id', component: EventView },
  { path: '/fundraisers/:id', component: Fundraiser },
  { path: '/files', component: Media },
  { path: '/files/:id', component: MediaDetail },
  { path: '/settings', component: Settings },
  { path: '/commerce', component: Commerce },
];

const ProjectRoutes = ({ match, location }) => (
  <EnsureLoggedIn component={Loading}>
    <Navbar />
    {!location.pathname.endsWith('/playground') && (
      <Container stretch>
        {projectRoutes.map(route => (
          <Route
            key={btoa(route.path)}
            exact
            path={`${match.path}${route.path}`}
            component={route.component}
          />
        ))}
      </Container>
    )}
    <Route exact path={`${match.path}/playground`} component={Playground} />
  </EnsureLoggedIn>
);

const LabsRoutes = ({ match }) => (
  <React.Fragment>
    <Route exact path={`${match.path}/forms`} component={DForm} />
  </React.Fragment>
);

export default hot(module)(({ client }) => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>
          <div style={{ height: '100%' }}>
            <GlobalStyles />
            <Switch>
              <Route exact path="/" component={ProjectSelect} />
              <Route exact path="/login/callback" component={LoginCallback} />
              <Route path="/labs" component={LabsRoutes} />
              <Route path="/:projectId" component={ProjectRoutes} />
            </Switch>
          </div>
        </Router>
      </Provider>
    </ThemeProvider>
  </ApolloProvider>
));
