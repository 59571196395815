import React, { Component } from 'react';
import styled from 'styled-components';
import { Tab, Tabs } from '@blueprintjs/core';
import Card from '/components/Card';
import Input from '/components/Input';
import MediaInput from '/components/MediaInput';

const SettingsCard = styled(Card)`
  margin: 12px 0;
`;

export default class PlatformsSettings extends Component {
  static defaultProps = {
    node: {},
  };

  handleChangePlatform = platform => field => e => {
    const { platforms } = this.props.node;
    this.props.onChange('platforms')({
      ...platforms,
      [platform]: {
        ...(platforms ? platforms[platform] : {}),
        [field]: e.target ? e.target.value : e,
      },
    });
  };

  handleImageChangePlatform = platform => image => {
    const { platforms } = this.props.node;
    this.props.onChange('platforms')({
      ...platforms,
      [platform]: {
        ...(platforms ? platforms[platform] : {}),
        image,
        imageId: image.id,
      },
    });
  };

  handleChangeFeatured = field => e => {
    const { featured } = this.props.node;
    this.props.onChange('featured')({
      ...featured,
      [field]: e.target ? e.target.value : e,
    });
  };

  handleImageChangeFeatured = image => {
    const { featured } = this.props.node;
    this.props.onChange('featured')({
      ...featured,
      image,
      imageId: image.id,
    });
  };

  render() {
    const { node } = this.props;
    return (
      <SettingsCard className="bp3-dark">
        <Tabs vertical animate id="node-settings">
          <Tab
            id="as-featured"
            title="Featured"
            panel={
              <div>
                <Input
                  name="title"
                  placeholder={node?.featured?.title || ''}
                  onChange={this.handleChangeFeatured('title')}
                  value={node?.featured?.title || ''}
                  className="bp3-fill"
                >
                  Title
                </Input>
                <Input
                  name="description"
                  placeholder={node?.featured?.description || ''}
                  onChange={this.handleChangeFeatured('description')}
                  value={node?.featured?.description || ''}
                  className="bp3-fill"
                >
                  Description
                </Input>
                <MediaInput
                  onChange={this.handleImageChangeFeatured}
                  mediaId={node?.featured?.image?.id || ''}
                />
              </div>
            }
          />
          <Tab
            id="as-all"
            title="Search Engines"
            panel={
              <div>
                <Input
                  name="title"
                  placeholder={node?.platforms?.search?.title || ''}
                  onChange={this.handleChangePlatform('search')('title')}
                  value={node?.platforms?.search?.title || ''}
                  className="bp3-fill"
                >
                  Title
                </Input>
                <Input
                  name="description"
                  placeholder={node?.platforms?.search?.description || ''}
                  onChange={this.handleChangePlatform('search')('description')}
                  value={node?.platforms?.search?.description || ''}
                  className="bp3-fill"
                >
                  Description
                </Input>
              </div>
            }
          />
          <Tab
            id="as-fb"
            title="Facebook"
            panel={
              <div>
                <Input
                  name="title"
                  placeholder={node?.platforms?.facebook?.title || ''}
                  onChange={this.handleChangePlatform('facebook')('title')}
                  value={node?.platforms?.facebook?.title || ''}
                  className="bp3-fill"
                >
                  Title
                </Input>
                <Input
                  name="description"
                  placeholder={node?.platforms?.facebook?.description || ''}
                  onChange={this.handleChangePlatform('facebook')('description')}
                  value={node?.platforms?.facebook?.description || ''}
                  className="bp3-fill"
                >
                  Description
                </Input>
                <MediaInput
                  onChange={this.handleImageChangePlatform('facebook')}
                  mediaId={node?.platforms?.facebook?.image?.id || ''}
                />
              </div>
            }
          />
          <Tab
            id="as-tw"
            title="Twitter"
            panel={
              <div>
                <Input
                  name="title"
                  placeholder={node?.platforms?.twitter?.title || ''}
                  onChange={this.handleChangePlatform('twitter')('title')}
                  value={node?.platforms?.twitter?.title || ''}
                  className="bp3-fill"
                >
                  Title
                </Input>
                <Input
                  name="description"
                  placeholder={node?.platforms?.twitter?.description || ''}
                  onChange={this.handleChangePlatform('twitter')('description')}
                  value={node?.platforms?.twitter?.description || ''}
                  className="bp3-fill"
                >
                  Description
                </Input>
                <MediaInput
                  onChange={this.handleImageChangePlatform('twitter')}
                  mediaId={node?.platforms?.twitter?.image?.id || ''}
                />
              </div>
            }
          />
          <Tab
            id="as-wa"
            title="WhatsApp"
            panel={
              <div>
                <Input
                  name="text"
                  onChange={this.handleChangePlatform('whatsapp')('text')}
                  value={node?.platforms?.whatsapp?.text || ''}
                  className="bp3-fill"
                >
                  Text
                </Input>
              </div>
            }
          />
          <Tabs.Expander />
        </Tabs>
      </SettingsCard>
    );
  }
}
