import components from './components';
import footer from './footer';
import forms from './forms';
import general from './general';
import images from './images';
import menus from './menus';
import spacing from './spacing';
import text from './text';

export default [
  ...general,
  ...spacing,
  ...text,
  ...images,
  ...forms,
  ...components,
  ...menus,
  ...footer,
];
