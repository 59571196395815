import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  FormGroup,
  InputGroup,
  Intent,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import MediaPicker from '/components/MediaPicker';
import SettingsCard from './SettingsCard';
import _InheritedInput from '../InheritedInput';
import Select from '../Select';
import withMedia from '/containers/withMedia';

const InheritedInput = styled(_InheritedInput)`
  & .bp3-button {
    margin-top: 5px;
  }

  & input {
    width: 300px !important;
  }
`;

class PlatformsSettings extends Component {
  state = {
    mediaPicker: false,
  };
  toggleMediaPicker = () => this.setState(({ mediaPicker }) => ({ mediaPicker: !mediaPicker }));
  handleMediaSelect = m => {
    this.setState({ mediaPicker: false });
    this.props.onImageChange(m);
  };
  render() {
    const {
      title,
      slug,
      inheritedSlug,
      image,
      onLocaleChange,
      onTitleChange,
      onSlugChange,
      locales,
      currentLocale,
    } = this.props;
    const { mediaPicker } = this.state;
    return (
      <SettingsCard backgroundImage={image ? image.url : ' '}>
        <div style={{ float: 'right' }}>
          <ButtonGroup>
            <Button onClick={() => (mediaPicker ? null : this.toggleMediaPicker())}>
              Change Image
            </Button>
            <Button
              intent={Intent.DANGER}
              disabled={!image?.url}
              icon="delete"
              onClick={() => this.props.onImageChange(null)}
            />
          </ButtonGroup>
          {mediaPicker && (
            <MediaPicker
              dialog
              onSelect={this.handleMediaSelect}
              onClose={this.toggleMediaPicker}
            />
          )}
        </div>
        <FormGroup label="Title" labelFor="titleInput" labelInfo="(required)">
          <Tooltip
            content="Please enter a title!"
            position={Position.RIGHT}
            intent={Intent.WARNING}
            isOpen={!title}
          >
            <InputGroup
              style={{ width: 300 }}
              value={title || ''}
              intent={!title ? Intent.WARNING : Intent.NONE}
              onChange={e => onTitleChange(e.target.value)}
              id="titleInput"
            />
          </Tooltip>
        </FormGroup>
        <label className="bp3-label" htmlFor="slugInput">
          Slug
          <InheritedInput
            id="slugInput"
            value={slug}
            placeholder={inheritedSlug}
            onChange={onSlugChange}
          />
        </label>

        <Select
          labelText="Language"
          wrapStyle={{ width: 200, position: 'relative' }}
          items={locales
            .concat(
              locales.find(l => l.locale.id === currentLocale.id)
                ? []
                : [{ locale: { ...currentLocale }, primary: false }],
            )
            .map(l => ({
              text: l.locale.name,
              value: l.locale.id,
            }))}
          value={currentLocale.id}
          onChange={e =>
            onLocaleChange(locales.filter(l => l.locale.id === e.target.value)[0].locale)
          }
        />
      </SettingsCard>
    );
  }
}

export default withMedia(PlatformsSettings);
