import React, { useState } from 'react';
import { Button, Intent, Classes, Callout } from '@blueprintjs/core';
import Form from '/components/Form';

export default function FormPanel({
  steps,
  getStepData,
  activeStep = 0,
  openPanel,
  closePanel,
  onChange,
  onFinish,
}) {
  // const processStepInput =
  const [errorMessage, setErrorMessage] = useState(null);
  const [processingInput, setProcessingInput] = useState(false);
  const step = steps[activeStep];
  const { processInput = () => true } = step;
  const hasNextStep = steps.length - 1 > activeStep;
  const hasPreviousStep = activeStep > 0;
  const handleFinish = () => onFinish();
  const handleNext = async () => {
    let shouldContinue;
    try {
      setProcessingInput(true);
      shouldContinue = await processInput(getStepData(activeStep));
      setProcessingInput(false);
    } catch (e) {
      setErrorMessage(e.message);
      setProcessingInput(false);
      console.error(e);
      shouldContinue = false;
    }
    if (!shouldContinue) return;
    if (!hasNextStep) {
      handleFinish();
      return;
    }
    openPanel({
      title: steps[activeStep + 1].title,
      component: FormPanel,
      props: {
        steps,
        getStepData,
        activeStep: activeStep + 1,
        onChange,
        onFinish,
      },
    });
  };
  const handlePrevious = () => closePanel();
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: 370,
      }}
    >
      <div style={{ overflowY: 'scroll', flexGrow: 1, padding: '12px 8px' }}>
        <Form
          liveValidate
          schema={steps[activeStep].schema}
          uiSchema={steps[activeStep].uiSchema}
          formData={getStepData(activeStep)}
          onChange={({ formData }) => onChange(activeStep, formData)}
          onSubmit={handleNext}
        />
      </div>
      {errorMessage && (
        <div>
          <Callout intent={Intent.DANGER} title="Uh oh">
            {errorMessage}
          </Callout>
        </div>
      )}
      <div
        className={Classes.DIALOG_FOOTER_ACTIONS}
        style={{
          justifyContent: 'space-between',
          backgroundColor: 'rgb(235, 241, 245)',
          padding: 8,
        }}
      >
        {hasPreviousStep ? <Button onClick={handlePrevious}>Back</Button> : <div />}
        <Button loading={processingInput} onClick={handleNext} intent={Intent.PRIMARY}>
          {hasNextStep ? 'Next' : 'Finish'}
        </Button>
      </div>
    </div>
  );
}
