import React from 'react';

export default [
  {
    id: 'primaryColor',
    title: 'Primary Color',
    description: () => <>The primary brand color of for your application.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#417DE8',
  },
  {
    id: 'secondaryColor',
    title: 'Secondary Color',
    description: () => <>The secondary brand color of for your application.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#274B8B',
  },
  {
    id: 'successColor',
    title: 'Success Intent Color',
    description: () => <>The color for the success intent.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#0F9960',
  },
  {
    id: 'warningColor',
    title: 'Warning Intent Color',
    description: () => <>The color for the warning intent.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#F29D49',
  },
  {
    id: 'dangerColor',
    title: 'Danger Intent Color',
    description: () => <>The color for the danger intent.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#DB3737',
  },
  {
    id: 'disabledColor',
    title: 'Disabled Intent Color',
    description: () => <>The color for the disabled intent.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#DDDDDD',
  },
  {
    id: 'noneColor',
    title: 'No Intent Color',
    description: () => <>The color for no intent.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#EEEEEE',
  },
  {
    id: 'backgroundColor',
    title: 'Background Color',
    description: () => <>The main background color of your application.</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#FFFFFF',
  },
  {
    id: 'iconColor',
    title: 'Icon Color',
    description: () => <>The color of icons (on light backgrounds).</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#212121',
  },
  {
    id: 'iconHoverColor',
    title: 'Icon Hover Color',
    description: () => <>The hover color of icons (on light backgrounds).</>,
    type: 'color',
    group: 'General',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'iconColorDark',
    title: 'Icon Color Dark',
    description: () => <>The color of icons (on dark backgrounds).</>,
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#FFFFFF',
  },
  {
    id: 'surfaceBackgroundColor',
    title: 'Surface Background Color',
    description: () => (
      <>
        The background color of{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-surface--default"
          target="_blank"
        >
          Surfaces
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'General',
    parent: null,
    defaultValue: '#ffffff',
  },
  {
    id: 'surfaceBorderRadius',
    title: 'Surface Border Radius',
    description: () => (
      <>
        The border radius of{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-surface--default"
          target="_blank"
        >
          Surfaces
        </a>
        .
      </>
    ),
    type: 'string',
    group: 'General',
    parent: null,
    defaultValue: '0px',
  },
  {
    id: 'disableElevation',
    title: 'Disable Surface Elevation',
    description: () => (
      <>
        Disables elevation for{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-surface--default"
          target="_blank"
        >
          Surfaces
        </a>
        .
      </>
    ),
    type: 'boolean',
    group: 'General',
    parent: null,
    defaultValue: false,
  },
  {
    id: 'shadow',
    title: 'Shadows',
    description: () => <>Shadows of components.</>,
    type: 'string',
    group: 'General',
    parent: null,
    defaultValue: '0 0 40px 0 rgba(0, 0, 0, 0.05)',
  },
  {
    id: 'mobileBreakpoint',
    title: 'Breakpoint Mobile',
    description: () => <>Breakpoint below which to apply mobile styles.</>,
    type: 'string',
    group: 'General',
    parent: null,
    defaultValue: '375px',
  },
  {
    id: 'tabletBreakpoint',
    title: 'Breakpoint Tablet',
    description: () => <>Breakpoint below which to apply tablet styles.</>,
    type: 'string',
    group: 'General',
    parent: null,
    defaultValue: '768px',
  },
  {
    id: 'contrastLuminanceThreshold',
    title: 'Contrast Luminance Threshold',
    description: () => (
      <>Determines when to switch from dark to light colors based on the background color.</>
    ),
    type: 'number',
    group: 'General',
    parent: null,
    defaultValue: 60,
  },
];
