import React, { Component } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Classes } from '@blueprintjs/core';
import gql from 'graphql-tag';
import { NODE_TYPE_TEXT } from '/constants';
import cardSVG from './campaign.svg';

const Container = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fcfcfc;
  &:hover {
  }
`;

const QUERY = gql`
  {
    campaigns {
      edges {
        node {
          ...NodeFields
        }
      }
    }
  }

  fragment NodeFields on Node {
    id
    title
    resourceType
    nodeType
  }
`;

export default class CampaignCardView extends Component {
  render() {
    const { id } = this.props;
    return (
      <Query query={QUERY}>
        {({ data, loading }) => {
          const campaigns = loading ? [] : data.campaigns.edges.map(({ node }) => node);
          const selected = campaigns.find(c => c.id === id);
          const campaignType = NODE_TYPE_TEXT[selected?.nodeType];
          return (
            <Container {...this.props}>
              <div className={Classes.TEXT_MUTED} style={{ textAlign: 'center' }}>
                <h3>{campaignType}</h3>
              </div>
              <h4 style={{ marginTop: '0' }}>{selected ? `${selected.title}` : ''}</h4>
              <img src={cardSVG} />
            </Container>
          );
        }}
      </Query>
    );
  }
}
