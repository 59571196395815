import React, { Component } from 'react';
import { Button, Collapse, Classes } from '@blueprintjs/core';
import { isProd } from '/config';

export default class Debug extends Component {
  state = {
    isOpen: false,
  };
  handleClick = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  render() {
    const { data = {}, show } = this.props;
    const { isOpen } = this.state;

    return (
      !isProd && (
        <div className={Classes.RUNNING_TEXT}>
          {!show && <Button onClick={this.handleClick}>{isOpen ? 'Hide' : 'Show'} Debug</Button>}
          <Collapse isOpen={show || isOpen}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Collapse>
        </div>
      )
    );
  }
}
