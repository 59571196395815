import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { NODE_TYPE_TEXT, STATUS_TEXT } from '/constants';

import ResourceSelect from './ResourceSelect';

const QUERY = gql`
  query Nodes($selector: NodeSelectorInput) {
    nodes(first: 0, selector: $selector) {
      edges {
        node {
          ...NodeFields
        }
      }
    }
  }

  fragment NodeFields on Node {
    id
    title
    slug
    resourceType
    nodeType
    status
  }
`;

const Item = styled.div`
  width: 400px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.span``;

const renderItem = (item, { modifiers }) => (
  <Item>
    <div
      style={{
        maxWidth: '70%',
      }}
    >
      <h4
        style={{
          margin: 0,
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {item.title}
      </h4>
      <Info
        className={classNames({
          [Classes.TEXT_MUTED]: !modifiers.active,
        })}
      >
        {item.slug}
      </Info>
    </div>
    <div style={{ display: 'flex', flexFlow: 'column', maxWidth: '30%', textAlign: 'right' }}>
      <Info
        className={classNames({
          [Classes.TEXT_MUTED]: !modifiers.active,
        })}
      >
        {NODE_TYPE_TEXT[item.nodeType]}
      </Info>
      <Info
        className={classNames({
          [Classes.TEXT_MUTED]: !modifiers.active,
        })}
      >
        {STATUS_TEXT[item.status]}
      </Info>
    </div>
  </Item>
);

export default ({ onLoad, onSelect, selected, name = 'Node', resourceTypes, ...props }) => (
  <ResourceSelect
    onLoad={onLoad}
    query={QUERY}
    variables={{
      selector: {
        resourceType: {
          in: resourceTypes,
        },
      },
    }}
    selectItems={({ nodes }) => nodes.edges.map(({ node }) => node)}
    onSelect={onSelect}
    nameProp="title"
    renderItem={renderItem}
    selected={selected}
    resourceName={name}
    {...props}
  />
);
