/* global Raven */
import load from 'load-script';
import { SENTRY_ENDPOINT, CONTEXT } from '/config';
import createClient, { getProjectId } from '/data/client';
import store from '/data/store';

const loadRaven = () =>
  new Promise(r => {
    load('https://cdn.ravenjs.com/3.24.0/raven.min.js', { attrs: { crossorigin: 'anonymous' } }, r);
  });

async function bootstrap() {
  await loadRaven();
  Raven.config(SENTRY_ENDPOINT, { environment: CONTEXT }).install();

  let projectId;
  store.subscribe(() => {
    const prevId = projectId;
    projectId = getProjectId();
    if (projectId === prevId) return;
    Raven.setExtraContext({ projectId });
  });

  const client = await createClient();
  window.__WINGS_CLIENT__ = client;
  return { client };
}

let promise;

export default () => {
  if (!promise) {
    promise = bootstrap();
  }
  return promise;
};
