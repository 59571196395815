import React from 'react';
import { compose, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select as _Select } from '@blueprintjs/select';

const Select = styled(_Select)`
  .bp3-popover-content {
    padding: 5px;
    max-height: 200px;
  }
`;

export default compose(
  setPropTypes({
    projects: PropTypes.array,
    setProject: PropTypes.func.isRequired,
    activeProject: PropTypes.object,
  }),
)(({ projects, setProject, activeProject = {} }) => (
  <Select
    filterable
    items={projects}
    itemPredicate={(query, item) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0}
    noResults={<MenuItem disabled text="No projects found." />}
    activeItem={projects.find(({ id }) => id === activeProject.id)}
    itemRenderer={(p, { handleClick }) => (
      <MenuItem
        key={`project-menu-item-${p.id}`}
        text={p.name}
        onClick={handleClick}
        active={activeProject.id === p.id}
      />
    )}
    onItemSelect={p => setProject(p.id)}
  >
    <Button text={activeProject.name || 'Select Project'} rightIcon="caret-down" />
  </Select>
));
