import React, { Component } from 'react';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import PropTypes from 'prop-types';
import { FormGroup } from '@blueprintjs/core';
import gql from 'graphql-tag';
import ResourceSelect from '/components/ResourceSelect';
import SettingsCard from './SettingsCard';

const TopBar = styled.div``;

const isApp = id => p => p.app.id === id;

export default class AppSettings extends Component {
  static propTypes = {
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        app: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }).isRequired,
        menu: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    ),
  };
  state = {
    selected: null,
  };

  getApp = id => this.props.settings.find(isApp(id)) || { app: { id } };
  getSelectedApp = () => this.getApp(this.state.selected);
  isSelectedApp = (...args) => isApp(this.state.selected)(...args);
  getMenuId = () => {
    const settings = this.getSelectedApp();
    return settings && settings.menu && settings.menu.id;
  };

  handleMenuChange = ({ id }) => {
    const filtered = this.props.settings.filter((...args) => !this.isSelectedApp(...args));

    this.props.onChange(
      filtered.concat({
        ...this.getSelectedApp(),
        menu: { id },
      }),
    );
  };

  render() {
    const {
      onChange, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    const { selected } = this.state;

    return (
      <SettingsCard {...filterProps(props)}>
        <TopBar>
          Change settings for app:&nbsp;
          <ResourceSelect
            query={gql`
              {
                items: apps {
                  id
                  name
                }
              }
            `}
            intent="PRIMARY"
            onSelect={({ id }) => this.setState({ selected: id })}
            resourceName="App"
            nameProp="name"
            selected={selected}
            style={{ display: 'inline-block' }}
          />
        </TopBar>

        {selected && (
          <div>
            <hr />
            <h3>Settings</h3>
            <FormGroup label="Menu">
              <ResourceSelect
                query={gql`
                  {
                    items: menus {
                      id
                      name
                    }
                  }
                `}
                onSelect={this.handleMenuChange}
                resourceName="Menu"
                nameProp="name"
                selected={this.getMenuId()}
              />
            </FormGroup>
          </div>
        )}
      </SettingsCard>
    );
  }
}
