import React from 'react';
import SettingsCard from './SettingsCard';
import CopyEditor from '/components/CopyEditor';

export default function CopySettings({ resourceType, copyOverrides, onCopyChange, localeId }) {
  return (
    <SettingsCard>
      <CopyEditor
        resourceType={resourceType}
        overrides={copyOverrides}
        onChange={v => onCopyChange(v.map(({ messageId, message }) => ({ messageId, message })))}
        localeId={localeId}
      />
    </SettingsCard>
  );
}
