import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import qs from 'qs';

const Figure = styled.figure`
  clear: both;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  min-height: 400px;
  .video-wrapper {
    position: relative;
    height: 0;
    iframe {
      pointer-events: none;
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const getMatches = (target, re) =>
  Object.keys(re).reduce((acc, key) => ({ ...acc, [key]: re[key].exec(target)[1] }), {});

export default class EmbedCardView extends Component {
  static propTypes = {
    src: PropTypes.string,
    html: PropTypes.string,
  };

  static defaultProps = {
    src: '',
    html: '',
  };

  state = {
    percentage: 56,
  };

  determineDimensions(props = this.props) {
    setTimeout(() => {
      const { width, height } = getMatches(props.html, {
        width: /width="(\d+)"/i,
        height: /height="(\d+)"/i,
      });
      const ratio = height / width;
      const percentage = ratio * 100;
      this.setState({ percentage });
    }, 0);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.determineDimensions(props);
  }

  constructor(props) {
    super();
    this.determineDimensions(props);
  }

  render() {
    const { html, ...props } = this.props;
    const resourceId = qs.parse(this.props.src.split('?')[1]).v;
    return (
      <Figure {...filterProps(props)}>
        <div
          className="video-wrapper"
          style={{ paddingBottom: `${this.state.percentage}%` }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<iframe width="480" height="270" src="https://www.youtube.com/embed/${resourceId}?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
          }} // eslint-disable-line react/no-danger
        />
      </Figure>
    );
  }
}
