import themeSettings from './themeSettings';

export default class Theme {
  static groups = Object.keys(themeSettings.reduce((g, s) => ({ ...g, [s.group]: 1 }), {}));
  groups = Theme.groups;

  constructor(arr) {
    this.variables = themeSettings.reduce((vars, s) => {
      let value = arr.find(e => e.key === s.id)?.value;
      switch (s.type) {
        case 'boolean':
          value = value === 'false' ? false : !!value;
          break;
        default:
          value = value;
      }
      return { ...vars, [s.id]: value };
    }, {});
  }

  getValue(key) {
    return this.variables[key];
  }

  setValue(key, value) {
    this.variables[key] = value;
  }

  toArray() {
    return Object.keys(this.variables).reduce((arr, key) => {
      if (
        this.variables[key] !== undefined &&
        this.variables[key] !== false &&
        this.variables[key] !== null &&
        this.variables[key] !== ''
      )
        return [...arr, { key, value: this.variables[key].toString() }];
      return arr;
    }, []);
  }

  getInheritedValue(key) {
    const setting = themeSettings.find(s => s.id === key);
    const v = this.getValue(key);
    if (v !== null && v !== undefined) return v;
    if (!setting.parent) {
      const dv = setting.defaultValue;
      if (dv === null || dv === undefined)
        throw Error(`No default value found for theme variable ${key}`);
      return dv;
    }
    return this.getInheritedValue(setting.parent);
  }

  getInheritedSettings(key, deps = []) {
    const setting = themeSettings.find(s => s.id === key);
    if (!setting.parent) return deps;
    return this.getInheritedSettings(setting.parent, [
      ...deps,
      themeSettings.find(s => s.id === setting.parent),
    ]);
  }

  getGroupSettings(group) {
    return themeSettings.filter(s => s.group === group);
  }
}
