import gql from 'graphql-tag';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import useData from './useData';

const PROJECT_QUERY = gql`
  query Projects {
    projects(first: 0) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;


const projectIdFromPath = path => {
  const [, projectId] = path.split('/');
  return projectId;
};


export default function useProjects() {
  const { client, dispatch, data } = useData(PROJECT_QUERY)
  const pathname = useSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => pathname,
  );
  const projects = data?.edges?.map(({ node }) => node);
  const activeProjectId = projectIdFromPath(pathname);
  const projectUrl = path => `/${activeProjectId}${path}`;
  const goTo = (path = '', { projectId = activeProjectId } = {}) =>
    dispatch(push(`/${projectId}${path}`));
  return {
    data,
    projectUrl,
    activeProject: projects?.find(p => p.id === projectIdFromPath(pathname)) || {},
    activeProjectId: projectIdFromPath(pathname),
    projects,
    goTo,
    setProject: projectId => {
      client.resetStore();
      goTo('', { projectId });
    },
  };
};


