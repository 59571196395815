import React from 'react';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import styled from 'styled-components';
import { gqlFetcher } from '/data/client';

const Wrapper = styled.main`
  height: calc(100% - 50px);
`;

export default () => (
  <Wrapper>
    <GraphiQL fetcher={gqlFetcher} />
  </Wrapper>
);
