import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InputGroup as _InputGroup, Tooltip, Button, ButtonGroup, Tag } from '@blueprintjs/core';

const InputGroup = styled(_InputGroup)`
  input {
    user-select: none;
    color: transparent;
    text-shadow: 0 0 0 #182026;
    cursor: pointer !important;
    &:focus {
      outline: none;
    }
  }
`;

export default function ClipboardInput({ value, secret, labels, style, ...props }) {
  const [copied, setCopied] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reveal, setReveal] = useState(false);
  const [shouldCopy, setShouldCopy] = useState(false);
  const revealed = !(secret && !reveal);
  const input = useRef(null);

  const handleSelect = () => {
    setDisabled(false);
  };

  const handleCopy = () => {
    handleSelect();
    setShouldCopy(true);
  };

  const handleReveal = () => setReveal(true);

  useEffect(() => {
    if (!disabled) input.current.select();
    if (shouldCopy) {
      document.execCommand('copy');
      setCopied(true);
    }
    setShouldCopy(false);
    setDisabled(true);
  }, [disabled, shouldCopy]);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => setCopied(false), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div style={{ display: 'inline', ...style }} onClick={handleSelect} {...props}>
      <InputGroup
        large
        value={revealed ? value : '************************'}
        inputRef={input}
        readOnly
        disabled={disabled}
        rightElement={
          !revealed ? (
            <ButtonGroup>
              {!labels?.length ? null : labels.map(label => <Tag round>{label}</Tag>)}
              <Tooltip content="Reveal" key="reveal-tooltip">
                <Button icon="eye-open" minimal onClick={handleReveal} />
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              {!labels?.length ? null : labels.map(label => <Tag round>{label}</Tag>)}
              <Tooltip content={copied ? 'Copied' : 'Copy...'} key="copy-tooltip">
                <Button icon="duplicate" minimal onClick={handleCopy} />
              </Tooltip>
            </ButtonGroup>
          )
        }
      />
    </div>
  );
}
