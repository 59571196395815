import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setStatic } from 'recompose';
import FromNow from '/components/FromNow';
import withProjects from '/containers/withProjects';
import withRouter from '/containers/withRouter';
import ContentView from './ContentView';

const FIELDS = ['', 'Order #', 'Name', 'Total', 'Status', 'Created', 'Gateway'];

export default compose(
  withRouter,
  setPropTypes({
    orders: PropTypes.array.isRequired,
  }),
  setStatic('defaultProps', {
    orders: [],
  }),
  withProjects,
)(({ orders = [], onCreate, historyPush }) => (
  <ContentView fields={FIELDS} list onCreate={onCreate}>
    {orders.map(order => (
      <ContentView.Item
        key={`order-list-item${order.id}`}
        onClick={() => historyPush(order.editUrl)}
      >
        <ContentView.Field />
        <ContentView.Field>
          <Link to={order.editUrl}>{order.id}</Link>
        </ContentView.Field>
        <ContentView.Field>
          {order.customer.billing.firstName} {order.customer.billing.lastName}
        </ContentView.Field>
        <ContentView.Field>
          {order.total.currency.symbol} {order.total.amount / 100}
        </ContentView.Field>
        <ContentView.Field>{order.status.toLowerCase()}</ContentView.Field>
        <ContentView.Field>
          <FromNow date={order.createdAt} />
        </ContentView.Field>
        <ContentView.Field>
          <a
            href={order.transactions[0]?.gateway.externalTransactionUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {order.transactions[0]?.gateway.externalTransactionId}
          </a>
        </ContentView.Field>
      </ContentView.Item>
    ))}
  </ContentView>
));
