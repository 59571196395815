import React, { Component } from 'react';
import styled from 'styled-components';
import { modularScale } from 'polished';
import propTypes, { defaultProps } from './propTypes';

const Insight = styled.p`
  background: #f5f8fa;
  font-size: ${modularScale(4)};
  font-weight: 700;
  padding: 1rem;
  text-align: center;
  * {
    text-align: center;
  }
`;

export default class InsightView extends Component {
  static setPropTypes = propTypes;
  static defaultProps = defaultProps;
  render() {
    const { text, ...props } = this.props;
    return (
      <Insight {...props}>
        <strong>{text}</strong>
      </Insight>
    );
  }
}
