import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 16px;
    background-color: #ebf1f5;
  }

  ul.bp3-button-group {
    list-style: none;
    padding-left: 0;
  }

  *:focus {
    outline: none;
  }

  .CodeMirror pre {
    -webkit-box-shadow: initial;
    box-shadow: initial;
  }

  .bp3-select-popover {
    .bp3-menu {
      max-height: 300px;
      overflow-y: scroll;
    }
  }
`;
