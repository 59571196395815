import React, { Component } from 'react';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import SchemaForm from 'react-jsonschema-form';
import ShadowDom from 'react-shadow';

class Form extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <ShadowDom
        include={[
          '//cdnjs.cloudflare.com/ajax/libs/bootswatch/3.3.6/cosmo/bootstrap.min.css',
          '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css',
        ]}
      >
        <div>
          <SchemaForm {...props}>
            <input type="submit" style={{ display: 'none' }} />
          </SchemaForm>
        </div>
      </ShadowDom>
    );
  }
}

const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  > div {
    margin: 0 8px;
    flex: 2;
  }
`;

class FormDefinition {
  constructor({ fields }) {
    this.fields = fields;
  }

  static fieldDefToJSONSchema = fields => {
    const fd = new FormDefinition({ fields });
    return fd.toJSONSchema();
  };

  toJSONSchema() {
    return {
      type: 'object',
      required: this.fields.filter(f => !!f.required).map(f => f.id.toString()),
      properties: {
        ...this.fields.reduce((props, field) => {
          switch (field.type) {
            case 'string':
              return {
                ...props,
                [field.id]: {
                  type: 'string',
                  title: field.label || '',
                  format: field.format || '',
                },
              };
            case 'number':
              return {
                ...props,
                [field.id]: {
                  type: 'integer',
                  title: field.label || '',
                },
              };
            default:
              return props;
          }
        }, {}),
      },
    };
  }
}

// const FIELD_DEFS = [
//   { id: 'firstName', type: 'string', label: 'First name', required: true },
//   { id: 'lastName', type: 'string', label: 'Last name', required: true },
//   { id: 'email', type: 'string', label: 'Email', required: true },
//   { id: 'askodmasoaa', type: 'string', label: 'Adres', required: true },
//   { id: 'ugmbkdmsoxp', type: 'string', label: 'Postcode', required: true },
//   { id: 'm69girmfDDj', type: 'string', label: 'Plaats', required: true },
//   { id: 'h3fDoLbjTfd', type: 'string', label: 'Land', required: true },
// ];

// const formDef = new FormDefinition({
//   fields: FIELD_DEFS,
// });

const fieldDefSchema = {
  type: 'array',
  items: {
    required: ['label'],
    type: 'object',
    title: 'Field',
    properties: {
      label: { type: 'string' },
      format: {
        type: 'string',
        enum: ['date', 'email'],
        enumNames: ['Date', 'Email'],
      },
      required: { type: 'boolean' },
    },
  },
};

export default class DForm extends Component {
  state = {
    fieldDefFormData: [],
    formData: {},
  };
  render() {
    const { ...props } = this.props;
    const { formData, fieldDefFormData } = this.state;
    const schema = FormDefinition.fieldDefToJSONSchema(
      fieldDefFormData.map((fd, i) => ({
        id: i,
        ...fd,
        type: 'string',
      })),
    );
    return (
      <Wrapper {...filterProps(props)} className="bp3-running-text">
        <div>
          <h3>Form definition</h3>
          <hr />
          <Form
            loadBootstrap
            schema={fieldDefSchema}
            formData={fieldDefFormData}
            onChange={({ formData }) => this.setState({ fieldDefFormData: formData })}
          />
          <h4>Data</h4>
          <pre>{JSON.stringify(fieldDefFormData, null, 2)}</pre>
        </div>
        <div>
          <h3>generated jsonschema</h3>
          <pre>{JSON.stringify(schema, null, 2)}</pre>
        </div>
        <div>
          <h3>Form (generated from jsonschema)</h3>
          <hr />
          <Form
            loadBootstrap
            schema={schema}
            formData={formData}
            onChange={({ formData }) => this.setState({ formData })}
          />
          <h4>Data</h4>
          <pre>{JSON.stringify(formData, null, 2)}</pre>
        </div>
      </Wrapper>
    );
  }
}
