import React, { Component } from 'react';
import { render } from 'react-dom';
import FormPanel from './components/FormPanel';
import PanelStack from './components/PanelStack';
import Dialog from './components/Dialog';

export default class Wizard extends Component {
  static create(props, container = document.body) {
    if (props != null && props.usePortal != null) console.warn('inline warning');
    const containerElement = document.createElement('div');
    container.appendChild(containerElement);
    const wizard = render(<Wizard {...props} usePortal={true} />, containerElement);
    if (wizard == null) {
      throw new Error('error create wizard');
    }
    return wizard;
  }

  state = {
    title: 'Setup',
    steps: [],
    data: [],
  };

  init({ title = 'Setup', steps = [], value }) {
    return new Promise((res, rej) => {
      this.resolve = res;
      this.reject = rej;
      setTimeout(() => {
        this.setState({ title, steps, data: value || [] });
      }, 0);
    });
  }

  handleChange = (step, formData) => {
    this.setState(({ data }) => ({
      data: data
        .slice(0, step)
        .concat([formData])
        .concat(data.slice(step + 1)),
    }));
  };

  handleFinish = () => {
    this.resolve(this.state.data);
    this.setState({ steps: [] });
  };

  handleCancel = () => {
    this.reject();
    this.setState({ steps: [] });
  };

  getStepData = step => this.state.data[step];

  render() {
    const { ...props } = this.props;
    const { title, steps } = this.state;
    if (!steps.length) return '';
    return (
      <Dialog
        {...props}
        {...{
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: steps.length,
          onClose: this.handleCancel,
        }}
        title={title}
      >
        <PanelStack
          style={{ height: 600 }}
          initialPanel={{
            title: steps[0].title,
            component: FormPanel,
            props: {
              steps,
              getStepData: this.getStepData,
              onChange: this.handleChange,
              onFinish: this.handleFinish,
            },
          }}
        />
      </Dialog>
    );
  }
}
