import React from 'react';
import { compose, setDisplayName } from 'recompose';
import JSON5 from 'json5';
import Form from '/components/Form';
import SettingsCard from './SettingsCard';

const parse = d => {
  try {
    return JSON.parse(d);
  } catch {
    return {};
  }
};

const SchemaSettings = ({ definition, onChange, data }) => (
  <SettingsCard>
    <Form
      liveValidate
      schema={JSON5.parse(definition)}
      formData={parse(data)}
      onChange={({ formData }) => onChange(JSON.stringify(formData))}
    />
  </SettingsCard>
);

export default compose(setDisplayName('SchemaSettings'))(SchemaSettings);
