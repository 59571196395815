import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { modularScale } from 'polished';
import { Content } from '@wingsplatform/react';
import propTypes, { defaultProps } from './propTypes';

const Testimonial = styled.section`
  background: #f5f8fa;
  clear: both;
  padding: 10px;
  text-align: center;
  header {
    text-align: center;
  }
`;

const Intro = styled.p`
  font-size: ${modularScale(1)};
`;

const Image = styled.figure`
  background: #f5f8fa;
  margin-left: 0;
  margin-right: 0;
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: auto;
  }
`;

export default class TestimonialCardView extends Component {
  static setPropTypes = propTypes;
  static defaultProps = defaultProps;
  render() {
    const { title, intro, content, src, ...props } = this.props;
    return (
      <Testimonial {...props}>
        <Icon icon="comment" />
        {!(title || intro) ? null : (
          <header>
            {!title ? null : <h2>{title}</h2>}
            {!intro ? null : <Intro>{intro}</Intro>}
          </header>
        )}
        <Image>
          <img src={src} alt="" />
        </Image>
        {content && <Content content={content} />}
      </Testimonial>
    );
  }
}
