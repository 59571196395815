import React from 'react';
import { Mutation } from 'react-apollo';
import rid from 'random-id';

export const randomId = (n = 17) => rid(n, 'Aa0');
window.randomId = randomId;

export const wait = n => new Promise(r => setTimeout(() => r(), n));

export const enumerate = (...args) => {
  const obj = {};
  args.forEach((arg, i) => {
    if (Array.isArray(arg)) {
      obj[(obj[arg[0]] = arg[1])] = arg[0]; // eslint-disable-line prefer-destructuring
    } else {
      obj[(obj[arg] = i)] = arg;
    }
  });
  return obj;
};

export const fetchProgress = (url, opts = {}, onProgress) =>
  new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (const k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = e => res(JSON.parse(e.target.responseText));
    xhr.onerror = rej;
    if (xhr.upload && onProgress)
      xhr.upload.onprogress = e =>
        onProgress({
          progress: Math.floor((e.loaded / e.total) * 100),
          total: e.total,
        });
    xhr.send(opts.body);
  });

const lowerCaseFirstChar = s => `${s[0].toLowerCase()}${s.slice(1)}`;

export const upperCaseFirstChar = s => `${s[0].toUpperCase()}${s.slice(1)}`;

export const createHOC = query => {
  const operationDef = query.definitions[0];
  const {
    operation,
    name: { value: nameVal },
  } = operationDef;

  if (operation !== 'mutation') throw new Error('createHOC only supports mutations');
  const name = lowerCaseFirstChar(nameVal);

  return Comp => props => (
    <Mutation mutation={query}>
      {(mut, { loading, error }) => (
        <Comp
          {...props}
          {...{
            [name]: mut,
            [`${name}Loading`]: loading,
            [`${name}Error`]: error,
          }}
        />
      )}
    </Mutation>
  );
};

export const createAsyncIterable = (fn, { delay = 1000 } = {}) => ({
  async *[Symbol.asyncIterator]() {
    while (true) {
      yield await fn();
      await wait(delay);
    }
  },
});
