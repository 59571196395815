import React from 'react';
import gql from 'graphql-tag';
import TabularResource from '/components/TabularResource';
import Menu from './Menu';
import { MenuFields, MenuMutable } from '/data/fragments';

const MENUS = gql`
  ${MenuFields}
  query Menus {
    items: menus {
      ...MenuFields
    }
  }
`;

const CREATE = gql`
  ${MenuFields}
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      menu {
        ...MenuFields
      }
    }
  }
`;

const UPDATE = gql`
  ${MenuFields}
  mutation UpdateMenu($input: UpdateMenuInput!) {
    updateMenu(input: $input) {
      menu {
        ...MenuFields
      }
    }
  }
`;

const DELETE = gql`
  ${MenuFields}
  mutation DeleteMenu($input: DeleteMenuInput!) {
    deleteMenu(input: $input) {
      menu {
        ...MenuFields
      }
    }
  }
`;

export default () => (
  <TabularResource
    resourceName="Menu"
    tabInfo={menu => ({ title: menu.name })}
    mutableFragment={MenuMutable}
    queries={{
      create: {
        query: CREATE,
        variables: { input: { menu: { name: 'My Menu' } } },
      },
      read: { query: MENUS },
      update: {
        query: UPDATE,
        variables: (id, menu) => ({ input: { menu, id } }),
      },
      delete: { query: DELETE, variables: id => ({ input: { id } }) },
    }}
  >
    {(menu, { onChange }) => <Menu menu={menu} onChange={onChange} />}
  </TabularResource>
);
