import React, { useEffect } from 'react';
import { Tab, Tabs as BTabs } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';
import Card from '/components/Card';

export default withRouter(({ children, titles = [], routed, location, history, ...props }) => {
  const tabIds = titles.map(t => t.toLowerCase());
  const goTo = tabId => history.push(`${location.pathname}#${tabId}`);

  useEffect(() => {
    if (!routed || location.hash) return;
    goTo(tabIds[0]);
  }, [routed, location]);

  const onChange = tabId => {
    if (!routed) return;
    goTo(tabId);
  };

  return (
    <BTabs
      animate
      renderActiveTabPanelOnly
      selectedTabId={routed ? location.hash.substr(1) : undefined}
      onChange={onChange}
      {...props}
    >
      {React.Children.map(children, (child, i) => (
        <Tab
          {...child.props.tabProps}
          id={tabIds[i]}
          title={titles[i]}
          panel={<Card>{child}</Card>}
        />
      ))}
    </BTabs>
  );
});
