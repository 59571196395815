import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Intent, Popover, Menu, MenuItem, Position } from '@blueprintjs/core';

import { usePaginatedQuery } from '/hooks';
import Composer from '/lib/composer';

import Breadcrumb from '/components/Breadcrumb';
import withRouter from '/containers/withRouter';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import withProjects from '/containers/withProjects';
import { SignupFields, PetitionFields, EventFields, FundraiserFields } from '/data/fragments';
import toaster from '/lib/toaster';
import CampaignList from '/components/CampaignList';
import Card from '/components/Card';
import ActionBar from '/components/ActionBar';

const CAMPAIGNS = gql`
  query Campaigns($after: String) {
    campaigns(first: 20, after: $after) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          nodeType
          id
          title
          slug
          locale {
            id
            name
            primary
          }
          status
          publishedAt
          updatedAt
          createdAt
        }
      }
    }
  }
`;

const CREATE_SIGNUP = gql`
  mutation CreateSignup($input: CreateSignupInput!) {
    createSignup(input: $input) {
      signup {
        ...SignupFields
      }
    }
  }
  ${SignupFields}
`;

const CREATE_PETITION = gql`
  mutation CreatePetition($input: CreatePetitionInput!) {
    createPetition(input: $input) {
      petition {
        ...PetitionFields
      }
    }
  }
  ${PetitionFields}
`;

const CREATE_EVENT = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      event {
        ...EventFields
      }
    }
  }
  ${EventFields}
`;

const CREATE_FUNDRAISER = gql`
  mutation CreateFundraiser($input: CreateFundraiserInput!) {
    createFundraiser(input: $input) {
      fundraiser {
        ...FundraiserFields
      }
    }
  }
  ${FundraiserFields}
`;

const Pagination = styled.div`
  text-align: center;
  margin-top: 20px;
`;

function Campaigns({ projectUrl, historyPush }) {
  const query = usePaginatedQuery({
    query: CAMPAIGNS,
    options: {
      fetchPolicy: 'cache-and-network',
    },
    pageInfoFromData: data => data.campaigns.pageInfo,
  });
  const campaigns = query.data?.campaigns?.edges?.map(({ node }) => node);
  const { PaginationControls } = query;

  return (
    <Composer
      components={[
        {
          component: Mutation,
          props: {
            mutation: CREATE_SIGNUP,
          },
        },
        {
          component: Mutation,
          props: {
            mutation: CREATE_PETITION,
          },
        },
        {
          component: Mutation,
          props: {
            mutation: CREATE_EVENT,
          },
        },
        {
          component: Mutation,
          props: {
            mutation: CREATE_FUNDRAISER,
          },
        },
      ]}
    >
      {([
        [createSignup, { loading: createSignupLoading }],
        [createPetition, { loading: createPetitionLoading }],
        [createEvent, { loading: createEventLoading }],
        [createFundraiser, { loading: createFundraiserLoading }],
      ]) =>
        query.loading ||
        createSignupLoading ||
        createPetitionLoading ||
        createEventLoading ||
        createFundraiserLoading ? (
          <Loading fill />
        ) : query.error ? (
          <ErrorCallout message="Something went wrong." />
        ) : (
          <>
            <ActionBar>
              <Breadcrumb
                items={[
                  {
                    text: 'Campaigns',
                  },
                ]}
              />
              <ActionBar.Actions>
                <Popover
                  content={
                    <Menu>
                      <MenuItem
                        text="Signup"
                        onClick={() => {
                          createSignup({
                            variables: {
                              input: { signup: { title: 'My Signup' } },
                            },
                          })
                            .then(({ data: { createSignup: { signup } } }) =>
                              historyPush(projectUrl(`/signups/${signup.id}`)),
                            )
                            .catch(() => toaster.danger('Something went wrong.'));
                        }}
                      />
                      <MenuItem
                        text="Petition"
                        onClick={() => {
                          createPetition({
                            variables: {
                              input: { petition: { title: 'My Petition' } },
                            },
                          })
                            .then(({ data: { createPetition: { petition } } }) =>
                              historyPush(projectUrl(`/petitions/${petition.id}`)),
                            )
                            .catch(() => toaster.danger('Something went wrong.'));
                        }}
                      />
                      <MenuItem
                        text="Event"
                        onClick={() => {
                          createEvent({
                            variables: {
                              input: {
                                event: {
                                  title: 'My Event',
                                  schedule: { start: Date.now() },
                                },
                              },
                            },
                          })
                            .then(({ data: { createEvent: { event } } }) =>
                              historyPush(projectUrl(`/events/${event.id}`)),
                            )
                            .catch(() => toaster.danger('Something went wrong.'));
                        }}
                      />
                      <MenuItem
                        text="Fundraiser"
                        onClick={() => {
                          createFundraiser({
                            variables: {
                              input: { fundraiser: { title: 'My Fundraiser' } },
                            },
                          })
                            .then(({ data: { createFundraiser: { fundraiser } } }) =>
                              historyPush(projectUrl(`/fundraisers/${fundraiser.id}`)),
                            )
                            .catch(() => toaster.danger('Something went wrong.'));
                        }}
                      />
                    </Menu>
                  }
                  position={Position.LEFT_TOP}
                >
                  <Button intent={Intent.PRIMARY} onClick={() => {}}>
                    New
                  </Button>
                </Popover>
              </ActionBar.Actions>
            </ActionBar>
            <Card style={{ width: '100%' }}>
              <CampaignList
                campaigns={campaigns.map(node => ({
                  ...node,
                  editUrl: projectUrl(`/${node.nodeType}s/${node.id}`),
                }))}
              />
              <Pagination>
                <PaginationControls />
              </Pagination>
            </Card>
          </>
        )
      }
    </Composer>
  );
}

export default compose(withRouter, withProjects)(Campaigns);
