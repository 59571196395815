import React from 'react';
import { compose, mapProps } from 'recompose';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import Breadcrumb from '/components/Breadcrumb';
import Loading from '/components/Loading';
import EntryEditor from '/components/EntryEditor';
import withProjects from '/containers/withProjects';
import ResourceNotFound from '/components/ResourceNotFound';

import {
  EntryFields,
  EntryMutable,
  filter,
  prepareNodeMutation,
  ProjectLocales,
} from '/data/fragments';

const Q = gql`
  query Entry($id: String!) {
    entry(selector: { id: { eq: $id } }) {
      ...EntryFields
    }
    project: currentProject {
      ...ProjectLocales
    }
  }
  ${EntryFields}
  ${ProjectLocales}
`;

const UPDATE = gql`
  mutation UpdateEntry($id: String!, $entry: EntryInput!) {
    updateEntry(input: { id: $id, entry: $entry }) {
      entry {
        ...EntryFields
      }
    }
  }
  ${EntryFields}
`;

const DELETE = gql`
  mutation DeleteEntry($id: String!) {
    deleteEntry(input: { id: $id }) {
      entry {
        ...EntryFields
      }
    }
  }
  ${EntryFields}
`;
const withUpdate = Comp => props => (
  <Mutation mutation={UPDATE}>
    {(updateEntry, { loading }) => <Comp {...props} saving={loading} updateEntry={updateEntry} />}
  </Mutation>
);

const withDelete = Comp => props => (
  <Mutation mutation={DELETE}>
    {(deleteEntry, { loading }) => <Comp {...props} deleting={loading} deleteEntry={deleteEntry} />}
  </Mutation>
);

class EntryView extends React.Component {
  handleUpdate = async (id, entry) =>
    this.props.updateEntry({
      variables: {
        id,
        entry: prepareNodeMutation(entry),
      },
    });
  handleDelete = async (id, entry) => {
    await this.props.deleteEntry({ variables: { id } });
    this.goBack(entry);
  };
  goBack = entry =>
    this.props.history.push(this.props.projectUrl(`/content?type=${entry?.type?.id || 'article'}`));

  handleGoBackClick = () => this.goBack();

  render() {
    const {
      match: {
        params: { id },
      },
      projectUrl,
      saving,
      deleting,
    } = this.props;
    return (
      <Query query={Q} fetchPolicy="cache-and-network" variables={{ id }}>
        {({ data: { entry = { type: {}, slug: '' }, project = {} } = {}, loading }) =>
          loading ? (
            <Loading fill />
          ) : !entry ? (
            <ResourceNotFound name="Entry" onGoBackClick={this.handleGoBackClick} />
          ) : (
            <React.Fragment>
              <Breadcrumb
                items={[
                  {
                    text: entry.type.name || '',
                    url: projectUrl(`/content?type=${entry.type.id}`),
                  },
                  { text: entry.title || id },
                ]}
              />
              <EntryEditor
                entry={entry}
                locales={project?.settings?.i18n?.locales}
                saving={saving}
                deleting={deleting}
                onSave={entry => this.handleUpdate(id, filter(EntryMutable, entry))}
                onDelete={() => this.handleDelete(entry.id, entry)}
              />
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default compose(
  withProjects,
  mapProps(({ match, ...rest }) => ({
    slug: match.params.slug,
    ...rest,
    match,
  })),
  withUpdate,
  withDelete,
)(EntryView);
