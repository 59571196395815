import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { randomId } from '/lib/utils';
import Input from '/components/Input';
import MobiledocEditor from '/components/MobiledocEditor';

const withoutIndex = (i, arr) => [...arr.slice(0, i), ...arr.slice(i + 1)];
const move = (array, index, delta) => {
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return array;
  const indexes = [index, newIndex].sort();
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
  return array;
};
const moveUp = (index, array) => move(array, index, -1);
const moveDown = (index, array) => move(array, index, 1);

class QAList extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: [],
  };

  updatedRowAtIndex = (i, payload) =>
    this.props.value.map((v, _i) => (i === _i ? { ...v, ...payload } : v));

  handleNewRowClick = () =>
    this.props.onChange([
      ...this.props.value,
      {
        _id: randomId(),
        question: '',
        answer: undefined,
      },
    ]);

  handleQuestionChange = i => v => this.props.onChange(this.updatedRowAtIndex(i, { question: v }));
  handleAnswerChange = i => v => this.props.onChange(this.updatedRowAtIndex(i, { answer: v }));
  handleRemove = i => () => this.props.onChange(withoutIndex(i, this.props.value));
  handleUp = i => () => this.props.onChange(moveUp(i, [...this.props.value]));
  handleDown = i => () => this.props.onChange(moveDown(i, [...this.props.value]));

  render() {
    const { value } = this.props;

    return (
      <div>
        <button onClick={this.handleNewRowClick}>Add Row</button>
        <div>
          {value.map(({ question, answer, _id }, row) => (
            <div key={btoa(`QACard>QAList${_id}`)}>
              <Input
                className="bp3-fill"
                name="question"
                placeholder="Question"
                onChange={e => this.handleQuestionChange(row)(e.target.value)}
                value={question}
              >
                Question
              </Input>
              <MobiledocEditor mini mobiledoc={answer} onChange={this.handleAnswerChange(row)} />
              <button onClick={this.handleUp(row)} disabled={row < 1}>
                <span role="img" aria-label="move up">
                  ☝️
                </span>
              </button>
              <button onClick={this.handleDown(row)} disabled={row > value.length - 2}>
                <span role="img" aria-label="move down">
                  👇
                </span>
              </button>
              <button onClick={this.handleRemove(row)}>X</button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default QAList;
