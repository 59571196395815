import React, { useEffect } from 'react';
import Loading from '/components/Loading';
import { useAuth } from '/hooks';

export default function LoginCallback({ history, location }) {
  const auth = useAuth();

  const handleAuthentication = async () => {
    if (/access_token|id_token|error/.test(location.hash)) {
      try {
        await auth.handleAuthentication();
        history.push(auth.consumePostLoginRedirectPath());
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    handleAuthentication();
  });

  return <Loading fill />;
}
