import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { ButtonGroup, Button, Intent } from '@blueprintjs/core';

const getPageNumbers = ({ current, total, size: maxSize = 7 }) => {
  const size = total < maxSize ? total : maxSize;
  const margin = Math.floor(size / 2);
  const shift = !(total <= maxSize);
  const first =
    (shift &&
      (total - current < margin ? total - margin * 2 : current > margin ? current - margin : 1)) ||
    1;
  return new Array(size).fill(0).map((_, i) => first + i);
};

const FPageInfo = `
  fragment PageInfo on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
    currentPage
    totalPages
  }
`;

export default ({ query: q, options, pageInfoFromData = data => data.items.pageInfo }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const qq = [q.loc.source.body, FPageInfo].join('\n');
  const qqq = gql`
    ${qq}
  `;
  const query = useQuery(qqq, {
    ...options,
    variables: {
      ...options.variables,
      after: `${currentPage - 1}`,
    },
  });

  const pageInfo = !query.loading ? pageInfoFromData(query.data) : {};

  const nextPage = () => {
    const totalPages = pageInfo?.totalPages || 0;
    if (totalPages && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage < 2) return;
    setCurrentPage(currentPage - 1);
  };

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(pageInfo.totalPages);

  const goToPage = n => setCurrentPage(n);

  function PaginationControls() {
    return (
      <ButtonGroup>
        <Button
          onClick={firstPage}
          disabled={!pageInfo.hasPreviousPage}
          icon="double-chevron-left"
        />
        <Button
          intent={Intent.PRIMARY}
          onClick={prevPage}
          disabled={!pageInfo.hasPreviousPage}
          icon="chevron-left"
        />
        {query.loading ? (
          <Button loading />
        ) : (
          getPageNumbers({
            current: currentPage,
            total: pageInfo.totalPages,
          }).map(n => (
            <Button active={n === currentPage} onClick={() => goToPage(n)}>
              {n}
            </Button>
          ))
        )}
        <Button
          intent={Intent.PRIMARY}
          onClick={nextPage}
          disabled={!pageInfo.hasNextPage}
          icon="chevron-right"
        />
        <Button onClick={lastPage} disabled={!pageInfo.hasNextPage} icon="double-chevron-right" />
      </ButtonGroup>
    );
  }

  return {
    ...query,
    currentPage,
    prevPage,
    nextPage,
    PaginationControls,
  };
};
