import React from 'react';

export default [
  {
    id: 'blockquoteBackgroundColor',
    title: 'Block Quote Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-blockquote--default"
          target="_blank"
        >
          Blockquote
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'blockquoteIconColor',
    title: 'Block Quote Icon Color',
    description: () => (
      <>
        The icon color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-blockquote--default"
          target="_blank"
        >
          Blockquote
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'blockquoteTextColor',
    title: 'Block Quote Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-blockquote--default"
          target="_blank"
        >
          Blockquote
        </a>{' '}
        component. Default calculated from text colors and the Blockquote background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'buttonBorderRadius',
    title: 'Button Border Radius',
    description: () => (
      <>
        The border radius of{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-button--primary"
          target="_blank"
        >
          Buttons
        </a>
        .
      </>
    ),
    type: 'string',
    group: 'Components',
    parent: 'surfaceBorderRadius',
    defaultValue: null,
  },
  {
    id: 'callToActionBackgroundColor',
    title: 'Call To Action Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'callToActionButtonBackgroundColor',
    title: 'Call To Action Button Background Color',
    description: () => (
      <>
        The background color of the buttons in the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'callToActionButtonBackgroundHoverColor',
    title: 'Call To Action Button Hover Background Color',
    description: () => (
      <>
        The hover background color of the buttons in the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'callToActionButtonTextColor',
    title: 'Call To Action Button Text Color',
    description: () => (
      <>
        The color of button text in the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'callToActionButtonTextHoverColor',
    title: 'Call To Action Button Hover Text Color',
    description: () => (
      <>
        The hover color of button text in the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'callToActionTextColor',
    title: 'Call To Action Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-calltoaction--default"
          target="_blank"
        >
          CallToAction
        </a>{' '}
        component. Default calculated from text colors and the CallToAction background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'collapseBackgroundColor',
    title: 'Collapse Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-collapse--default"
          target="_blank"
        >
          Collapse
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'collapseBackgroundHoverColor',
    title: 'Collapse Hover Color',
    description: () => (
      <>
        The background hover color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-collapse--default"
          target="_blank"
        >
          Collapse
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'dialogBackgroundColor',
    title: 'Dialog Background Color',
    description: () => (
      <>
        A background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-dialog--default"
          target="_blank"
        >
          Dialog
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'dialogCloseColor',
    title: 'Dialog Close Color',
    description: () => (
      <>
        A color of the closing cross for the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-dialog--default"
          target="_blank"
        >
          Dialog
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'iconColor',
    defaultValue: null,
  },
  {
    id: 'dialogCloseHoverColor',
    title: 'Dialog Close Hover Color',
    description: () => (
      <>
        A hover color of the closing cross for the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-dialog--default"
          target="_blank"
        >
          Dialog
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'iconColor',
    defaultValue: null,
  },
  {
    id: 'dialogTextColor',
    title: 'Dialog Text Color',
    description: () => (
      <>
        A text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-dialog--default"
          target="_blank"
        >
          Dialog
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'drawerBackgroundColor',
    title: 'Drawer Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-drawer--default"
          target="_blank"
        >
          Drawer
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'expandableBackgroundColor',
    title: 'Expandable Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-expandable--default"
          target="_blank"
        >
          Expandable
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'insightBackgroundColor',
    title: 'Insight Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-insight--default"
          target="_blank"
        >
          Insight
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'insightTextColor',
    title: 'Insight Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-insight--default"
          target="_blank"
        >
          Insight
        </a>{' '}
        component. Default calculated from text colors and the Insight background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'landingSectionBackgroundColor',
    title: 'Cover Header Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-header--cover"
          target="_blank"
        >
          Cover version of the Header
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'landingSectionTitleColor',
    title: 'Cover Header Title Text Color',
    description: () => (
      <>
        The title text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-header--cover"
          target="_blank"
        >
          Cover version of the Header
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'landingSectionTitleBackgroundColor',
    title: 'Cover Header Title Background Color',
    description: () => (
      <>
        The title background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-header--cover"
          target="_blank"
        >
          Cover version of the Header
        </a>{' '}
        component. Default is null.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: null,
    defaultValue: 'rgba(0, 0, 0, 0)',
  },
  {
    id: 'landingSectionArrowColor',
    title: 'Cover Header Arrow Color',
    description: () => (
      <>
        The arrow color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-header--cover"
          target="_blank"
        >
          Cover version of the Header
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'landingSectionTitleColor',
    defaultValue: null,
  },
  {
    id: 'landingSectionGradientColor',
    title: 'Cover Header Gradient Color',
    description: () => (
      <>
        The gradient color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-header--cover"
          target="_blank"
        >
          Cover version of the Header
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: null,
    defaultValue: 'rgba(0, 0, 0, 0.3)',
  },
  {
    id: 'pullquoteBackgroundColor',
    title: 'Pullquote Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-pullquote--default"
          target="_blank"
        >
          Pullquote
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'backgroundColor',
    defaultValue: null,
  },
  {
    id: 'pullquoteTextColor',
    title: 'Pullquote Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-pullquote--default"
          target="_blank"
        >
          Pullquote
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'scrollBarColor',
    title: 'Scroll Bar Color',
    description: () => (
      <>
        The bar color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-scrollbar--default"
          target="_blank"
        >
          ScrollBar
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'scrollBarBackgroundColor',
    title: 'Scroll Bar Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-scrollbar--default"
          target="_blank"
        >
          ScrollBar
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'sectionMarkerBackgroundColor',
    title: 'Section Marker Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-sectionmarker--default"
          target="_blank"
        >
          SectionMarker
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'sectionMarkerNumberColor',
    title: 'Section Marker Number Color',
    description: () => (
      <>
        The number color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-sectionmarker--default"
          target="_blank"
        >
          SectionMarker
        </a>{' '}
        component. Default calculated from text colors and the SectionMarker background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'sectionMarkerNumberOpacity',
    title: 'Section Marker Number Opacity',
    description: () => (
      <>
        The opacity for the number of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-sectionmarker--default"
          target="_blank"
        >
          SectionMarker
        </a>{' '}
        component. Number between 0 and 1, e.g. 1 or 0.7.
      </>
    ),
    type: 'number',
    group: 'Components',
    parent: null,
    defaultValue: '1',
  },
  {
    id: 'sectionMarkerTextColor',
    title: 'Section Marker Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-sectionmarker--default"
          target="_blank"
        >
          SectionMarker
        </a>{' '}
        component. Default calculated from text colors and the SectionMarker background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'sectionMarkerTitleColor',
    title: 'Section Marker Title Color',
    description: () => (
      <>
        The title color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-sectionmarker--default"
          target="_blank"
        >
          SectionMarker
        </a>{' '}
        component. Default calculated from text colors and the SectionMarker background.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'shareButtonBackgroundColor',
    title: 'Social Button Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-socialbuttons--default"
          target="_blank"
        >
          SocialButtons
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'shareButtonBackgroundHoverColor',
    title: 'Social Button Background Hover Color',
    description: () => (
      <>
        The hover background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-socialbuttons--default"
          target="_blank"
        >
          SocialButtons
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'shareButtonTextColor',
    title: 'Social Button Text Color',
    description: () => (
      <>
        The text (and icons) color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-socialbuttons--default"
          target="_blank"
        >
          SocialButtons
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'shareButtonTextHoverColor',
    title: 'Social Button Text Hover Color',
    description: () => (
      <>
        The text (and icons) color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-socialbuttons--default"
          target="_blank"
        >
          SocialButtons
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'testimonialBackgroundColor',
    title: 'Testimonial Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-cards-testimonialcard--default"
          target="_blank"
        >
          Testimonial
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Components',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
];
