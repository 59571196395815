import React from 'react';
import filterProps from 'filter-invalid-dom-props';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import _CodeMirror from 'react-codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/monokai';

import styled from 'styled-components';

const CodeMirror = styled(_CodeMirror)`
  .CodeMirror-cursor {
    border-left: 1px solid white;
  }
`;

const CODEMIRROR_CFG = {
  mode: 'javascript',
  theme: 'monokai',
  indentWithTabs: false,
  extraKeys: {
    Tab: cm => cm.execCommand('indentMore'),
    'Shift-Tab': cm => cm.execCommand('indentLess'),
  },
};
const CodeEditor = ({ value, onChange, options, ...props }) => (
  <CodeMirror
    value={value}
    onChange={onChange}
    {...filterProps(props)}
    options={{ ...CODEMIRROR_CFG, ...options }}
  />
);

export default compose(setDisplayName('CodeEditor'), setPropTypes({}))(CodeEditor);
