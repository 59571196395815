import React from 'react';
import { Select } from '@blueprintjs/select';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { MenuItem, Button } from '@blueprintjs/core';

const SET_CURRENCY_SETTINGS_MUTATION = gql`
  mutation SetCurrencySettings($input: UpdateCommerceCurrencySettingsInput) {
    updateCommerceCurrencySettings(input: $input) {
      currencySettings {
        currencyId
      }
    }
  }
`;

export default ({ settings, refetch }) => {
  const [setCurrencyId] = useMutation(SET_CURRENCY_SETTINGS_MUTATION);
  const { currencySettings: { currencyId, availableCurrencies = [] } = {} } = settings;
  const updateCurrency = async cur => {
    await setCurrencyId({
      variables: { input: { currencySettings: { currencyId: cur.id } } },
    });
    refetch();
  };

  const getText = currency => `${currency.symbol} ${currency.name} `;

  return (
    <div className="bp3-tab-panel">
      <h3>Currency</h3>
      <Select
        items={availableCurrencies.filter(cur => cur.id !== currencyId)}
        itemRenderer={(item, { handleClick, active }) => (
          <MenuItem onClick={handleClick} active={active} text={getText(item)} />
        )}
        onItemSelect={updateCurrency}
        itemPredicate={(query, item) =>
          [item.name, item.id, item.symbol]
            .join(' ')
            .toLowerCase()
            .indexOf((query || '').toLowerCase()) >= 0
        }
        noResults={<MenuItem disabled text="No results." />}
        filterable
      >
        <Button>{getText(availableCurrencies.find(c => c.id === currencyId))}</Button>
      </Select>
    </div>
  );
};
