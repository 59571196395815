import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { randomId } from '/lib/utils';
import { createCard } from '@wingsplatform/react';
import { Dialog as _Dialog, Tab, Tabs } from '@blueprintjs/core';
import withClickableHighlight from '/styles/withClickableHighlight';
import MediaPicker from '/components/MediaPicker';
import withMedia from '/containers/withMedia';
import TestimonialCardView from './TestimonialCardView';
import TYPE from './TestimonialCardView/enums';
import Input from '/components/Input';
import TextArea from '/components/TextArea';
import MobiledocEditor from '/components/MobiledocEditor';
import DialogStyle from '/styles/dialog';

const tabId = () => `tab-${randomId()}`;

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

class TestimonialCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    payload: this.props.payload,
  };

  contentTabId = tabId();
  mediaTabId = tabId();
  tabGroupId = tabId();
  editorId = tabId();

  eventToPayloadState = key => e => {
    const { value } = e.target;
    this.setPayloadState({ [key]: value });
  };

  valueToPayloadState = key => v => this.setPayloadState({ [key]: v });

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));

  save = () => this.props.save(this.state.payload);

  handleSelect = medium =>
    this.setPayloadState({
      _mediaId: medium.id,
      src: medium.urlMedium,
      url: medium.url,
    });

  render() {
    const { title, intro, content } = this.state.payload;

    return (
      <Fragment>
        <TestimonialCardView {...this.state.payload} />
        <Dialog title="Testimonial" icon="comment" isOpen onClose={this.save}>
          <div className="bp3-dialog-body">
            <Tabs id={this.tabGroupId}>
              <Tab
                id={this.contentTabId}
                title="Content"
                panel={
                  <Fragment>
                    <Input
                      name="title"
                      placeholder="Testimonial title"
                      onChange={this.eventToPayloadState('title')}
                      value={title}
                      className="bp3-fill"
                    >
                      Title <span className="bp3-text-muted">(optional)</span>
                    </Input>
                    <TextArea
                      name="intro"
                      placeholder="Testimonial intro"
                      onChange={this.eventToPayloadState('intro')}
                      value={intro}
                      className="bp3-fill"
                    >
                      Intro <span className="bp3-text-muted">(optional)</span>
                    </TextArea>
                    <label className="bp3-label" htmlFor={this.editorId}>
                      Content
                      <MobiledocEditor
                        mobiledoc={content}
                        onChange={this.valueToPayloadState('content')}
                        mini
                        id={this.editorId}
                      />
                    </label>
                  </Fragment>
                }
              />
              <Tab
                id={this.mediaTabId}
                title="Image"
                panel={<MediaPicker onSelect={this.handleSelect} />}
              />
            </Tabs>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default createCard({
  name: 'TestimonialCard',
  renderWith: withClickableHighlight(TestimonialCardView),
  editWith: withMedia(TestimonialCardEditor),
  buttonText: 'Testimonial',
  payload: {
    type: TYPE.EXTENDED,

    title: '',
    intro: '',
    content: '',

    _mediaId: '',
    src: 'http://via.placeholder.com/350x350.png',
    url: 'http://via.placeholder.com/350x350.png',
  },
});
