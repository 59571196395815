import React, { Component } from 'react';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { Query } from 'react-apollo';
import classNames from 'classnames';
import gql from 'graphql-tag';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import MailChimpMapping from './MailChimpMapping';

const QUERY = gql`
  {
    integrations {
      mailchimp {
        lists {
          id
          name
          fields {
            id
            tag
            name
          }
          interestCategories {
            id
            title

            interests {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const FIELD_SOURCES = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
];

const INTEREST_SOURCES = [{ label: 'Newsletter', value: 'newsletter' }];

const Section = styled.section`
  background-color: #ebf1f5;
  padding: 16px;
  &.hide {
    display: none;
  }
`;

export default class MailChimpSettings extends Component {
  static propTypes = {};
  handleListChange = v => {
    this.props.onChange({
      ...this.props.settings,
      listId: v.id,
    });
  };

  handleFieldMappingChange = field => ({ source, value }) => {
    this.props.onChange({
      ...this.props.settings,
      fieldMapping: [
        ...this.props.settings.fieldMapping.filter(m => m.field !== field),
        { field, source, value },
      ],
    });
  };

  handleInterestMappingChange = interest => ({ source, value }) => {
    this.props.onChange({
      ...this.props.settings,
      interestMapping: [
        ...this.props.settings.interestMapping.filter(m => m.interest !== interest),
        { interest, source, value },
      ],
    });
  };

  getActiveList = lists => {
    const { listId } = this.props.settings;
    const listSkeleton = {
      name: 'Select',
      fields: [],
      interestCategories: [],
    };
    return !lists || !lists.length
      ? listSkeleton
      : lists.find(l => l.id === listId) || listSkeleton;
  };

  hasCategories = list => !!list.interestCategories.length;
  hasFields = list => !!list.fields.length;

  render() {
    const { onChange, settings, ...props } = this.props; // eslint-disable-line no-unused-vars
    return (
      <Query query={QUERY} fetchPolicy="network-only">
        {({ data, loading, error }) => {
          const { mailchimp } = data?.integrations || {};
          const activeList = !loading ? this.getActiveList(mailchimp.lists) : {};

          return loading ? (
            <Loading text="fetching MailChimp info..." />
          ) : error ? (
            <ErrorCallout message="Something went wrong" />
          ) : (
            <div {...filterProps(props)}>
              <FormGroup
                helperText="submissions of petitions, events, et al. will push constituents to this list"
                label="List"
                labelFor="text-input"
              >
                <Select
                  items={mailchimp.lists}
                  itemPredicate={(query, item) =>
                    item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                  }
                  itemRenderer={(list, { handleClick, active }) => (
                    <MenuItem
                      key={`mc-list-item-${list.id}`}
                      text={list.name}
                      onClick={handleClick}
                      active={active}
                    />
                  )}
                  noResults={<MenuItem disabled text="No lists found." />}
                  onItemSelect={this.handleListChange}
                >
                  <Button text={activeList.name} rightIcon="caret-down" />
                </Select>
              </FormGroup>
              <div>
                <Section className={classNames({ hide: !this.hasFields(activeList) })}>
                  <h3>Fields</h3>
                  {[...activeList.fields]
                    .sort((a, b) => (a.id < b.id ? -1 : 1))
                    .map(f => (
                      <div key={`mc-field-item-${f.id}`}>
                        <MailChimpMapping
                          name={f.name}
                          {...settings.fieldMapping.find(m => m.field === f.tag)}
                          onChange={this.handleFieldMappingChange(f.tag)}
                          sourceFields={FIELD_SOURCES}
                        />
                      </div>
                    ))}
                </Section>
                <hr
                  style={{
                    display: !this.hasCategories(activeList) ? 'none' : '',
                  }}
                />
                <Section
                  className={classNames({
                    hide: !this.hasCategories(activeList),
                  })}
                >
                  <h3>Groups</h3>
                  {[...activeList.interestCategories].map((ic, i) => (
                    <div key={`interest-category-${ic.id}`}>
                      {i > 0 && <hr />}
                      <h4>{ic.title}</h4>
                      {ic.interests.map(interest => (
                        <div key={`mc-field-item-${interest.id}`}>
                          <MailChimpMapping
                            name={interest.name}
                            {...settings.interestMapping.find(m => m.interest === interest.id)}
                            onChange={this.handleInterestMappingChange(interest.id)}
                            sourceFields={INTEREST_SOURCES}
                            boolean
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </Section>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}
