import { createContext } from 'react';
import auth from '/services/auth';

export default createContext({
  login: () => auth.login(),
  logout: () => {
    auth.logout();
    auth.login();
  },
  getAccessToken: () => auth.getAccessToken(),
  getUser: () => auth.getUser(),
  loggedIn: () => auth.isAuthenticated(),
  handleAuthentication: () => auth.handleAuthentication(),
  consumePostLoginRedirectPath: auth.consumePostLoginRedirectPath,
  authHeaders: (...args) => auth.authHeaders(...args),
});
