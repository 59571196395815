import React from 'react';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import { Checkbox, Label } from '@blueprintjs/core';
// import PropTypes from 'prop-types';

const LOCATIONS = [
  { name: 'node.entry.article', label: 'Article' },
  { name: 'node.entry.page', label: 'Page' },
  { name: 'node.petition', label: 'Petition' },
  { name: 'node.event', label: 'Event' },
  { name: 'node.fundraiser', label: 'Fundraiser' },
  { name: 'node.signup', label: 'Signup' },
  { name: 'editor.datacard', label: 'Content (Data Card)' },
];

const Locations = ({ value: locations, onChange }) => {
  const isChecked = location => locations.indexOf(location) > -1;
  const withUpdatedLocation = (location, checked) =>
    locations.filter(l => l !== location).concat(checked ? [location] : []);
  return (
    <div>
      <Label>Locations</Label>
      {LOCATIONS.map(l => (
        <Checkbox
          key={btoa(l.name)}
          label={l.label}
          checked={isChecked(l.name)}
          onChange={e => onChange(withUpdatedLocation(l.name, e.target.checked))}
        />
      ))}
    </div>
  );
};

export default compose(setDisplayName('Locations'), setPropTypes({}))(Locations);
