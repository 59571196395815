import PropTypes from 'prop-types';

export default {
  title: PropTypes.node,
  intro: PropTypes.node,
  marker: PropTypes.node,
};

export const defaultProps = {
  title: '',
  intro: '',
  marker: '',
};
