import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Intent } from '@blueprintjs/core';
import ReactEmailEditor from '/lib/react-email-editor';
import { uploadMediaFile } from '/data/client';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #394b59;
  padding: 10px;
  height: 60px;
  button {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default class EmailEditor extends Component {
  save = () => {
    this.editor.exportHtml(({ design, html }) => this.props.onSave({ design, html }));
  };

  render() {
    const { mergeTags, design } = this.props;
    return (
      <Container>
        <Bar>
          <Button intent={Intent.PRIMARY} onClick={this.save}>
            Save
          </Button>
        </Bar>
        <ReactEmailEditor
          onLoad={editor => {
            if (design) editor.loadDesign(design);
            if (mergeTags) editor.setMergeTags(mergeTags);
            editor.registerCallback('image', async ({ attachments: [file] }, done) => {
              const mediaFile = await uploadMediaFile(file, ({ progress }) => done({ progress }));
              done({ progress: 100, url: mediaFile.url });
            });
          }}
          ref={editor => (this.editor = editor)}
          options={{}}
        />
      </Container>
    );
  }
}
