import React from 'react';
import { Button, Popover, Menu, MenuItem, MenuDivider, Position } from '@blueprintjs/core';
import copy from 'copy-to-clipboard';
import { useAuth } from '/hooks';

export default ({ match, history }) => {
  const { logout, getUser, getAccessToken } = useAuth();
  const user = getUser() || {};
  const isGlobalAdmin = !!user.roles?.global?.some(r => r === 'admin');

  const copyAccessToken = () => {
    const { token, expiresAt } = getAccessToken();
    if (!token) return;
    const expires = new Date(expiresAt);
    copy(token);
    // eslint-disable-next-line no-alert
    alert(`Copied your access token to the clipboard. It expires at:\n${expires.toString()}`);
  };

  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      content={
        <Menu>
          <MenuItem
            text="Playground"
            icon="build"
            onClick={() => history.push(`/${match.params.projectId}/playground`)}
          />
          {isGlobalAdmin && (
            <MenuItem text="Copy Access Token" icon="key" onClick={copyAccessToken} />
          )}

          <MenuDivider />
          <MenuItem text="Sign Out" icon="log-out" onClick={logout} />
        </Menu>
      }
    >
      <Button className="bp3-minimal" icon="menu" text={user.username} />
    </Popover>
  );
};
