import React from 'react';
import { Intent, AnchorButton } from '@blueprintjs/core';
import { WINGS_API_ENDPOINT } from '/config';
import { useAuth } from '../hooks';

const CampaignActionBar = ({ campaign = {} }) => {
  const auth = useAuth();
  const { nodeType, id } = campaign;
  const handleDownloadClick = () => {
    fetch(`${WINGS_API_ENDPOINT}/actions/download-submissions/${nodeType}/${id}`, {
      method: 'GET',
      headers: new Headers({
        ...auth.authHeaders(),
      }),
    }).then(async response => {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${campaign.slug}-submissions-${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 0' }}>
      <AnchorButton intent={Intent.PRIMARY} rightIcon="download" onClick={handleDownloadClick}>
        Download
      </AnchorButton>
    </div>
  );
};

export default CampaignActionBar;
