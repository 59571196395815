import React from 'react';
import { FormGroup, InputGroup, Tabs, Tab } from '@blueprintjs/core';
import styled from 'styled-components';

const GeneralSettings = styled.div`
  .bp3-label {
    width: 160px;
  }
`;
import EmailEditor from '/components/EmailEditor';

export default class EmailSettings extends React.Component {
  handleTemplateChange = name => ({ design, html }) => {
    const { settings, onChange } = this.props;
    onChange({
      ...settings,
      templates: [
        ...((settings && settings.templates) || []).filter(t => t.name !== name),
        {
          name,
          subject: this.getTemplate(name).subject,
          template: html,
          design: JSON.stringify(design),
        },
      ],
    });
  };
  handleTemplateSubjectChange = name => subject => {
    const { settings, onChange } = this.props;
    onChange({
      ...settings,
      templates: [
        ...((settings && settings.templates) || []).filter(t => t.name !== name),
        {
          ...(this.getTemplate(name) || { name }),
          subject,
        },
      ],
    });
  };
  getTemplate = name =>
    (this.props.settings &&
      this.props.settings &&
      this.props.settings.templates &&
      this.props.settings.templates.find(tpl => tpl.name === name)) ||
    null;
  render() {
    const { templates, settings, onChange } = this.props;

    return (
      <div>
        <GeneralSettings>
          <FormGroup
            labelFor="email-from-name-input"
            label="From Name"
            helperText="enter your sender name"
            inline
          >
            <InputGroup
              id="email-from-name-input"
              placeholder="Example Company"
              value={(settings && settings.emailFromName) || ''}
              onChange={e => onChange({ ...settings, emailFromName: e.target.value })}
            />
          </FormGroup>
        </GeneralSettings>
        <hr />
        <Tabs renderActiveTabPanelOnly>
          {templates.map(t => {
            const tpl = this.getTemplate(t.name);
            const design = tpl && tpl.design ? JSON.parse(tpl.design) : undefined;
            return (
              <Tab
                key={`${t.name}-key`}
                id={`${t.name}-tab`}
                title={t.title}
                panel={
                  <React.Fragment>
                    <FormGroup
                      labelFor={`email-template-${t.name}-from-name-input`}
                      label="Subject"
                      helperText="enter the email subject"
                      inline
                    >
                      <InputGroup
                        id={`email-template-${t.name}-from-name-input`}
                        value={tpl ? tpl.subject : ''}
                        onChange={e => this.handleTemplateSubjectChange(t.name)(e.target.value)}
                      />
                    </FormGroup>
                    <EmailEditor
                      design={design}
                      mergeTags={t.mergeTags}
                      onSave={this.handleTemplateChange(t.name)}
                    />
                  </React.Fragment>
                }
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}
