import { useState, useEffect, useContext } from 'react';
import AuthContext from '/ctx/Auth';

export const useAuth = () => useContext(AuthContext);

export const useDebouncedState = (init, debounce, cb) => {
  const [value, setValue] = useState(init);
  let timeout;

  useEffect(() => {
    if (cb) return cb(value);
  }, [value]);

  const setDebounced = (v, _debounce) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setValue(v);
    }, _debounce || debounce);
    return () => clearTimeout(timeout);
  };

  const clear = () => {
    clearTimeout(timeout);
  };

  return [value, setDebounced, clear];
};

export { default as usePaginatedQuery } from './pagination';

export { default as useProjects } from './useProjects';

export { default as useCopy } from './useCopy';
