import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog as _Dialog } from '@blueprintjs/core';
import { createCard } from '@wingsplatform/react';
import { withApolloProvider } from '../../../../data/provider';
import DialogStyle from '/styles/dialog';
import withClickableHighlight from '/styles/withClickableHighlight';
import CampaignCardView from './CampaignCardView';
import NodeSelect from '../../../../components/NodeSelect';

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

class CampaignCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  eventToPayloadState = key => e => {
    const { value } = e.target;
    this.setPayloadState({ [key]: value });
  };

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));

  save = () => this.props.save(this.state.payload);

  render() {
    return (
      <>
        <CampaignCardView {...this.state.payload} />
        <Dialog
          title="Campaign"
          icon="grid-view"
          isOpen
          onClose={this.save}
          canOutsideClickClose={false}
          enforceFocus={false}
        >
          <div className="bp3-dialog-body">
            <NodeSelect
              resourceTypes={['node.petition', 'node.event', 'node.fundraiser', 'node.signup']}
              onSelect={node =>
                this.setPayloadState({
                  id: node.id,
                  resourceType: node.resourceType,
                })
              }
              selected={this.state.payload.id}
            />
          </div>
        </Dialog>
      </>
    );
  }
}

export default createCard({
  name: 'CampaignCard',
  renderWith: withClickableHighlight(withApolloProvider(CampaignCardView)),
  editWith: withApolloProvider(CampaignCardEditor),
  buttonText: 'Campaign',
  payload: {},
});
