import React, { Component } from 'react';
import moment from 'moment';

export default class FromNow extends Component {
  static defaultProps = {
    date: null,
  };

  getText = () => (this.props.date ? moment(this.props.date).fromNow() : '');

  state = {
    text: this.getText(),
  };

  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          text: this.getText(),
        }),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <span>{this.state.text}</span>;
  }
}
