import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { createCard, ImageCard } from '@wingsplatform/react';
import { Popover, Position, Tab, Tabs as _Tabs } from '@blueprintjs/core';
import withClickableHighlight from '/styles/withClickableHighlight';
import MediaPicker from '/components/MediaPicker';
import withMedia from '/containers/withMedia';
import Select from '/components/Select';

const { SIZE, FLOAT } = ImageCard.View;

const SIZE_MAP = {
  [SIZE.MEDIUM]: 'urlMedium',
  [SIZE.LARGE]: 'urlLarge',
  [SIZE.EXTRALARGE]: 'urlExtraLarge',
};

const Tabs = styled(_Tabs)`
  padding: 20px;
`;

const ImageCardView = styled(ImageCard.View)`
  &.size-${ImageCard.View.SIZE.EXTRALARGE} {
    margin-left: -161px;
    margin-right: -161px;
  }
`;

class ImageCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    medium: null,
    payload: this.props.payload,
  };

  UNSAFE_componentWillMount() {
    const { loading } = this.props.data;

    if (!loading) {
      this.setState({
        medium: this.props.data.media.find(({ id }) => id === this.props.payload._mediaId),
      });
    }
  }

  setPayload = p => this.setState(({ payload }) => ({ payload: { ...payload, ...p } }));

  handleSelect = medium =>
    this.setState(({ payload }) => ({
      medium,
      payload: {
        ...payload,
        _mediaId: medium.id,
        src: medium[SIZE_MAP[this.state.payload.size]],
        url: medium.url,
        caption: medium.caption,
        alt: medium.alt,
      },
    }));

  handleFloatChange = e => this.setPayload({ float: parseInt(e.target.value, 10) });
  handleSizeChange = e => {
    const size = parseInt(e.target.value, 10);
    this.setPayload({
      size,
      src: this.state.medium[SIZE_MAP[size]],
    });
  };

  hasFloat = () => _.includes([SIZE.MEDIUM], (this.state.payload || {}).size);

  render() {
    const { save } = this.props;
    const { size, float } = this.state.payload || {};

    return (
      <Popover isOpen onInteraction={() => save(this.state.payload)} position={Position.BOTTOM}>
        <ImageCardView {...this.state.payload} />
        <Tabs id="article-options-tabs">
          <Tab
            id="article-options-tabs-picker"
            title="Selection"
            panel={<MediaPicker onSelect={this.handleSelect} />}
          />
          <Tab
            id="article-options-tabs-layout"
            title="Layout"
            panel={
              <Fragment>
                <Select
                  onChange={this.handleSizeChange}
                  labelText="Size"
                  items={[
                    { value: SIZE.MEDIUM, text: 'Medium' },
                    { value: SIZE.LARGE, text: 'Large' },
                    { value: SIZE.EXTRALARGE, text: 'Extralarge' },
                  ]}
                  value={size}
                />

                {!this.hasFloat() ? null : (
                  <Select
                    onChange={this.handleFloatChange}
                    labelText="Float"
                    items={[
                      { value: FLOAT.NONE, text: 'None' },
                      { value: FLOAT.LEFT, text: 'Left' },
                      { value: FLOAT.RIGHT, text: 'Right' },
                    ]}
                    value={float}
                  />
                )}
              </Fragment>
            }
          />
        </Tabs>
      </Popover>
    );
  }
}

export default createCard({
  name: ImageCard.name,
  renderWith: withClickableHighlight(ImageCardView),
  editWith: withMedia(ImageCardEditor),
  buttonText: 'Image',
  payload: {
    _mediaId: '',
    src: 'http://via.placeholder.com/350x150.png',
    url: 'http://via.placeholder.com/350x150.png',
    caption: 'I am a great caption',
    alt: 'Awesome placeholder',
    size: SIZE.MEDIUM,
    float: FLOAT.NONE,
  },
});
