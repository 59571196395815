import React, { useState } from 'react';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import filterProps from 'filter-invalid-dom-props';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Composer from '/lib/composer';
import styled from 'styled-components';
import { filter } from 'graphql-anywhere';
import { Tab, Tabs as _Tabs, Button, Intent, Dialog, Classes } from '@blueprintjs/core';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import ActionGroup from '/components/ActionGroup';

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  > .bp3-label {
    width: 160px;
  }
  .bp3-tab-list {
    min-width: 200px;
  }
  .w-tab {
    width: 100%;
    padding: 24px;
  }
`;

const Tabs = styled(_Tabs)`
  min-height: 656px;
`;

const CreateButton = styled(Button)`
  margin: 24px 0;
`;

const Panel = styled.div``;

const QueryDefinition = PropTypes.shape({
  query: PropTypes.object.isRequired,
  variables: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
});

const EMPTY_MUTATION_ARG = {
  query: gql`
    mutation {
      a
    }
  `,
};

const TabularResource = ({
  queries,
  resourceName,
  tabInfo = item => ({ title: item.id }),
  mutableFragment,
  actionButtons = () => {},
  children,
  ...props
}) => {
  const [cache, setCache] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const handleChange = id => (item, cb) => {
    setCache(cache => ({ ...cache, [id]: item }), cb);
  };

  return (
    <Composer
      {...filterProps(props)}
      components={[
        {
          component: Query,
          props: {
            query: queries.read.query,
            fetchPolicy: 'cache-and-network',
          },
        },
        {
          component: Mutation,
          props: {
            mutation: queries.create.query,
          },
        },
        {
          component: Mutation,
          props: {
            mutation: queries.update.query,
          },
        },
        {
          component: Mutation,
          props: {
            mutation: (queries.delete || EMPTY_MUTATION_ARG).query,
          },
        },
      ]}
    >
      {([
        [{ data: { items = [], ...data } = {}, loading, error, refetch }],
        [createResource, { loading: createLoading }],
        [updateResource, { loading: updateLoading }],
        [deleteResource, { loading: deleteLoading }],
      ]) => (
        <Wrapper>
          {loading && !items.length ? (
            <Loading fill />
          ) : error ? (
            <ErrorCallout message="Couldn't fetch resources." />
          ) : (
            <Tabs
              id="resource-tabs"
              renderActiveTabPanelOnly
              vertical
              style={{ minWidth: 400, minHeight: 656 }}
            >
              <CreateButton
                fill
                icon="add"
                text={`Add ${resourceName || ''}`}
                intent={Intent.PRIMARY}
                onClick={async () =>
                  createResource({
                    variables:
                      typeof queries.create.variables === 'function'
                        ? await queries.create.variables()
                        : queries.create.variables,
                  }).then(() => refetch())
                }
                loading={createLoading}
              />
              {items.map(item => (
                <Tab
                  key={`items-item-${item.id}`}
                  className="w-tab"
                  id={item.id}
                  title={tabInfo(item).title || item.id}
                  panel={
                    <Panel>
                      <Dialog
                        icon="warning-sign"
                        onClose={() => setShowDeleteDialog(false)}
                        title="Delete"
                        isOpen={showDeleteDialog}
                      >
                        <div className={Classes.DIALOG_BODY}>Are you sure?</div>
                        <div className={Classes.DIALOG_FOOTER}>
                          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
                            <Button
                              loading={deleteLoading}
                              intent={Intent.DANGER}
                              onClick={() => {
                                queries.delete &&
                                  deleteResource({
                                    variables: queries.delete.variables(
                                      item.id,
                                      filter(mutableFragment, item),
                                    ),
                                  }).then(() => {
                                    refetch();
                                    setShowDeleteDialog(false);
                                  });
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Dialog>
                      <ActionBar>
                        <ActionGroup
                          onSave={() =>
                            updateResource({
                              variables: queries.update.variables(
                                item.id,
                                filter(mutableFragment, cache[item.id]),
                              ),
                            }).then(() => handleChange(item.id)(null, () => refetch()))
                          }
                          saveDisabled={!cache[item.id]}
                          saving={updateLoading}
                          onDelete={queries.delete && (() => setShowDeleteDialog(true))}
                          deleting={deleteLoading}
                          buttons={actionButtons(item)}
                        />
                      </ActionBar>
                      {children(cache[item.id] || item, {
                        onChange: handleChange(item.id),
                        data,
                      })}
                    </Panel>
                  }
                />
              ))}
            </Tabs>
          )}
        </Wrapper>
      )}
    </Composer>
  );
};

export default compose(
  setDisplayName('TabularResource'),
  setPropTypes({
    queries: PropTypes.shape({
      read: QueryDefinition,
      create: QueryDefinition,
      update: QueryDefinition,
      delete: QueryDefinition,
    }),
    tabInfo: PropTypes.func,
    mutableFragment: PropTypes.object,
  }),
)(TabularResource);
