import React, { Component } from 'react';
import styled from 'styled-components';
import { modularScale } from 'polished';
import propTypes, { defaultProps } from './propTypes';

const Header = styled.div`
  background: #f5f8fa;
  padding: 1rem;
  text-align: center;
  * {
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: ${modularScale(5)};
  font-weight: 700;
  margin: 0.5rem 0;
`;

const Intro = styled.p`
  font-size: ${modularScale(1)};
  font-weight: 700;
`;

const Marker = styled.span`
  font-size: ${modularScale(1)};
  font-weight: 700;
`;

export default class SectionHeadingView extends Component {
  static setPropTypes = propTypes;
  static defaultProps = defaultProps;
  render() {
    const { title, intro, marker, options, ...props } = this.props;
    return (
      <Header {...props}>
        <Marker>{marker}</Marker>
        <Title>{title}</Title>
        <Intro>{intro}</Intro>
        {options}
      </Header>
    );
  }
}
