import styled from 'styled-components';

export default Comp => styled(Comp)`
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition: box-shadow linear 120ms;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  border-radius: 5px;
  overflow: hidden;
`;
