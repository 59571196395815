import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import './styles';
import App from './App'; // eslint-disable-line
import bootstrap from './bootstrap';

const renderApp = client => {
  const App = require('./App').default;
  render(<App client={client} />, document.getElementById('root'));
};

bootstrap().then(({ client }) => {
  renderApp(client);
  if (module.hot) module.hot.accept(() => renderApp(client));
});
