import React from 'react';
import { Link as _Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Button } from '@blueprintjs/core';
import withProjects from '/containers/withProjects';
import Container from './Container';
import ProjectMenuButton from './ProjectMenuButton';
import UserButton from './UserButton';

const MENU_ITEMS = [
  { url: '/content?type=article', text: 'Content', icon: 'annotation' },
  { url: '/campaigns', text: 'Campaigns', icon: 'trending-up' },
  { url: '/commerce', text: 'Commerce', icon: 'shopping-cart' },
  { url: '/files', text: 'Files', icon: 'folder-open' },
  { url: '/settings', text: 'Settings', icon: 'settings' },
];

const Link = styled(_Link)`
  color: white !important;
  :hover {
    text-decoration: none;
  }
`;

const isActive = (path, url) => (url === '/' ? path === url : path.startsWith(url.split('?')[0]));

export default compose(
  withRouter,
  withProjects,
)(({ location, projectUrl }) => (
  <nav className="bp3-navbar bp3-dark">
    <Container>
      <div className="bp3-navbar-group bp3-align-left">
        <div className="bp3-navbar-heading">Wings</div>
        {MENU_ITEMS.map(item => (
          <Link to={projectUrl(item.url)} key={`navmenui${item.text}`}>
            <Button
              className="bp3-minimal"
              icon={item.icon}
              text={item.text}
              active={isActive(location.pathname, projectUrl(item.url))}
            />
          </Link>
        ))}
      </div>
      <div className="bp3-navbar-group bp3-align-right">
        <ProjectMenuButton />
        <span className="bp3-navbar-divider" />
        <UserButton />
      </div>
    </Container>
  </nav>
));
