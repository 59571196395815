import React from 'react';

export default ({ onSave, onCancel }) => (
  <div>
    <button onClick={onSave} className="bp3-button bp3-small bp3-intent-primary">
      save
    </button>
    <button onClick={onCancel} className="bp3-button bp3-small bp3-minimal bp3-intent-warning">
      cancel
    </button>
  </div>
);
