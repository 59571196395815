import React, { Component } from 'react';
import _SchemaForm from 'react-jsonschema-form';
import styled from 'styled-components';
import ArrayFieldTemplate from './components/ArrayFieldTemplate';
import StringFieldTemplate from './components/StringFieldTemplate';

const SchemaForm = styled(_SchemaForm)`
  fieldset {
    border: 0;
  }
`;

export default class Form extends Component {
  // _onChangeHandlersCalled = 0;
  render() {
    const { ...props } = this.props;
    return (
      <SchemaForm
        showErrorList={false}
        {...props}
        ArrayFieldTemplate={ArrayFieldTemplate}
        fields={{ StringField: StringFieldTemplate }}
        transformErrors={errors =>
          errors.map(error => ({
            ...error,
            message: `${error?.params?.missingProperty || 'this field'} is required`,
          }))
        }
        onChange={(...args) =>
          // this._onChangeHandlersCalled++ &&
          props.onChange?.(...args)
        }
      >
        <input type="submit" style={{ display: 'none' }} />
      </SchemaForm>
    );
  }
}
