import styled from 'styled-components';

const ActionBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
ActionBar.Actions = styled.div``;

export default ActionBar;
