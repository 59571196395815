import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setStatic } from 'recompose';
import withRouter from '/containers/withRouter';
import ContentView from './ContentView';
import FromNow from './FromNow';

const DEFAULT_FIELDS = [
  { columnName: '' },
  {
    columnName: 'Title',
    render: node => <Link to={node.editUrl}>{node.title || '(no title)'}</Link>,
  },
  { columnName: 'Slug', render: node => node.slug },
  { columnName: '' },
  {
    columnName: 'Language',
    render: node => node.locale.name,
  },
  {
    columnName: 'Status',
    render: node =>
      node.status === 'PUBLISHED' ? (
        <>
          Published (<FromNow date={node.publishedAt} />)
        </>
      ) : (
        <>
          Draft (updated <FromNow date={node.updatedAt} />)
        </>
      ),
  },
];

export default compose(
  withRouter,
  setPropTypes({
    nodes: PropTypes.array.isRequired,
  }),
  setStatic('defaultProps', {
    nodes: [],
  }),
)(({ nodes = [], historyPush, fields: fieldsProp = v => v }) => {
  const fields = fieldsProp(DEFAULT_FIELDS);
  const fieldNames = fields.map(f => f.columnName);
  return (
    <ContentView fields={fieldNames} list>
      {nodes.map(node => (
        <ContentView.Item
          key={`node-list-item-${node.id}`}
          onClick={() => historyPush(node.editUrl)}
        >
          {fields.map(field => (
            <ContentView.Field>{(field?.render || (() => null))(node)}</ContentView.Field>
          ))}
        </ContentView.Item>
      ))}
    </ContentView>
  );
});
