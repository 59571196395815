import React from 'react';
import styled from 'styled-components';
import Currency from './Currency';
import Gateways from './Gateways';
import Tabs from '../../Tabs';

const CommerceSettings = styled.div`
  .bp3-tab-panel {
    width: 100%;
  }
`;

export default ({ settings, refetch }) => (
  <CommerceSettings>
    <Tabs titles={['Gateways', 'Currency']} vertical>
      <Gateways settings={settings} refetch={refetch} tabProps={{ style: { width: '100%' } }} />
      <Currency settings={settings} refetch={refetch} />
    </Tabs>
  </CommerceSettings>
);
