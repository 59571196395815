import React from 'react';
import styled from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { resourceTypeText } from '/constants';
import cardSVG from './nodes.svg';

const Container = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fcfcfc;
  &:hover {
  }
`;

const getTitle = ({ type, selector, items }) => {
  switch (type) {
    case 'archive':
      return [resourceTypeText(selector.resourceType.eq), 'Archive'].join(' ');
    case 'selection':
      return `Node Selection (${items.length} item${items.length === 1 ? '' : 's'})`;
    default:
      return 'Nodes';
  }
};

export default function NodesCardView(props) {
  return (
    <Container {...props}>
      <div className={Classes.TEXT_MUTED} style={{ textAlign: 'center' }}>
        <h3>{getTitle(props)}</h3>
        <img src={cardSVG} />
      </div>
    </Container>
  );
}
