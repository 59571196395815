import gql from 'graphql-tag';
import useData from './useData';

const messages = [
  {
    id: 'wings.404.message.text',
    description: '404 page message',
    locations: ['webapp'],
  },

  {
    id: 'wings.Campaign.description.collapse',
    description: 'Campaign proposition description collapse text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.Campaign.description.expand',
    description: 'Campaign proposition description expand text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },

  {
    id: 'wings.Campaign.petitionCounter.message',
    description: 'Petition counter message',
    locations: ['webapp', 'node.petition'],
  },

  {
    id: 'wings.Campaign.fundraiserCounter.message',
    description: 'Fundraiser counter message',
    locations: ['webapp', 'node.fundraiser'],
  },

  {
    id: 'wings.CampaignForm.confirm.title',
    description: 'Campaign form confirm title',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.confirm.text',
    description: 'Campaign form confirm text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.error.title',
    description: 'Campaign form error title',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.error.text',
    description: 'Campaign form error text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.error.buttonText',
    description: 'Campaign form button text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },

  {
    id: 'wings.CampaignConfirmed.main.signupTitle',
    description: 'Signup confirmation title',
    locations: ['webapp', 'node.signup'],
  },
  {
    id: 'wings.CampaignConfirmed.main.signupText',
    description: 'Signup confirmation text',
    locations: ['webapp', 'node.signup'],
  },
  {
    id: 'wings.CampaignConfirmed.main.signupShareTitle',
    description: 'Signup share title',
    locations: ['webapp', 'node.signup'],
  },

  {
    id: 'wings.CampaignConfirmed.main.petitionTitle',
    description: 'Petition confirmation title',
    locations: ['webapp', 'node.petition'],
  },
  {
    id: 'wings.CampaignConfirmed.main.petitionText',
    description: 'Petition confirmation text',
    locations: ['webapp', 'node.petition'],
  },
  {
    id: 'wings.CampaignConfirmed.main.petitionShareTitle',
    description: 'Petition share title',
    locations: ['webapp', 'node.petition'],
  },

  {
    id: 'wings.CampaignConfirmed.main.eventTitle',
    description: 'Event confirmation title',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.CampaignConfirmed.main.eventText',
    description: 'Event confirmation text',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.CampaignConfirmed.main.eventShareTitle',
    description: 'Event share title',
    locations: ['webapp', 'node.event'],
  },

  {
    id: 'wings.CampaignConfirmed.main.fundraiserDefaultTitle',
    description: 'Fundraiser confirmation title',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserDefaultText',
    description: 'Fundraiser confirmation default text',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserSuccessTitle',
    description: 'Fundraiser confirmation success title',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserSuccessText',
    description: 'Fundraiser confirmation success text',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserPendingTitle',
    description: 'Fundraiser confirmation pending title',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserPendingText',
    description: 'Fundraiser confirmation pending text',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserFailedTitle',
    description: 'Fundraiser confirmation failed title',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserFailedText',
    description: 'Fundraiser confirmation failed text',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignConfirmed.main.fundraiserShareTitle',
    description: 'Fundraiser confirmation share title',
    locations: ['webapp', 'node.fundraiser'],
  },

  {
    id: 'wings.CampaignForm.emailField.label',
    description: 'Campaign form email label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.firstNameField.label',
    description: 'Campaign form first name label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.lastNameField.label',
    description: 'Campaign form last name label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.addressField.label',
    description: 'Campaign form address label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.zipcodeField.label',
    description: 'Campaign form postcode label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.cityField.label',
    description: 'Campaign form city label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.countryField.label',
    description: 'Campaign form country label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.phoneField.label',
    description: 'Campaign form phone label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.newsletterField.label',
    description: 'Campaign form newsletter label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.privacyConsentField.label',
    description: 'Campaign form privacy consent label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.termsField.label',
    description: 'Campaign form terms label',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.amountField.label',
    description: 'Fundraiser form amount label',
    locations: ['webapp', 'node.fundraiser'],
  },
  {
    id: 'wings.CampaignForm.loading.text',
    description: 'Campaign form loading text',
    locations: ['webapp', 'node.event', 'node.fundraiser', 'node.petition', 'node.signup'],
  },

  {
    id: 'wings.CampaignForm.signupSubmit.text',
    description: 'Signup form submit text',
    locations: ['webapp', 'node.signup'],
  },
  {
    id: 'wings.CampaignForm.eventSubmit.text',
    description: 'Event form submit text',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.CampaignForm.petitionSubmit.text',
    description: 'Petition form submit text',
    locations: ['webapp', 'node.petition'],
  },
  {
    id: 'wings.CampaignForm.fundraiserSubmit.text',
    description: 'Fundraiser form submit text',
    locations: ['webapp', 'node.fundraiser'],
  },

  {
    id: 'wings.Campaign.eventInfo.title',
    description: 'Event info title',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.Campaign.eventStart.label',
    description: 'Event info time start label',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.Campaign.eventEnd.label',
    description: 'Event info time end label',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.Campaign.eventFee.label',
    description: 'Event info fee label',
    locations: ['webapp', 'node.event'],
  },
  {
    id: 'wings.Campaign.eventLocation.label',
    description: 'Event info location label',
    locations: ['webapp', 'node.event'],
  },

  {
    id: 'wings.Article.chapters.title',
    description: 'Article chapter toggle title',
    locations: ['webapp', 'node.entry.article'],
  },
  {
    id: 'wings.Article.skipToContent.tooltip',
    description: 'Article skip to content tooltip',
    locations: ['webapp', 'node.entry.article'],
  },

  { id: 'wings.Footer.madeBy.emoji', description: "Footer 'made by' emoji", locations: [] },
  {
    id: 'wings.Footer.madeBy.message',
    description: "Footer 'made by' message",
    locations: [],
  },
  {
    id: 'wings.Footer.poweredBy.message',
    description: "Footer 'powered by' message",
    locations: [],
  },
];

const DEFAULT_COPY_QUERY = gql`
  query DefaultCopy {
    defaultAppCopy {
      message {
        messageId
        description
        message
      }
      locale {
        id
        name
        primary
      }
    }
  }
`;

export default function useCopy(resourceType) {
  const { data } = useData(DEFAULT_COPY_QUERY);
  return {
    messages: !resourceType ? [] : messages.filter(m => m.locations.includes(resourceType)),
    defaultAppCopy: data?.defaultAppCopy || [],
  };
}
