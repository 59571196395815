import React from 'react';
import styled from 'styled-components';
import { Button, Intent, Icon, ControlGroup } from '@blueprintjs/core';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { default as _NodeSelect } from '../../../../components/NodeSelect';

const NodeItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  z-index: 21;
`;

const NodeSelect = styled(_NodeSelect)`
  width: 300px;
`;

const DragHandle = SortableHandle(() => (
  <Icon
    style={{ width: '20px', cursor: 'grab' }}
    icon="drag-handle-vertical"
    intent={Intent.PRIMARY}
  />
));

const SortableItem = SortableElement(({ deleteItem, onLoad, onSelect, items, i }) => (
  <NodeItem>
    <DragHandle />
    <ControlGroup fill={true} vertical={false}>
      <NodeSelect
        onLoad={onLoad}
        selected={items[i].nodeId}
        onSelect={node => onSelect(node, i, items)}
      />
      <Button icon="delete" intent={Intent.DANGER} onClick={() => deleteItem(i, items)} />
    </ControlGroup>
  </NodeItem>
));

export default SortableContainer(({ items, onLoad, onSelect, deleteItem }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem
        items={items}
        key={`item-${index}`}
        index={index}
        i={index}
        onLoad={onLoad}
        onSelect={onSelect}
        deleteItem={deleteItem}
      />
    ))}
  </div>
));
