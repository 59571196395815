import PropTypes from 'prop-types';
import React from 'react';
import wizard from '/lib/wizard';

async function toggleLink(editor) {
  if (editor.range.isCollapsed) {
    return;
  }

  const selectedText = editor.cursor.selectedText();
  let defaultUrl = '';
  if (selectedText.indexOf('http') !== -1) {
    defaultUrl = selectedText;
  }
  const { range } = editor;
  const hasLink = editor.detectMarkupInRange(range, 'a');

  if (hasLink) {
    editor.toggleMarkup('a');
  } else {
    const [{ url, external }] = await wizard.init({
      title: 'Add Link',
      steps: [
        {
          title: 'Link',
          schema: {
            type: 'object',
            required: ['url'],
            properties: {
              url: { title: 'URL', type: 'string' },
              external: {
                title: 'Open in a new browser window',
                type: 'boolean',
              },
            },
          },
          uiSchema: {
            url: {
              'ui:autofocus': true,
            },
          },
        },
      ],
      value: [
        {
          url: defaultUrl,
        },
      ],
    });

    if (!url) {
      return;
    }

    editor.selectRange(range);
    const attrs = { href: url, target: external ? '_blank' : '' };
    editor.run(postEditor => {
      const markup = postEditor.builder.createMarkup('a', attrs);
      markup.attributes = attrs;
      postEditor.toggleMarkup(markup);
    });
  }
}

const LinkButton = (
  { children = 'Link', type = 'button', handler, className, activeClassName = 'active', ...props },
  { editor, activeMarkupTags = [] },
) => {
  const onClick = () => {
    if (!editor.hasCursor()) {
      return;
    }

    if (editor.hasActiveMarkup('a')) {
      editor.toggleMarkup('a');
    } else {
      toggleLink(editor, handler);
    }
  };

  className = [className, activeMarkupTags.indexOf('a') > -1 && activeClassName]
    .filter(Boolean)
    .join(' ');

  props = { type, ...props, onClick, className };
  return <button {...props}>{children}</button>;
};

LinkButton.propTypes = {
  children: PropTypes.node,
};

LinkButton.contextTypes = {
  editor: PropTypes.object,
  activeMarkupTags: PropTypes.array,
};

export default LinkButton;
