import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Debug from '/components/Debug';
import _Form from '/components/Form';
import { randomId } from '/lib/utils';

const Form = styled(_Form)`
  .form-group.field-object {
    .form-group.field-object {
      background-color: #fafafa;
    }
  }
`;

const FIELDS_SCHEMA = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      label: { title: 'Field Label', type: 'string' },
      type: {
        title: 'Field Type',
        type: 'string',
        oneOf: [
          { const: 'text', title: 'Text Field' },
          { const: 'number', title: 'Number Field' },
          // { const: 'select', title: 'Select Field' },
          { const: 'checkbox', title: 'Checkbox Field' },
        ],
        default: 'text',
      },
    },
    // dependencies: {
    //   type: {
    //     oneOf: [
    //       {
    //         properties: {
    //           type: {
    //             enum: ['text', 'checkbox', 'number'],
    //           },
    //         },
    //       },
    //       {
    //         properties: {
    //           type: {
    //             enum: ['select'],
    //           },
    //           selectOptions: {
    //             type: 'object',
    //             properties: {
    //               items: {
    //                 title: 'Select Options',
    //                 type: 'array',
    //                 items: {
    //                   type: 'object',
    //                   properties: {
    //                     text: { title: 'Text', type: 'string' },
    //                   },
    //                 },
    //               },
    //             },
    //           },
    //         },
    //       },
    //     ],
    //   },
    // },
  },
};

export default class SubmissionFields extends React.Component {
  static propTypes = {
    settings: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  };
  static defaultProps = {
    settings: [],
  };

  render() {
    const { onChange, settings } = this.props;

    return (
      <>
        <div style={{ width: 600 }}>
          <h2>Custom Fields</h2>
          <Form
            schema={FIELDS_SCHEMA}
            formData={settings}
            onChange={({ formData }) =>
              onChange(formData.map(f => ({ ...f, id: f.id || randomId() })))
            }
          />
          <Debug data={settings} />
        </div>
      </>
    );
  }
}
