import React, { useState } from 'react';
import { ControlGroup, InputGroup, Button, Intent } from '@blueprintjs/core';

export default function InheritedInput({ value, placeholder, onChange, id, ...props }) {
  const [editMode, setEditMode] = useState(!!value);
  return (
    <ControlGroup {...props}>
      <InputGroup
        id={id}
        value={editMode ? value : placeholder}
        disabled={!editMode}
        onChange={e => onChange(e.target.value)}
        onBlur={() => !value && setEditMode(false)}
      />
      {!editMode ? (
        <Button
          icon="edit"
          onClick={() => {
            onChange(placeholder);
            setEditMode(true);
          }}
        />
      ) : (
        <Button
          icon="trash"
          intent={Intent.DANGER}
          onClick={() => {
            onChange('');
            setEditMode(false);
          }}
        />
      )}
    </ControlGroup>
  );
}
