import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditableText } from '@blueprintjs/core';
import { createCard } from '@wingsplatform/react';
import InsightCardView from './InsightCardView';
import Actions from './Actions';
import withClickableHighlight from '/styles/withClickableHighlight';

class InsightCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = { ...this.props.payload };

  render() {
    const { payload, save } = this.props;
    const { text } = this.state;
    return (
      <div>
        <InsightCardView
          {...this.state}
          text={
            <EditableText
              onChange={v => this.setState({ text: v })}
              placeholder="Edit insight..."
              selectAllOnFocus
              defaultValue={text}
              multiline
              minLines={1}
              isEditing
            />
          }
        />
        <Actions onCancel={() => save(payload)} onSave={() => save(this.state)} />
      </div>
    );
  }
}

export default createCard({
  name: 'InsightCard',
  renderWith: withClickableHighlight(InsightCardView),
  editWith: InsightCardEditor,
  buttonText: 'Insight',
  payload: {
    text: 'Your insight here.',
  },
});
