import React from 'react';
import { Query } from 'react-apollo';
import { withApolloProvider } from '/data/provider';
import { SCHEMAS_QUERY } from './DataCard';

export const withSchemas = Component =>
  withApolloProvider(props => (
    <Query query={SCHEMAS_QUERY}>
      {({ data, loading }) => {
        const getSchemaForKey = key => {
          if (loading) return null;
          return data.schemas.find(({ key: k }) => k === key);
        };
        return (
          <Component
            {...props}
            schemas={data?.schemas || []}
            loading={loading}
            getSchemaForKey={getSchemaForKey}
          />
        );
      }}
    </Query>
  ));
