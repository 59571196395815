import React from 'react';
import { compose } from 'recompose';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withApolloProvider } from '/data/provider';
import withProjects from './withProjects';

const QUERY = gql`
  query Files {
    files(first: 0) {
      edges {
        node {
          id
          name
          caption
          alt
          key
          license
          updatedAt
          createdAt
          url
          ... on ImageFile {
            url192: transform(width: 192, height: 192)
            urlMedium: transform(width: 720)
            urlLarge: transform(width: 1200)
            urlExtraLarge: transform(width: 1600)
          }
        }
      }
    }
  }
`;

const withMedia = Comp => props => (
  <Query query={QUERY} fetchPolicy="cache-and-network">
    {({ data, ...rest }) => (
      <Comp
        {...props}
        data={{
          media: data?.files?.edges?.map(e => e.node) || [],
          ...rest,
        }}
      />
    )}
  </Query>
);

export default compose(withApolloProvider, withProjects, withMedia);
