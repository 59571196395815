import React from 'react';
import { Tabs, Tab, FormGroup, InputGroup, NumericInput, Checkbox } from '@blueprintjs/core';
import NodeEditor from './NodeEditor';
import Card from './Card';
import MobiledocEditor from './MobiledocEditor';
import CampaignSettings from './CampaignSettings';
import SubmissionFields from './SubmissionFields';
import CampaignActionBar from './CampaignActionBar';

export default ({ petition, ...props }) => (
  <div>
    <NodeEditor node={petition} {...props}>
      {({ node, updateField }) => (
        <Card style={{ marginTop: 12 }}>
          <Tabs id="petition-tabs" renderActiveTabPanelOnly>
            <Tab
              id="petition-info-tab"
              title="Info"
              panel={
                <>
                  <FormGroup label="Introduction" labelFor="petition-info-intro">
                    <InputGroup
                      id="petition-info-intro"
                      value={node.intro || ''}
                      onChange={e => updateField('intro')(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup label="Description">
                    <MobiledocEditor
                      mobiledoc={node.description}
                      onChange={updateField('description')}
                      autoFocus
                      mini
                    />
                  </FormGroup>
                </>
              }
            />
            <Tab
              id="petition-fields-tab"
              title="Fields"
              panel={
                <SubmissionFields
                  settings={node.submissionFields}
                  onChange={updateField('submissionFields')}
                />
              }
            />
            <Tab
              id="petition-signatures-tab"
              title="Signatures"
              panel={
                <>
                  <CampaignActionBar campaign={node} />
                  {/* {!(signatures && signatures.length) ? (
                    <Callout intent={Intent.PRIMARY}>
                      This petition has no signatures.
                    </Callout>
                  ) : (
                    <SubmissionList submissions={signatures} />
                  )} */}
                </>
              }
            />
            <Tab
              id="petition-settings-tab"
              title="Settings"
              panel={
                <CampaignSettings settings={node.settings} onChange={updateField('settings')}>
                  <Tab
                    id="petition-settings-tab"
                    title="Petition Options"
                    style={{ width: '100%' }}
                    panel={
                      <div>
                        <Checkbox
                          checked={node?.options?.disableSignatureCount}
                          label="Disable Signature Count"
                          onChange={() => {
                            updateField('options')({
                              ...node.options,
                              disableSignatureCount: !node?.options?.disableSignatureCount,
                            });
                          }}
                        />
                        <hr />
                        <FormGroup
                          helperText="Add a custom offset value to the signatures counter"
                          label="Signature Offset"
                        >
                          <NumericInput
                            value={node?.options?.signatureOffset}
                            min={0}
                            onValueChange={v =>
                              updateField('options')({
                                ...node.options,
                                signatureOffset: Number(v),
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                    }
                  />
                </CampaignSettings>
              }
            />
          </Tabs>
        </Card>
      )}
    </NodeEditor>
  </div>
);
