import React, { useState } from 'react';
import { InputGroup, FormGroup, Callout, Intent } from '@blueprintjs/core';
import TranslationOverridesEditor from './TranslationOverridesEditor';
import Debug from '/components/Debug';

export default function TranslationEditor({ value: translation, onChange, locales }) {
  return (
    <>
      <FormGroup labelFor="name-input" label="Name">
        <InputGroup
          id="name-input"
          placeholder="My Translation"
          value={translation.name}
          onChange={e =>
            onChange({
              ...translation,
              name: e.target.value,
            })
          }
        />
        <TranslationOverridesEditor
          resourceType="webapp"
          onChange={currentLocale => overrides =>
            onChange({
              ...translation,
              overrides: [
                ...translation.overrides.filter(o => o.localeId !== currentLocale),
                ...overrides,
              ],
            })}
          value={translation}
          locales={locales}
        />
      </FormGroup>
      <Debug data={translation} />
    </>
  );
}
