import React from 'react';

export default [
  {
    id: 'mediumSpacing',
    title: 'Medium Spacing',
    description: () => (
      <>Medium sized spacing. By default all other spacing sizes are based on this.</>
    ),
    type: 'string',
    group: 'Spacing',
    parent: null,
    defaultValue: '40px',
  },
  {
    id: 'largeSpacing',
    title: 'Large Spacing',
    description: () => <>Large spacing. Default is medium spacing * 2.</>,
    type: 'string',
    group: 'Spacing',
    parent: null,
    defaultValue: '80px',
  },
  {
    id: 'smallSpacing',
    title: 'Small Spacing',
    description: () => <>Small spacing. Default is medium spacing / 2.</>,
    type: 'string',
    group: 'Spacing',
    parent: null,
    defaultValue: '20px',
  },
  {
    id: 'extraLargeSpacing',
    title: 'Extra Large Spacing',
    description: () => <>Extra large spacing. Default is medium spacing * 4.</>,
    type: 'string',
    group: 'Spacing',
    parent: null,
    defaultValue: '160px',
  },
  {
    id: 'extraSmallSpacing',
    title: 'Extra Small Spacing',
    description: () => <>Extra large spacing. Default is medium spacing / 4.</>,
    type: 'string',
    group: 'Spacing',
    parent: null,
    defaultValue: '10px',
  },
];
