import gql from 'graphql-tag';
import React from 'react';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import Gateway from './Gateway';

const schema = {
  type: 'object',
  required: ['secret'],
  properties: {
    apiKey: { title: 'API Key', type: 'string' },
  },
};

const SET_PAYMENT_GATEWAY_MOLLIE_MUTATION = gql`
  mutation SetMolliePaymentGateway($input: SetMolliePaymentGatewayInput) {
    setMolliePaymentGateway(input: $input) {
      mollie {
        apiKey
      }
    }
  }
`;

export default ({
  settings: { paymentGateways: { mollie: { apiKey = '' } = {} } = {} } = {},
  settings,
  ...props
}) => (
  <Gateway
    {...props}
    name="Mollie"
    id="mollie"
    mutation={SET_PAYMENT_GATEWAY_MOLLIE_MUTATION}
    schema={schema}
    settings={settings}
  >
    <FormGroup label="API Key" labelFor="mollie-api-key">
      <InputGroup id="mollie-api-key" value={apiKey} disabled style={{ marginBottom: 20 }} />
    </FormGroup>
  </Gateway>
);
