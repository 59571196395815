import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from './history';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  combineReducers({
    router: connectRouter(history),
    app: (state = {}, action) => {
      switch (action.type) {
        default:
          return state;
      }
    },
  }),
  composeEnhancers(applyMiddleware(routerMiddleware(history))),
);
