import React from 'react';
import Composer from 'react-composer';

export default ({ components, ...props }) => (
  <Composer
    components={components.map(({ component: Comp, props }) => ({ render }) => (
      <Comp {...props}>{(...args) => render([...args])}</Comp>
    ))}
    {...props}
  />
);
