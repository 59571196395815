import React from 'react';
import { Tag, ControlGroup, NumericInput, Intent } from '@blueprintjs/core';
import { randomId } from '/lib/utils';

export default ({ symbol, value, onChange, children, labelText }) => {
  const id = `input-${randomId()}`;
  return (
    <label htmlFor={id}>
      {children || labelText}
      <ControlGroup>
        {symbol ? <Tag intent={Intent.PRIMARY}>{symbol}</Tag> : null}
        <NumericInput
          id={id}
          allowNumericCharactersOnly
          min={0}
          onValueChange={v => onChange(v * 100)}
          value={value / 100}
        />
      </ControlGroup>
    </label>
  );
};
