/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Popover, Position } from '@blueprintjs/core';
import MEDIUM_PLACEHOLDER from '/assets/img/medium_placeholder.png';
import withMedia from '/containers/withMedia';
import MediaPicker from './MediaPicker';
import withClickableHighlight from '/styles/withClickableHighlight';

const Image = withClickableHighlight(styled.img`
  width: 96px;
  height: 96px;
`);

class MediaInput extends Component {
  static defaultProps = {
    mediaId: '',
  };
  state = {
    isPicking: false,
  };
  toggle = () => this.setState(({ isPicking }) => ({ isPicking: !isPicking }));
  findMedium = mediumId =>
    (this.props.data &&
      this.props.data.media &&
      this.props.data.media.find(({ id }) => id === mediumId)) ||
    {};
  render() {
    const { mediaId } = this.props;
    const medium = this.findMedium(mediaId);
    const { isPicking } = this.state;
    return (
      <Popover isOpen={isPicking} position={Position.TOP}>
        <div onClick={this.toggle}>
          <Image src={medium.id ? medium.url : MEDIUM_PLACEHOLDER} />
        </div>
        <MediaPicker
          onSelect={v => this.setState({ isPicking: false }, () => this.props.onChange(v))}
        />
      </Popover>
    );
  }
}

export default compose(withMedia)(MediaInput);
