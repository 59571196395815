import React, { Component } from 'react';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import PropTypes from 'prop-types';
import { Button, Intent, ControlGroup, InputGroup } from '@blueprintjs/core';
import SettingsCard from './SettingsCard';

const DeleteButton = styled(Button)`
  flex-grow: 0 !important;
  width: 48px;
`;

const AddButton = styled(Button)`
  margin: 12px 0;
`;

class MetaSettings extends Component {
  removeAtIndex = i => () => {
    const { meta } = this.props;
    this.props.onChange(meta.slice(0, i).concat(meta.slice(i + 1)));
  };
  handleAdd = () => this.props.onChange((this.props.meta || []).concat([{ key: '', value: '' }]));
  handleChange = i => v =>
    this.props.onChange(
      this.props.meta
        .slice(0, i)
        .concat([v])
        .concat(this.props.meta.slice(i + 1)),
    );
  render() {
    const {
      meta = [],
      onChange, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;

    return (
      <SettingsCard {...filterProps(props)}>
        {meta.map(({ key, value }, i) => (
          <ControlGroup fill={true} vertical={false} key={`meta-cg-${i}`}>
            <InputGroup
              value={key}
              onChange={e => this.handleChange(i)({ key: e.target.value, value })}
            />
            <InputGroup
              value={value}
              onChange={e => this.handleChange(i)({ key, value: e.target.value })}
            />
            <DeleteButton icon="delete" intent={Intent.DANGER} onClick={this.removeAtIndex(i)} />
          </ControlGroup>
        ))}
        <AddButton onClick={this.handleAdd} icon="add" intent={Intent.PRIMARY} text="Add" />
      </SettingsCard>
    );
  }
}

export default compose(
  setDisplayName('MetaSettings'),
  setPropTypes({
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }),
)(MetaSettings);
