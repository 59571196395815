import React from 'react';
import { compose, setDisplayName } from 'recompose';
import styled from 'styled-components';
import { InputGroup as _InputGroup, TextArea, Intent, HTMLSelect } from '@blueprintjs/core';
import MobiledocEditor from '../../MobiledocEditor';

const InputGroup = styled(_InputGroup)`
  margin: 16px 0;
`;

const StringFieldTemplate = ({ formData, onChange, schema, idSchema, rawErrors, autofocus }) => {
  if (schema.oneOf && schema.oneOf.length) {
    return (
      <div style={{ margin: '16px 0' }}>
        <HTMLSelect
          style={{ display: 'block' }}
          options={schema.oneOf.map(o => ({ value: o.const, label: o.title }))}
          onChange={e => onChange(e.currentTarget.value)}
          value={formData}
        />
      </div>
    );
  }
  return schema.format === 'textarea' ? (
    <TextArea
      id={idSchema.$id}
      fill
      value={formData || ''}
      onChange={e => onChange(e.target.value || undefined)}
    />
  ) : schema.format === 'mobiledoc' ? (
    <MobiledocEditor
      id={idSchema.$id}
      mobiledoc={formData || ''}
      style={{ marginBottom: 16 }}
      onChange={v => onChange((v !== MobiledocEditor.EMPTY_MOBILEDOC && v) || undefined)}
      mini
    />
  ) : (
    <InputGroup
      id={idSchema.$id}
      value={formData || ''}
      onChange={e => onChange(e.target.value || undefined)}
      intent={rawErrors?.length ? Intent.DANGER : Intent.NONE}
      autoFocus={autofocus}
    />
  );
};

export default compose(setDisplayName('StringFieldTemplate'))(StringFieldTemplate);
