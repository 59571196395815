import React from 'react';
import ThemeSettingsGroup from './ThemeSettingsGroup';
import ThemeSettingInput from './ThemeSettingInput';
import Theme from './Theme';

export default function ThemeProperties({ value, onChange }) {
  const theme = new Theme(value);
  return (
    <div>
      {theme.groups.map(group => {
        return (
          <ThemeSettingsGroup key={group} name={group}>
            {theme.getGroupSettings(group).map(setting => (
              <ThemeSettingInput
                key={setting.id}
                id={setting.id}
                type={setting.type}
                description={setting.description}
                title={setting.title}
                value={theme.getValue(setting.id)}
                inheritedValue={theme.getInheritedValue(setting.id)}
                inheritedSettings={theme.getInheritedSettings(setting.id)}
                onChange={val => {
                  theme.setValue(setting.id, val);
                  onChange(theme.toArray());
                }}
                options={setting.options}
              />
            ))}
          </ThemeSettingsGroup>
        );
      })}
    </div>
  );
}
