import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { ProjectFields, ProjectMutable } from '/data/fragments';
import ProjectSettings from '/components/ProjectSettings';
import View from '/components/View';

const QUERY = gql`
  ${ProjectFields}
  query Project {
    currentProject {
      ...ProjectFields
    }
  }
`;

const MUTATION = gql`
  ${ProjectFields}
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        ...ProjectFields
      }
    }
  }
`;

export const prepareProjectSettingsMutation = settings => ({
  ...settings,
  i18n: {
    ...settings.i18n,
    locales: settings.i18n.locales.map(({ locale, ...rest }) => ({
      ...rest,
      localeId: locale.id,
    })),
  },
});

export default class Settings extends Component {
  render() {
    return (
      <View query={QUERY} breadcrumbs={[{ text: 'Settings' }]}>
        {({ data, refetch }) => (
          <Mutation mutation={MUTATION}>
            {mutate => (
              <ProjectSettings
                availableEmailTemplates={data?.currentProject?.settings?.emails?.availableTemplates}
                availableLocales={data?.currentProject?.settings?.i18n?.availableLocales}
                refetch={refetch}
                settings={data?.currentProject?.settings}
                onSave={settings =>
                  mutate({
                    variables: {
                      input: {
                        project: filter(ProjectMutable, {
                          settings: prepareProjectSettingsMutation(settings),
                        }),
                      },
                    },
                  })
                }
              />
            )}
          </Mutation>
        )}
      </View>
    );
  }
}
