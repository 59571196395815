import React from 'react';
import { Provider, connect } from 'react-redux';
import { push } from 'connected-react-router';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, mapProps } from 'recompose';
import store from '/data/store';

const Q = gql`
  query Projects {
    projects(first: 0) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const withProjects = graphql(Q);

const projectIdFromPath = path => {
  const [, projectId] = path.split('/');
  return projectId;
};

const withStore = Comp => props => (
  <Provider store={store}>
    <Comp {...props} />
  </Provider>
);

const withState = connect(
  (
    {
      router: {
        location: { pathname },
      },
    },
    { data: { projects: { edges = [] } = {} } = {} },
  ) => {
    const projects = edges.map(({ node }) => node);
    return {
      activeProject: projects.find(p => p.id === projectIdFromPath(pathname)) || {},
      activeProjectId: projectIdFromPath(pathname),
      projects,
    };
  },
  (dispatch, { client }) => ({
    setProject: projectId => {
      client.resetStore();
      dispatch(push(`/${projectId}`));
    },
  }),
);

export default compose(
  withStore,
  withApollo,
  withProjects,
  withState,
  mapProps(({ data: { projects: { edges = [] } = {}, ...data } = {}, ...props }) => ({
    ...props,
    data,
    projects: edges.map(({ node }) => node),
    projectUrl: url => `/${props.activeProjectId}${url}`,
  })),
);
