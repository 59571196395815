import React from 'react';
import styled from 'styled-components';
import { Card, Tabs, Tab, FormGroup, TextArea as _TextArea } from '@blueprintjs/core';
import NodeEditor from './NodeEditor';
import MobiledocEditor from './MobiledocEditor';
import CampaignActionBar from './CampaignActionBar';
import CampaignSettings from './CampaignSettings';
import SubmissionFields from './SubmissionFields';

const TextArea = styled(_TextArea)`
  min-height: 80px;
`;

export default ({ signup, ...props }) => (
  <div>
    <NodeEditor node={signup} {...props}>
      {({ node, updateField }) => (
        <Card>
          <Tabs renderActiveTabPanelOnly>
            <Tab
              id="signup-info-tab"
              title="Info"
              panel={
                <React.Fragment>
                  <FormGroup label="Introduction" labelFor="intro-input">
                    <TextArea
                      id="intro-input"
                      className="bp3-fill"
                      large
                      onChange={e => updateField('intro')(e.target.value)}
                      value={node.intro || ''}
                    />
                  </FormGroup>
                  <FormGroup label="Description" labelFor="description-input">
                    <MobiledocEditor
                      id="description-input"
                      mini
                      mobiledoc={node.description}
                      onChange={updateField('description')}
                    />
                  </FormGroup>
                </React.Fragment>
              }
            />
            <Tab
              id="signup-submissions-tab"
              title={`Submissions`}
              panel={<CampaignActionBar campaign={node} />}
            />
            <Tab
              id="signup-fields-tab"
              title="Fields"
              panel={
                <SubmissionFields
                  settings={node.submissionFields}
                  onChange={updateField('submissionFields')}
                />
              }
            />
            <Tab
              id="signup-settings-tab"
              title="Settings"
              panel={
                <CampaignSettings settings={node.settings} onChange={updateField('settings')} />
              }
            />
          </Tabs>
        </Card>
      )}
    </NodeEditor>
  </div>
);
