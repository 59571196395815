import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createCard } from '@wingsplatform/react';
import { randomId } from '/lib/utils';
import { Dialog as _Dialog, Tabs, Tab } from '@blueprintjs/core';
import withClickableHighlight from '/styles/withClickableHighlight';
import MediaPicker from '/components/MediaPicker';
import withMedia from '/containers/withMedia';
import QACardView from './QACardView';
import Input from '/components/Input';
import ColorInput from '/components/ColorInput';

import QAList from './QAList';
import DialogStyle from '/styles/dialog';

const tabId = () => `tab-${randomId()}`;

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

class QACardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    payload: this.props.payload,
  };

  contentTabId = tabId();
  mediaTabId = tabId();
  tabGroupId = tabId();
  editorId = tabId();

  eventToPayloadState = key => e => {
    const { value } = e.target;
    this.setPayloadState({ [key]: value });
  };

  valueToPayloadState = key => v => this.setPayloadState({ [key]: v });

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));

  save = () => this.props.save(this.state.payload);

  handleSelect = medium =>
    this.setPayloadState({
      _mediaId: medium.id,
      src: medium.urlMedium,
      url: medium.url,
    });

  render() {
    const { title, content, backgroundColor, backgroundHoverColor } = this.state.payload;

    return (
      <Fragment>
        <QACardView {...this.state.payload} />
        <Dialog title="Questions and Answers" icon="help" isOpen onClose={this.save}>
          <div className="bp3-dialog-body">
            <Tabs id={this.tabGroupId}>
              <Tab
                id={this.contentTabId}
                title="Content"
                panel={
                  <Fragment>
                    <Input
                      name="title"
                      placeholder="Title"
                      onChange={this.eventToPayloadState('title')}
                      value={title}
                      className="bp3-fill"
                    >
                      Title <span className="bp3-text-muted">(optional)</span>
                    </Input>
                    <label className="bp3-label" htmlFor={this.editorId}>
                      Content
                      <QAList
                        id={this.editorId}
                        value={content}
                        onChange={this.valueToPayloadState('content')}
                      />
                    </label>
                  </Fragment>
                }
              />
              <Tab
                id={this.mediaTabId}
                title="Image"
                panel={<MediaPicker onSelect={this.handleSelect} />}
              />
            </Tabs>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default createCard({
  name: 'QACard',
  renderWith: withClickableHighlight(QACardView),
  editWith: withMedia(QACardEditor),
  buttonText: 'Q&A',
  payload: {
    type: QACardView.TYPE.INTERVIEW,

    title: '',
    intro: '',
    content: [], // { _id: String, question: Mobiledoc, answer: Mobiledoc }

    _mediaId: '',
    src: 'http://via.placeholder.com/350x350.png',
    url: 'http://via.placeholder.com/350x350.png',
  },
});
