import React from 'react';
import { Callout } from '@blueprintjs/core';

function validateJSONString(value) {
  try {
    JSON.parse(value);
  } catch {
    return false;
  }
  return true;
}

export default function ThemeSettingInputValidation({ value, type, ...props }) {
  let valid;

  switch (type) {
    case 'json':
      valid = value === '' || validateJSONString(value);
      break;
    default:
      valid = true;
  }

  if (!valid) {
    switch (type) {
      case 'json':
        return (
          <Callout style={{ margin: '20px 0' }} title="Invalid JSON" intent="warning">
            Please check that your JSON is correctly formatted.
          </Callout>
        );
      default:
        return 'error';
    }
  }

  return null;
}
