import React from 'react';
import filterProps from 'filter-invalid-dom-props';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import { ButtonGroup, Button, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const ActionGroup = ({
  onSave,
  saving,
  saveDisabled,
  onCancel,
  cancelling,
  onDelete,
  deleting,
  buttons,
  ...props
}) => (
  <ButtonGroup {...filterProps(props)}>
    {onSave && (
      <Button
        icon="floppy-disk"
        onClick={onSave}
        intent={Intent.SUCCESS}
        loading={saving}
        disabled={saveDisabled}
      >
        Save
      </Button>
    )}
    {onCancel && (
      <Button onClick={onCancel} loading={cancelling} icon="cross">
        Cancel
      </Button>
    )}
    {onDelete && (
      <Button onClick={onDelete} loading={deleting} icon="trash" intent={Intent.DANGER}>
        Delete
      </Button>
    )}
    {buttons}
  </ButtonGroup>
);

export default compose(
  setDisplayName('ActionGroup'),
  setPropTypes({
    onSave: PropTypes.func,
    saving: PropTypes.bool,
    onDelete: PropTypes.func,
    deleting: PropTypes.bool,
  }),
)(ActionGroup);
