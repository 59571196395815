import React, { Component } from 'react';
import styled from 'styled-components';
import { EditableText, Button, ButtonGroup, Colors, Intent } from '@blueprintjs/core';
import SortableTree, { changeNodeAtPath, insertNode, removeNode, map } from 'react-sortable-tree';
import Debug from '/components/Debug';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app

const Wrapper = styled.div`
  min-width: 100%;

  .w-menu-node {
    .rst__rowSubtitle {
      display: block;
      margin: 4px 0;
    }
    .rst__moveHandle {
      background-color: ${Colors.GRAY1};
    }
  }
  .rst__collapseButton,
  .rst__expandButton {
    border-radius: 0;
  }
`;

const TreeContainer = styled.div`
  height: 400px;
`;

const getNodeKey = ({ treeIndex }) => treeIndex;

const treeToItems = tree =>
  tree.reduce(
    (items, { text, url, primary, children }) =>
      items.concat([
        {
          text,
          url,
          primary,
          items: children && children.length ? treeToItems(children) : [],
        },
      ]),
    [],
  );

const itemsToTree = items =>
  items.reduce(
    (tree, { text, primary, url, items }) =>
      tree.concat([
        {
          text,
          url,
          primary,
          children: items && items.length ? itemsToTree(items) : [],
        },
      ]),
    [],
  );

export default class Tree extends Component {
  constructor({ items }) {
    super();
    this.state = {
      treeData: itemsToTree(items),
    };
  }

  setState(v) {
    return super.setState(v, this.bubbleChanges);
  }

  setTreeData = fn =>
    this.setState(({ treeData }) => ({
      treeData: fn(treeData),
    }));

  handleNodeFieldChange = (field, { node, path }) => v =>
    this.setTreeData(treeData =>
      changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...node, [field]: v },
      }),
    );

  handleInsert = () =>
    this.setTreeData(
      treeData =>
        insertNode({
          treeData,
          depth: 0,
          minimumTreeIndex: 0,
          getNodeKey,
          newNode: { url: '', text: '' },
        }).treeData,
    );

  handlePrimaryChange = primaryIndex =>
    this.setTreeData(treeData =>
      map({
        treeData,
        getNodeKey,
        callback: ({ node, treeIndex: idx }) => ({
          ...node,
          primary: node.primary ? false : idx === primaryIndex,
        }),
      }),
    );

  bubbleChanges = () => this.props.onChange(treeToItems(this.state.treeData));

  handleDelete = ({ path }) =>
    this.setTreeData(treeData => removeNode({ treeData, path, getNodeKey }).treeData);

  render() {
    return (
      <Wrapper>
        <Debug data={this.state} />
        <Button style={{ margin: '16px 0' }} onClick={this.handleInsert} intent={Intent.PRIMARY}>
          Add Menu Item
        </Button>
        <TreeContainer>
          <SortableTree
            treeData={this.state.treeData}
            onChange={treeData => this.setState({ treeData }, this.bubbleChanges)}
            rowHeight={80}
            maxDepth={2}
            generateNodeProps={({ node, path, treeIndex }) => ({
              className: 'w-menu-node',
              title: (
                <EditableText
                  placeholder="Text..."
                  onChange={this.handleNodeFieldChange('text', { node, path })}
                  value={node.text}
                />
              ),
              subtitle: (
                <>
                  <EditableText
                    placeholder="URL... (relative (/example) or absolute (https://otherwebsite.com/example))"
                    onChange={this.handleNodeFieldChange('url', { node, path })}
                    value={node.url}
                  />
                </>
              ),
              buttons: [
                <ButtonGroup>
                  <Button
                    icon={node.primary ? 'star' : 'star-empty'}
                    children="Primary"
                    intent={node.primary ? Intent.PRIMARY : Intent.NONE}
                    onClick={() => this.handlePrimaryChange(treeIndex)}
                  />
                  <Button
                    icon="delete"
                    intent={Intent.DANGER}
                    onClick={() => this.handleDelete({ node, path })}
                  />
                </ButtonGroup>,
              ],
            })}
          />
        </TreeContainer>
      </Wrapper>
    );
  }
}
