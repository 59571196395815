import React from 'react';

import Tabs from '/components/Tabs';
import Stripe from './Stripe';
import Mollie from './Mollie';

export default ({ settings, refetch }) => (
  <Tabs titles={['Stripe', 'Mollie']}>
    <Stripe refetch={refetch} settings={settings} />
    <Mollie refetch={refetch} settings={settings} />
  </Tabs>
);
