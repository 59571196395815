import React from 'react';
import gql from 'graphql-tag';
import { FormGroup, InputGroup, ControlGroup, MenuItem, Button, Intent } from '@blueprintjs/core';
import { Select, MultiSelect } from '@blueprintjs/select';
import TabularResource from '/components/TabularResource';
import ClipboardInput from '/components/ClipboardInput';
import Debug from '/components/Debug';
import wizard from '/lib/wizard';

import { WebhookFields, WebhookMutable } from '/data/fragments';

const EVENTS = [
  'attendance.confirmed',
  'attendance.created',
  'donation.confirmed',
  'donation.created',
  'entry.created',
  'entry.updated',
  'entry.deleted',
  'event.created',
  'event.updated',
  'event.deleted',
  'fundraiser.created',
  'fundraiser.updated',
  'fundraiser.deleted',
  'petition.created',
  'petition.updated',
  'petition.deleted',
  'signature.confirmed',
  'signature.created',
  'signup.created',
  'signup.updated',
  'signup.deleted',
  'submission.confirmed',
  'submission.created',
];

const METHODS = ['POST', 'PUT', 'DELETE', 'PATCH'];

const WEBHOOKS = gql`
  ${WebhookFields}
  query Menus {
    items: webhooks {
      ...WebhookFields
    }
  }
`;

const CREATE = gql`
  ${WebhookFields}
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook {
        ...WebhookFields
      }
    }
  }
`;

const UPDATE = gql`
  ${WebhookFields}
  mutation UpdateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      webhook {
        ...WebhookFields
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteWebhook($input: DeleteWebhookInput!) {
    deleteWebhook(input: $input) {
      webhook {
        id
      }
    }
  }
`;

const editHeaders = ({ headers }) => {
  console.log();
  return wizard.init({
    title: 'Edit Headers',
    steps: [
      {
        title: 'Headers',
        schema: {
          type: 'object',
          properties: {
            headers: {
              title: 'Headers',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  key: { title: 'Key', type: 'string' },
                  value: { title: 'Value', type: 'string' },
                },
              },
            },
          },
        },
      },
    ],
    value: [
      {
        headers: headers || [],
      },
    ],
  });
};

export default function WebhooksView() {
  const handleEditHeadersClick = (webhook, onChange) => () => {
    editHeaders(webhook).then(data => {
      const [{ headers }] = data;
      onChange({ ...webhook, headers });
    });
  };
  return (
    <TabularResource
      resourceName="Webhook"
      tabInfo={webhook => ({ title: webhook.name })}
      mutableFragment={WebhookMutable}
      queries={{
        create: {
          query: CREATE,
          variables: {
            input: {
              webhook: {
                name: 'My webhook',
                events: [],
                method: 'POST',
                endpoint: 'https://examplewebhook.url',
              },
            },
          },
        },
        read: { query: WEBHOOKS },
        update: {
          query: UPDATE,
          variables: (id, webhook) => ({
            input: {
              id,
              webhook,
            },
          }),
        },
        delete: { query: DELETE, variables: id => ({ input: { id } }) },
      }}
    >
      {(webhook, { onChange }) => (
        <>
          <FormGroup
            helperText="A good name helps identify this webhook (example: A Awesome Hook)"
            label="Name"
            labelFor="name-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="name-input"
              value={webhook.name || ''}
              onChange={e => onChange({ ...webhook, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup
            helperText="The endpoint url (example: https://awesomeapi.com/api). Must use https."
            label="Endpoint"
            labelFor="endpoint-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="endpoint-input"
              value={webhook.endpoint || ''}
              onChange={e => onChange({ ...webhook, endpoint: e.target.value })}
            />
          </FormGroup>
          <FormGroup
            helperText="The events that trigger the webhook"
            label="Events"
            labelFor="events-input"
            labelInfo="(required)"
          >
            <MultiSelect
              id="events-input"
              items={EVENTS.filter(e => !webhook.events.map(_e => _e.action).includes(e))}
              selectedItems={webhook.events.map(e => e.action)}
              itemRenderer={(item, { handleClick, active }) => (
                <MenuItem onClick={handleClick} active={active} text={item} />
              )}
              onItemSelect={item =>
                onChange({
                  ...webhook,
                  events:
                    webhook.events.filter((_item, i, arr) => arr[i].action === item).length > 0
                      ? webhook.events
                      : [...webhook.events, { action: item }],
                })
              }
              itemPredicate={(query, item) =>
                item.toLowerCase().indexOf((query || '').toLowerCase()) >= 0
              }
              filterable
              resetOnSelect
              noResults={<MenuItem disabled text="No results." />}
              tagRenderer={item => item}
              tagInputProps={{
                onRemove: item =>
                  onChange({
                    ...webhook,
                    events: webhook.events.filter((_item, i, arr) => arr[i].action !== item),
                  }),
              }}
            />
          </FormGroup>
          <FormGroup label="HTTP Method" labelFor="method-input" labelInfo="(required)">
            <Select
              items={METHODS.filter(m => m !== webhook.method)}
              itemRenderer={(item, { handleClick, active }) => (
                <MenuItem onClick={handleClick} active={active} text={item} />
              )}
              onItemSelect={method =>
                onChange({
                  ...webhook,
                  method,
                })
              }
              itemPredicate={(query, item) =>
                item.toLowerCase().indexOf((query || '').toLowerCase()) >= 0
              }
              noResults={<MenuItem disabled text="No results." />}
              filterable
            >
              <Button>{webhook.method}</Button>
            </Select>
          </FormGroup>
          <FormGroup label="Headers">
            {!webhook.headers || !webhook.headers.length
              ? null
              : webhook.headers.map(header => (
                  <ControlGroup>
                    <ClipboardInput
                      value={header.key}
                      // labels={webhook.redirect ? ['redirect'] : []}
                      style={{ marginBottom: '4px', display: 'inline-block', width: '50%' }}
                    />
                    <ClipboardInput
                      value={header.value}
                      // labels={webhook.redirect ? ['redirect'] : []}
                      style={{ marginBottom: '4px', display: 'inline-block', width: '50%' }}
                    />
                  </ControlGroup>
                ))}
            <Button intent={Intent.PRIMARY} onClick={handleEditHeadersClick(webhook, onChange)}>
              Edit headers
            </Button>
          </FormGroup>
          <Debug data={webhook} />
        </>
      )}
    </TabularResource>
  );
}
