import React from 'react';
import NodeEditor from './NodeEditor';
import Card from './Card';
import MobiledocEditor from './MobiledocEditor';

export default ({ entry, ...props }) => (
  <div>
    <NodeEditor node={entry} {...props}>
      {({ node, updateField }) => (
        <Card elevation={2} style={{ marginTop: 12, minHeight: '100vh' }}>
          <MobiledocEditor mobiledoc={node.content} onChange={updateField('content')} autoFocus />
        </Card>
      )}
    </NodeEditor>
  </div>
);
