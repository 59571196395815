import React, { Component } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import withMedia from '/containers/withMedia';

const imageSize = 192;
const imageSize2 = imageSize * 2;
const maxHeight = imageSize2 + 16;

const Wrapper = styled.div`
  width: 100%;
  height: ${maxHeight + 30}px;
  overflow-y: scroll;
`;

const MediaWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-height: ${maxHeight}px;
  overflow-y: scroll;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${imageSize}px;
  height: ${imageSize}px;
  overflow: hidden;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
`;

const ImageName = styled.h2`
  background: rgba(0, 0, 0, 0.7);
  display: inline-block;
  padding: 5px;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  top: ${imageSize / 1.3}px;
  left: 0;
  width: auto;
  line-height: 1.4;
  span {
    color: white;
  }
`;

const InputWrapper = styled.div`
  padding: 3px 3px 10px 3px;
`;

class MediaPicker extends Component {
  static defaultProps = {
    data: { media: [] },
  };

  state = { search: '' };

  componentDidMount() {
    setTimeout(() => this.input.focus(), 0);
  }

  handleSearchChange = e => this.setState({ search: e.target.value });
  filter = m => m.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;

  get filteredMedia() {
    return this.props.data.media.filter(this.filter);
  }

  handleSelect = medium => this.props.onSelect(medium);

  render() {
    const { dialog, onClose } = this.props;
    const { search } = this.state;
    const picker = (
      <Wrapper>
        <InputWrapper>
          <input
            ref={ref => {
              this.input = ref;
            }}
            className="bp3-input bp3-fill"
            id="mediapicker-input"
            type="text"
            onChange={this.handleSearchChange}
            placeholder="Type image name to search"
            value={search}
          />
        </InputWrapper>
        <MediaWrapper>
          {this.filteredMedia.map(medium => (
            <ImageContainer
              key={`mediapicker${medium.id}`}
              onClick={e => {
                e.stopPropagation();
                this.handleSelect(medium);
              }}
            >
              <Image src={medium.url} alt={medium.alt} />
              <ImageName>
                <span>{medium.name}</span>
              </ImageName>
            </ImageContainer>
          ))}
        </MediaWrapper>
      </Wrapper>
    );

    return !dialog ? (
      picker
    ) : (
      <Dialog title="Media" icon="media" isOpen onClose={onClose}>
        {picker}
      </Dialog>
    );
  }
}
export default compose(withMedia)(MediaPicker);
