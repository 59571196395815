import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WINGS_API_ENDPOINT } from '/config';
import { fetchProgress } from '/lib/utils';
import history from './history';

const getToken = () => localStorage.getItem('accessToken');

export const getProjectId = () => {
  const path = history.location.pathname;
  const [, projectId] = path.split('/');
  return projectId;
};

export const authHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'X-Wings-Project': getProjectId(),
});

const query = query =>
  fetch(WINGS_API_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeaders() },
    body: JSON.stringify({
      query,
    }),
  }).then(result => result.json());

const introspect = async () => {
  const result = await query(`
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `);
  const filteredData = result.data.__schema.types.filter(type => type.possibleTypes !== null);
  result.data.__schema.types = filteredData;
  return result.data;
};

export default async () =>
  new ApolloClient({
    link: new HttpLink({
      fetch: (uri, options) =>
        fetch(WINGS_API_ENDPOINT, {
          ...options,
          headers: { ...options.headers, ...authHeaders() },
        }),
    }),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: await introspect(),
      }),
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

export const gqlFetcher = graphQLParams =>
  fetch(WINGS_API_ENDPOINT, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      ...authHeaders(),
    },
    body: JSON.stringify(graphQLParams),
  }).then(response => response.json());

const UPLOAD_ENDPOINT = `${WINGS_API_ENDPOINT}/_/media/upload`;

export const uploadMediaFile = (file, onProgress = () => {}) => {
  const data = new FormData();
  data.append('file', file);
  data.set('projectId', getProjectId());

  return fetchProgress(
    UPLOAD_ENDPOINT,
    {
      method: 'POST',
      headers: { ...authHeaders() },
      body: data,
    },
    onProgress,
  );
};
