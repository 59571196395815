import React from 'react';
import { compose, mapProps } from 'recompose';

import styled from 'styled-components';
import { Button, NonIdealState, Intent, HTMLSelect } from '@blueprintjs/core';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import qs from 'qs';

import { usePaginatedQuery } from '/hooks';
import Breadcrumb from '/components/Breadcrumb';
import EntryList from '/components/EntryList';
import Card from '/components/Card';
import ActionBar from '/components/ActionBar';
import withProjects from '/containers/withProjects';

const ENTRIES = gql`
  query Entries($selector: EntrySelectorInput, $after: String) {
    entries(first: 20, after: $after, selector: $selector) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          id
          title
          slug
          locale {
            id
            name
            primary
          }
          status
          publishedAt
          updatedAt
          createdAt
        }
      }
    }
  }
`;

const parseQs = props => qs.parse(props.location.search.replace('?', ''));

const withData = Comp => props => {
  const query = usePaginatedQuery({
    query: ENTRIES,
    options: {
      variables: {
        selector: { typeId: { eq: props.type } },
      },
      fetchPolicy: 'cache-and-network',
    },
    pageInfoFromData: data => data?.entries.pageInfo,
  });

  return <Comp {...props} {...query} />;
};

const withCreate = graphql(
  gql`
    mutation CreateEntry($typeId: String!, $entry: EntryInput!) {
      entry: createEntry(input: { typeId: $typeId, entry: $entry }) {
        entry {
          id
        }
      }
    }
  `,
  { name: 'createEntry' },
);

const Wrapper = styled.div``;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Pagination = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default compose(
  mapProps(props => ({ ...props, type: parseQs(props).type })),
  withProjects,
  withData,
  withCreate,
  mapProps(props => ({
    ...props,
    onCreate: async () => {
      const {
        data: {
          entry: { entry },
        },
      } = await props.createEntry({
        variables: { typeId: props.type, entry: {} },
      });
      props.history.push(props.projectUrl(`/entries/${entry.id}`));
    },
  })),
)(
  ({
    data: { entries: { edges: entries = [] } = {} } = {},
    onCreate,
    projectUrl,
    type,
    history,
    PaginationControls,
  }) => (
    <Wrapper>
      <ActionBar>
        <Breadcrumb
          items={[
            {
              text: (
                <HTMLSelect
                  options={[
                    { label: 'Article', value: 'article' },
                    { label: 'Page', value: 'page' },
                  ]}
                  onChange={e => history.push(projectUrl(`/content?type=${e.target.value}`))}
                  value={type}
                />
              ),
            },
          ]}
        />
        <ActionBar.Actions>
          <Button intent={Intent.PRIMARY} onClick={onCreate}>
            New
          </Button>
        </ActionBar.Actions>
      </ActionBar>
      <Container>
        {!entries.length ? (
          <NonIdealState
            visual="folder-open"
            title="There's nothing here..."
            description="Create a new page to get started."
          />
        ) : (
          <Card style={{ width: '100%' }}>
            <EntryList
              entries={entries.map(({ node: e }) => ({
                ...e,
                editUrl: projectUrl(`/entries/${e.id}`),
              }))}
            />
            <Pagination>
              <PaginationControls />
            </Pagination>
          </Card>
        )}
      </Container>
    </Wrapper>
  ),
);
