import React from 'react';
import gql from 'graphql-tag';
import TabularResource from '/components/TabularResource';
import { TranslationFields, TranslationMutable } from '/data/fragments';
import TranslationEditor from './TranslationEditor';

const CREATE = gql`
  ${TranslationFields}
  mutation CreateTranslation($input: CreateTranslationInput!) {
    createTranslation(input: $input) {
      translation {
        ...TranslationFields
      }
    }
  }
`;

const UPDATE = gql`
  ${TranslationFields}
  mutation UpdateTranslation($input: UpdateTranslationInput!) {
    updateTranslation(input: $input) {
      translation {
        ...TranslationFields
      }
    }
  }
`;

const DELETE = gql`
  ${TranslationFields}
  mutation DeleteTranslation($input: DeleteTranslationInput!) {
    deleteTranslation(input: $input) {
      translation {
        ...TranslationFields
      }
    }
  }
`;

const READ = gql`
  ${TranslationFields}
  query Translations {
    items: translations {
      ...TranslationFields
    }
  }
`;

export default function TranslationSettings({ i18n: { locales } }) {
  return (
    <TabularResource
      resourceName="Translation"
      tabInfo={translation => ({ title: translation.name })}
      mutableFragment={TranslationMutable}
      queries={{
        create: {
          query: CREATE,
          variables: { input: { translation: { name: 'My Translation', overrides: [] } } },
        },
        read: { query: READ },
        update: {
          query: UPDATE,
          variables: (id, { ...translation }) => ({
            input: {
              id,
              translation,
            },
          }),
        },
        delete: {
          query: DELETE,
          variables: id => ({ input: { id } }),
        },
      }}
    >
      {(translation, { onChange }) => (
        <TranslationEditor value={translation} onChange={onChange} locales={locales} />
      )}
    </TabularResource>
  );
}
