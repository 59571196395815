import { enumerate } from '/lib/utils';

export const NODE_TYPE = enumerate(
  ['ENTRY', 'entry'],
  ['PETITION', 'petition'],
  ['EVENT', 'event'],
  ['SIGNUP', 'signup'],
  ['FUNDRAISER', 'fundraiser'],
);

export const NODE_TYPE_TEXT = {
  [NODE_TYPE.ENTRY]: 'Entry',
  [NODE_TYPE.PETITION]: 'Petition',
  [NODE_TYPE.EVENT]: 'Event',
  [NODE_TYPE.SIGNUP]: 'Signup',
  [NODE_TYPE.FUNDRAISER]: 'Fundraiser',
};

export const RESOURCE_TYPE = {
  ARTICLE: 'node.entry.article',
  PAGE: 'node.entry.page',
  PETITION: 'node.petition',
  EVENT: 'node.event',
  SIGNUP: 'node.signup',
  FUNDRAISER: 'node.fundraiser',
};

export const ENTRY_TYPE = enumerate(['ARTICLE', 'article'], ['PAGE', 'page']);
export const ENTRY_TYPE_TEXT = {
  [ENTRY_TYPE.ARTICLE]: 'Article',
  [ENTRY_TYPE.PAGE]: 'Page',
};

const entrySubTypeToString = subType => {
  switch (subType) {
    case ENTRY_TYPE.ARTICLE:
      return ENTRY_TYPE_TEXT[ENTRY_TYPE.ARTICLE];
    case ENTRY_TYPE.PAGE:
      return ENTRY_TYPE_TEXT[ENTRY_TYPE.PAGE];
    default:
      return NODE_TYPE_TEXT[NODE_TYPE.ENTRY];
  }
};

export const resourceTypeText = resourceType => {
  const [, nodeType, subType] = resourceType.split('.');
  switch (true) {
    case nodeType === NODE_TYPE.ENTRY:
      return entrySubTypeToString(subType);
    default:
      return NODE_TYPE_TEXT[nodeType];
  }
};

export const STATUS = enumerate(['DRAFT', 'DRAFT'], ['PUBLISHED', 'PUBLISHED']);

export const STATUS_TEXT = {
  [STATUS.DRAFT]: 'Draft',
  [STATUS.PUBLISHED]: 'Published',
};
