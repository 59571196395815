import React from 'react';

export default [
  {
    id: 'formBackgroundColor',
    title: 'Form Background Color',
    description: () => (
      <>
        The background color of{' '}
        <a
          href="https://components.wings.dev/?path=/story/jsonschema-form-schemaform--default"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'formLinkTextColor',
    title: 'Form Link Text Color',
    description: () => (
      <>
        The text color of links in{' '}
        <a
          href="https://components.wings.dev/?path=/story/jsonschema-form-schemaform--default"
          target="_blank"
        >
          Forms
        </a>
        . Default calculated from text colors and the Form background.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'textColor',
  },
  {
    id: 'formTextColor',
    title: 'Form Text Color',
    description: () => (
      <>
        The text color in{' '}
        <a
          href="https://components.wings.dev/?path=/story/jsonschema-form-schemaform--default"
          target="_blank"
        >
          Forms
        </a>
        . Default calculated from text colors and the Form background.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormBackgroundColor',
    title: 'Campaign Form Background Color',
    description: () => (
      <>
        The background color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'formBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormLinkTextColor',
    title: 'Campaign Form Link Text Color',
    description: () => (
      <>
        The text color of links in campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        . Default calculated from text colors and the CampaignForm background.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'formLinkTextColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormTextColor',
    title: 'Campaign Form Text Color',
    description: () => (
      <>
        The text color in campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        . Default calculated from text colors and the CampaignForm background.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'formTextColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormButtonBackgroundColor',
    title: 'Campaign Form Button Background Color',
    description: () => (
      <>
        The button background color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormButtonBackgroundHoverColor',
    title: 'Campaign Form Button Background Hover Color',
    description: () => (
      <>
        The button background hover color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormButtonTextColor',
    title: 'Campaign Form Button Text Color',
    description: () => (
      <>
        The button text color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'campaignFormButtonTextHoverColor',
    title: 'Campaign Form Button Hover Text Color',
    description: () => (
      <>
        The button hover text color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Forms
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'campaignPropositionBackgroundColor',
    title: 'Campaign Proposition Background Color',
    description: () => (
      <>
        The background color of campaign{' '}
        <a
          href="https://components.wings.dev/?path=/story/react-campaign--petition"
          target="_blank"
        >
          Proposition (text area)
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'counterBarColor',
    title: 'Counter Bar Color',
    description: () => (
      <>
        The bar color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-counter--default"
          target="_blank"
        >
          Counter
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'counterBackgroundColor',
    title: 'Counter Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-counter--default"
          target="_blank"
        >
          Counter
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'backgroundColor',
    defaultValue: null,
  },
  {
    id: 'counterTextColor',
    title: 'Counter Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-counter--default"
          target="_blank"
        >
          Counter
        </a>{' '}
        component. Default calculated from text colors and the Counter background.
      </>
    ),
    type: 'color',
    group: 'Forms',
    parent: 'textColor',
    defaultValue: null,
  },
];
