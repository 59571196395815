import React from 'react';
import { Dialog as _BDialog, Classes } from '@blueprintjs/core';
import styled from 'styled-components';

const BDialog = styled(_BDialog)`
  padding-bottom: 0;
`;

const Body = styled.div`
  margin: 0;
`;

export default function Dialog({ children, ...props }) {
  return (
    <BDialog icon="info-sign" {...props}>
      <Body className={Classes.DIALOG_BODY}>{children}</Body>
    </BDialog>
  );
}
