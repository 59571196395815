import React from 'react';

export default [
  {
    id: 'logoImageUrl',
    title: 'Logo Image URL',
    description: () => <>Url for the logo image.</>,
    type: 'image',
    group: 'Images',
    parent: null,
    defaultValue: '',
  },
  {
    id: 'faviconImageUrl',
    title: 'Favicon Image URL',
    description: () => <>Url for the favicon image.</>,
    type: 'image',
    group: 'Images',
    parent: null,
    defaultValue: '',
  },
];
