import React from 'react';
import { Spinner } from '@blueprintjs/core';
import styled from 'styled-components';
import classNames from 'classnames';

const Wrapper = styled.main`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  &.fill {
    height: calc(100vh - 50px);
  }
`;

const Message = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export default ({ fill = false, text = '' }) => (
  <Wrapper className={classNames({ fill })}>
    <Spinner />
    {text && <Message>{text}</Message>}
  </Wrapper>
);
