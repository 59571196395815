import gql from 'graphql-tag';
export { filter } from 'graphql-anywhere';

const NodeFields = gql`
  fragment NodeFields on Node {
    nodeType
    resourceType
    id
    title
    slug(inherit: false)
    inheritedSlug: slug(inherit: true)
    publicSlug: slug
    locale {
      id
      name
      primary
    }
    featured(inherit: false) {
      title
      description
      image {
        id
        url
      }
    }
    copy {
      message {
        messageId
        description
        message
      }
    }
    copyOverrides {
      message
      messageId
    }
    platforms(inherit: false) {
      search {
        title
        description
      }
      facebook {
        title
        description
        image {
          id
          url
        }
      }
      twitter {
        title
        description
        image {
          id
          url
        }
      }
      whatsapp {
        text
      }
    }
    image {
      id
      url
    }
    meta {
      key
      value
    }
    apps {
      app {
        id
      }
      menu {
        id
      }
    }
    schemas {
      id
      name
      key
      definition
    }
    data {
      key
      data
    }
    status
    publishedAt
    updatedAt
    createdAt
  }
`;

const NodeMutable = gql`
  fragment NodeMutable on Nodee {
    title
    slug
    locale {
      id
      name
    }
    copyOverrides {
      message
      messageId
    }
    featured {
      title
      description
      image {
        id
        url
      }
    }
    platforms {
      search {
        title
        description
      }
      facebook {
        title
        description
        image {
          id
          url
        }
      }
      twitter {
        title
        description
        image {
          id
          url
        }
      }
      whatsapp {
        text
      }
    }
    image {
      id
      url
    }
    meta {
      key
      value
    }
    data {
      key
      data
    }
    status
    publishedAt
    apps {
      app {
        id
      }
      menu {
        id
      }
    }
  }
`;

export const prepareNodeMutation = ({ platforms, meta, apps = [], ...node }) => ({
  ...node,
  locale: undefined,
  localeId: node.locale.id,
  image: undefined,
  imageId: node.image ? node.image.id : null,
  meta: !meta ? null : meta.filter(m => m.key.length > 0 && m.value.length > 0),
  featured: {
    ...node.featured,
    image: undefined,
    imageId: node.featured?.image?.id,
  },
  platforms: {
    search: {
      title: platforms?.search?.title,
      description: platforms?.search?.description,
      imageId: platforms?.search?.image?.id,
    },
    facebook: {
      title: platforms?.facebook?.title,
      description: platforms?.facebook?.description,
      imageId: platforms?.facebook?.image?.id,
    },
    twitter: {
      title: platforms?.twitter?.title,
      description: platforms?.twitter?.description,
      imageId: platforms?.twitter?.image?.id,
    },
    whatsapp: {
      text: platforms?.whatsapp?.text,
    },
  },
  apps: apps.map(({ app, menu }) => ({ appId: app.id, menuId: menu.id })),
});

export const CampaignFields = gql`
  fragment CampaignFields on Campaign {
    ...NodeFields
    intro
    description
    submissionFields {
      id
      type
      label
      selectOptions {
        items {
          text
        }
      }
    }
    settings {
      legal {
        terms {
          disabled
          url
        }
        privacyPolicy {
          url
        }
      }
      mailchimp {
        listId
        fieldMapping {
          field
          source
          value
        }
        interestMapping {
          interest
          source
          value
        }
      }
    }
  }
  ${NodeFields}
`;

export const CampaignMutable = gql`
  fragment CampaignMutable on Campaign {
    ...NodeMutable
    intro
    description
    submissionFields {
      id
      type
      label
      selectOptions {
        items {
          text
        }
      }
    }
    settings {
      legal {
        terms {
          disabled
          url
        }
        privacyPolicy {
          url
        }
      }
      mailchimp {
        listId
        fieldMapping {
          field
          source
          value
        }
        interestMapping {
          interest
          source
          value
        }
      }
    }
  }
  ${NodeMutable}
`;

export const ProjectFields = gql`
  fragment ProjectFields on Project {
    id
    name
    settings {
      commerce {
        paymentGateways {
          stripe {
            secret
            publicKey
            status
          }
          mollie {
            apiKey
            status
          }
        }
        currencySettings {
          currencyId
          availableCurrencies {
            id
            name
            symbol
          }
        }
      }
      i18n {
        availableLocales {
          id
          name
        }
        locales {
          locale {
            id
            name
          }
          primary
        }
      }
      mailchimp {
        apiKey
      }
      emails {
        emailFrom
        emailFromName
        availableTemplates {
          name
          title
          mergeTags {
            name
            value
          }
        }
        templates {
          name
          subject
          template
          design
        }
      }
    }
  }
`;

export const ProjectMutable = gql`
  fragment ProjectMutable on Project {
    name
    settings {
      i18n {
        locales {
          localeId
          primary
        }
      }
      mailchimp {
        apiKey
      }
      emails {
        emailFrom
        emailFromName
        templates {
          name
          subject
          template
          design
        }
      }
    }
  }
`;

export const ProjectLocales = gql`
  fragment ProjectLocales on Project {
    id
    settings {
      i18n {
        locales {
          locale {
            id
            name
          }
          primary
        }
      }
    }
  }
`;

export const SignupFields = gql`
  fragment SignupFields on Signup {
    ...CampaignFields
  }
  ${CampaignFields}
`;

export const SignupMutable = gql`
  fragment SignupMutable on Signup {
    ...CampaignMutable
  }
  ${CampaignMutable}
`;

export const PetitionFields = gql`
  fragment PetitionFields on Petition {
    ...CampaignFields
    signatureCount
    options {
      disableSignatureCount
      signatureOffset
    }
  }
  ${CampaignFields}
`;

export const PetitionMutable = gql`
  fragment PetitionMutable on Petition {
    ...CampaignMutable
    options {
      disableSignatureCount
      signatureOffset
    }
  }
  ${CampaignMutable}
`;

export const EventFields = gql`
  fragment EventFields on Event {
    ...CampaignFields
    attendeeCount
    schedule {
      start
      end
    }
    fee {
      amount {
        amount
        currency {
          id
          name
          symbol
        }
      }
    }
    location {
      name
      street
      city
      zip
      country
    }
  }
  ${CampaignFields}
`;

export const EventMutable = gql`
  fragment EventMutable on Event {
    ...CampaignMutable
    schedule {
      start
      end
    }
    fee {
      amount {
        amount
      }
    }
    location {
      name
      street
      city
      zip
      country
    }
  }
  ${CampaignMutable}
`;

export const FundraiserFields = gql`
  fragment FundraiserFields on Fundraiser {
    ...CampaignFields
    amounts {
      disableCustomAmount
      options {
        amount {
          amount
          currency {
            id
          }
        }
      }
    }
    target {
      amount
      currency {
        id
        name
        symbol
      }
    }
    options {
      disableRaised
      raisedOffset {
        amount
        currency {
          id
          name
          symbol
        }
      }
    }
  }
  ${CampaignFields}
`;

export const FundraiserMutable = gql`
  fragment FundraiserMutable on Fundraiser {
    ...CampaignMutable
    target {
      amount
    }
    amounts {
      disableCustomAmount
      options {
        amount {
          amount
        }
      }
    }
    options {
      disableRaised
      raisedOffset {
        amount
      }
    }
  }
  ${CampaignMutable}
`;

export const EntryFields = gql`
  fragment EntryFields on Entry {
    ...NodeFields
    type {
      id
      name
    }
    content
  }
  ${NodeFields}
`;

export const EntryMutable = gql`
  fragment EntryMutable on Entry {
    ...NodeMutable
    content
  }
  ${NodeMutable}
`;

export const ArticleFields = gql`
  fragment ArticleFields on Article {
    ...NodeFields
    content
  }
  ${NodeFields}
`;

export const ArticleMutable = gql`
  fragment ArticleMutable on Article {
    ...NodeMutable
    content
  }
  ${NodeMutable}
`;

export const MenuMutable = gql`
  fragment MenuMutable on Menu {
    name
    items {
      url
      text
      primary
      items {
        url
        text
      }
    }
  }
`;

export const MenuFields = gql`
  fragment MenuFields on Menu {
    id
    name
    items {
      url
      text
      primary
      items {
        url
        text
      }
    }
  }
`;

export const ThemeMutable = gql`
  fragment ThemeMutable on WebAppTheme {
    name
    properties {
      key
      value
    }
  }
`;

export const ThemeFields = gql`
  fragment ThemeFields on WebAppTheme {
    id
    name
    properties {
      key
      value
    }
  }
`;

export const AppMutable = gql`
  fragment AppMutable on App {
    name
    menu {
      id
    }
    home {
      node {
        nodeType
        id
      }
    }
    theme {
      id
    }
    webhooks {
      all
    }
    translation {
      id
    }
    domain
    domains {
      domain
      redirect
    }
    scripts {
      name
      script
      location
    }
  }
`;

export const AppFields = gql`
  fragment AppFields on App {
    id
    name
    auth {
      key
      secret
    }
    ... on WebApp {
      slug
      wingsDomain
      domain
      domains {
        domain
        redirect
      }
      scripts {
        name
        script
        location
      }
      home {
        node {
          nodeType
          id
        }
      }
      translation {
        id
        name
        overrides {
          localeId
          messageId
          message
        }
      }
      menu {
        id
      }
      theme {
        id
        name
      }
      webhooks {
        all
      }
    }
  }
`;

export const SchemaFields = gql`
  fragment SchemaFields on Schema {
    id
    name
    key
    definition
    locations
  }
`;

export const SchemaMutable = gql`
  fragment SchemaMutable on Schema {
    name
    key
    definition
    locations
  }
`;

export const TranslationFields = gql`
  fragment TranslationFields on Translation {
    id
    name
    overrides {
      localeId
      messageId
      message
    }
  }
`;

export const TranslationMutable = gql`
  fragment TranslationMutable on Translation {
    name
    overrides {
      localeId
      messageId
      message
    }
  }
`;

export const WebhookFields = gql`
  fragment WebhookFields on Webhook {
    id
    name
    events {
      action
    }
    method
    endpoint
    headers {
      key
      value
    }
  }
`;

export const WebhookMutable = gql`
  fragment WebhookMutable on Webhook {
    name
    events {
      action
    }
    method
    endpoint
    headers {
      key
      value
    }
  }
`;
