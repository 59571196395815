import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { useCopy } from '/hooks';

export default function CopyEditor({ resourceType, overrides = [], onChange, localeId }) {
  const { messages, defaultAppCopy: _defaultAppCopy } = useCopy(resourceType);
  const defaultAppCopy = _defaultAppCopy.filter(t => t.locale.id === localeId);

  const handleCopyChange = id => e => {
    const v = e.target.value;

    let newOverrides = [...overrides.filter(m => m.messageId !== id)];

    if (v !== '') {
      newOverrides = newOverrides.concat({
        messageId: id,
        message: v,
        ...(localeId ? { localeId } : {}),
      });
    }

    onChange(newOverrides);
  };

  return (
    <div>
      {messages.map(({ description, id }) => {
        const message = overrides.find(m => m.messageId === id)?.message || '';
        const placeholder =
          defaultAppCopy.find(m => m.message.messageId === id)?.message.message || '';
        return (
          <FormGroup label={description}>
            <InputGroup
              placeholder={placeholder}
              value={message}
              onChange={handleCopyChange(id)}
            ></InputGroup>
          </FormGroup>
        );
      })}
    </div>
  );
}
