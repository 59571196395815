import React, { Component } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import Breadcrumb from '/components/Breadcrumb';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';

const ActionBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default class View extends Component {
  render() {
    const { breadcrumbs, ...props } = this.props;
    return (
      <Query fetchPolicy="cache-and-network" {...props}>
        {({ data, loading, error, refetch }) =>
          loading && !data ? (
            <Loading fill />
          ) : error ? (
            <ErrorCallout message="Something went wrong." />
          ) : (
            <>
              <ActionBar>
                <Breadcrumb
                  items={
                    (typeof breadcrumbs === 'function' ? breadcrumbs(data) : breadcrumbs) || []
                  }
                />
              </ActionBar>
              {this.props.children({ data, refetch })}
            </>
          )
        }
      </Query>
    );
  }
}
