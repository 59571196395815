import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createCard } from '@wingsplatform/react';
import { WINGS_API_ENDPOINT } from '/config';
import withClickableHighlight from '/styles/withClickableHighlight';
import EmbedCardView from './EmbedCardView';

class EmbedCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = { ...this.props.payload };

  async debouncedUpdate(cb) {
    console.log('debouncedUpdate');
    this.update(cb);
  }

  async update(cb = () => {}) {
    const { src } = this.state;
    const res = await fetch(`${WINGS_API_ENDPOINT}/_/oembed/${encodeURIComponent(src)}`);
    const data = await res.json();
    this.setState({ html: data.html }, () => cb()); // eslint-disable-line react/no-unused-state
  }

  handleChange = e => this.setState({ src: e.target.value }, () => this.debouncedUpdate());
  save = () => {
    // this.debouncedUpdate.cancel();
    this.update(() => this.props.save(this.state));
  };

  render() {
    const { payload, save } = this.props;
    const { src, html } = this.state;
    return (
      <div>
        <div>
          {'Source: '}
          <input type="text" onChange={this.handleChange} value={src} />
          <button onClick={this.save}>save</button>
          <button onClick={() => save(payload)}>cancel</button>
        </div>
        <EmbedCardView src={src} html={html} />
      </div>
    );
  }
}

export default createCard({
  name: 'EmbedCard',
  renderWith: withClickableHighlight(EmbedCardView),
  editWith: EmbedCardEditor,
  buttonText: 'Embed',
  payload: {
    src: 'https://www.youtube.com/watch?v=Htckxql7GRA',
    html:
      '<iframe width="480" height="270" src="https://www.youtube.com/embed/Htckxql7GRA?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
});
