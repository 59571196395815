import createMedia from './media';

export default {
  media: createMedia({
    giant: 1170,
    desktop: 992,
    tablet: 768,
    phone: 376,
  }),
};
