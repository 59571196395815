import React, { useState } from 'react';
import { InputGroup, FormGroup, Callout, Intent } from '@blueprintjs/core';
import JSON5 from 'json5';
import CodeEditor from '/components/CodeEditor';
import Debug from '/components/Debug';
import Form from '/components/Form';
import Locations from './Locations';

const isValidDefinition = def => {
  if (def === null) return false;
  try {
    JSON5.parse(def);
    return true;
  } catch {
    return false;
  }
};

export default function SchemaEditor({ value: schema, onChange }) {
  const [definition, setDefinition] = useState(schema.definition);

  const handleDefinitionChange = v => {
    setDefinition(v);
    if (isValidDefinition(v)) onChange({ ...schema, definition: v });
  };

  return (
    <>
      <FormGroup labelFor="name-input" label="Name" helperText="a descriptive name for your schema">
        <InputGroup
          id="name-input"
          placeholder="My Schema"
          value={schema.name}
          onChange={e =>
            onChange({
              ...schema,
              name: e.target.value,
            })
          }
        />
      </FormGroup>
      <FormGroup labelFor="key-input" label="Key" helperText="used to identify data in the API">
        <InputGroup
          id="key-input"
          placeholder="my-schema"
          value={schema.key}
          onChange={e =>
            onChange({
              ...schema,
              key: e.target.value.replace(/[^a-z]/g, '').substr(0, 30),
            })
          }
        />
      </FormGroup>
      <Locations value={schema.locations} onChange={v => onChange({ ...schema, locations: v })} />
      <hr />
      <CodeEditor value={definition} onChange={handleDefinitionChange} />
      <hr />
      {isValidDefinition(definition) ? (
        <Form liveValidate schema={JSON5.parse(definition)} />
      ) : (
        <Callout intent={Intent.WARNING}>Parse Error</Callout>
      )}
      <Debug data={schema} />
    </>
  );
}
