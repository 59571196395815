import React, { Component } from 'react';
import { Classes } from '@blueprintjs/core';
import styled from 'styled-components';
import cardSVG from './cta.svg';

const Container = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fcfcfc;
  &:hover {
  }
`;

export default class CTACardView extends Component {
  render() {
    const { title, text, actionText, buttonEditor, ...props } = this.props;

    return (
      <Container {...props}>
        <div className={Classes.TEXT_MUTED} style={{ textAlign: 'center' }}>
          <h3>Call To Action</h3>
          <img src={cardSVG} />
        </div>
      </Container>
    );
  }
}
