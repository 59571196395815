/* global FormData,fetch */
import React, { Component } from 'react';
import { compose, mapProps } from 'recompose';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import withMedia from '/containers/withMedia';
import withProjects from '/containers/withProjects';
import { uploadMediaFile } from '/data/client';

const Wrapper = styled.div`
  .dropzone {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #bfccd6;
    margin: 16px 0;
    border-radius: 3px;
  }

  .dropzone p {
    margin: 0;
  }
`;

const MediaWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
`;

const ImageContainer = styled.div`
  width: 192px;
  height: 192px;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  margin: 2px;
`;

class FilesView extends Component {
  static defaultProps = {
    activeProject: {},
    media: [],
  };

  onDrop = async files => {
    const [file] = files;
    await uploadMediaFile(file);
    this.props.refetch();
  };

  render() {
    const { media, projectUrl } = this.props;
    return (
      <Wrapper>
        <div>
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={classNames('dropzone', {
                  'dropzone--isActive': isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? <p>Let go to upload! 🎉</p> : <p>Drop your file here...</p>}
              </div>
            )}
          </Dropzone>
        </div>
        <MediaWrapper>
          {media.map(m => (
            <Link to={projectUrl(`/files/${m.id}`)} key={`me${m.id}`}>
              <ImageContainer>
                <Image src={m.url192} />
              </ImageContainer>
            </Link>
          ))}
        </MediaWrapper>
      </Wrapper>
    );
  }
}

export default compose(
  withProjects,
  withMedia,
  mapProps(({ data, ...rest }) => ({
    media: data.media || [],
    ...data,
    ...rest,
  })),
)(FilesView);
