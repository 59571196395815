import React, { useState } from 'react';
import { compose, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select as _Select } from '@blueprintjs/select';

const Select = styled(_Select)`
  .bp3-popover-content {
    padding: 5px;
    max-height: 200px;
  }
`;

export default compose(
  setPropTypes({
    resourceName: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    nameProp: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    variables: PropTypes.object,
    selected: PropTypes.string,
    onLoad: PropTypes.func,
  }),
)(
  ({
    query,
    resourceName,
    variables = {},
    nameProp = 'id',
    selectItems: selectItemsProp,
    renderItem: renderItemProp,
    renderItemText,
    onSelect,
    selected,
    onLoad = () => {},
    ...props
  }) => {
    const [isOnLoadEmitted, setIsOnLoadEmitted] = useState(false);
    const selectItems = (data, loading) =>
      !loading && data ? selectItemsProp?.(data) || data.items : [];
    const maybeEmitOnLoad = data => {
      if (isOnLoadEmitted) return;
      setIsOnLoadEmitted(true);
      const items = selectItems(data, false);

      onLoad(
        items,
        items.find(item => item.id === selected),
      );
    };
    const renderItem = renderItemProp || (i => renderItemText?.(i) || i[nameProp]);
    return (
      <Query query={query} variables={variables} onCompleted={data => maybeEmitOnLoad(data)}>
        {({ data, loading }) => {
          const items = selectItems(data, loading);
          return (
            <Select
              filterable
              items={items}
              itemPredicate={(query, item) =>
                (item[nameProp] || '').toLowerCase().indexOf((query || '').toLowerCase()) >= 0
              }
              noResults={<MenuItem disabled text="No results." />}
              activeItem={selected}
              itemRenderer={(i, { handleClick }) => (
                <MenuItem
                  key={`resource-select-menu-item-${i.id}`}
                  text={renderItem(i, {
                    modifiers: { active: selected === i.id },
                  })}
                  onClick={handleClick}
                  active={selected === i.id}
                />
              )}
              onItemSelect={i => onSelect(i)}
            >
              <Button
                text={(() => {
                  const res = items.find(i => i.id === selected);
                  return res ? res[nameProp] : `Select ${resourceName}`;
                })()}
                rightIcon="caret-down"
                loading={loading}
                disabled={loading}
                {...props}
              />
            </Select>
          );
        }}
      </Query>
    );
  },
);
