import gql from 'graphql-tag';
import useData from './useData';
import { AppFields } from '../data/fragments';

const APPS_QUERY = gql`
  query Apps {
    apps {
      ...AppFields
    }
  }
  ${AppFields}
`;

export default function useApps() {
  const { data } = useData(APPS_QUERY);
  return data;
}
