import React from 'react';
import PropTypes from 'prop-types';
import Debug from '/components/Debug';
import Tabs from '/components/Tabs';
import Apps from './Apps';
import ThemeSettings from './ThemeSettings';
import CommerceSettings from './CommerceSettings/CommerceSettings';
import MenuSettings from './MenuSettings';
import IntegrationSettings from './IntegrationSettings';
import EmailSettings from './EmailSettings';
import SchemaSettings from './SchemaSettings';
import TranslationSettings from './TranslationSettings';
import I18nSettings from './I18nSettings';
import WebhookSettings from './WebhookSettings';

export default class ProjectSettings extends React.Component {
  static propTypes = {
    settings: PropTypes.object,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    settings: {
      i18n: {},
      stripe: {},
      mailchimp: {},
      emails: {},
    },
  };

  constructor(props) {
    super();
    this.state = { settings: props.settings };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.settings.commerce.currencySettings.currencyId !==
      prevState.settings.commerce.currencySettings.currencyId
    ) {
      return {
        settings: {
          ...prevState.settings,
          commerce: nextProps.settings.commerce,
        },
      };
    }
    return null;
  }

  debouncedSave = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.props.onSave(this.state.settings), 2000);
  };

  setSettingsState = v =>
    this.setState(({ settings }) => ({ settings: { ...settings, ...v } }), this.debouncedSave);

  handleEmailSettingsChange = v => this.setSettingsState({ emails: v });

  handleI18nSettingsChange = v => this.setSettingsState({ i18n: v });

  handleIntegrationSettingsChange = ({ stripe, mailchimp }) =>
    this.setSettingsState({ stripe, mailchimp });

  render() {
    const {
      settings: { emails, mailchimp, commerce, i18n },
    } = this.state;

    return (
      <>
        <Tabs
          titles={[
            'Apps',
            'Themes',
            'Menus',
            'Languages',
            'Commerce',
            'Integrations',
            'Emails',
            'Webhooks',
            'Schemas',
          ]}
          routed
        >
          <Apps i18n={i18n} />
          <ThemeSettings />
          <MenuSettings />
          <Tabs titles={['Project Languages', 'Translations']}>
            <I18nSettings
              i18n={i18n}
              onChange={this.handleI18nSettingsChange}
              availableLocales={this.props.availableLocales}
            />

            <TranslationSettings i18n={i18n} />
          </Tabs>
          <CommerceSettings settings={commerce} refetch={this.props.refetch} />
          <IntegrationSettings
            settings={{ mailchimp }}
            onChange={this.handleIntegrationSettingsChange}
          />
          <EmailSettings
            templates={this.props.availableEmailTemplates}
            settings={emails}
            onChange={this.handleEmailSettingsChange}
          />
          <WebhookSettings />
          <SchemaSettings />
        </Tabs>
        <Debug data={this.state.settings} />
      </>
    );
  }
}
