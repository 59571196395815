import React from 'react';

export default [
  {
    id: 'webFontConfig',
    title: 'Web Font Config',
    description: () => (
      <>
        Webfont config for webfont loader. See information about usage{' '}
        <a href="https://github.com/typekit/webfontloader" target="_blank">
          here
        </a>
        . Should be{' '}
        <a href="https://www.w3schools.com/js/js_json_syntax.asp" target="_blank">
          correctly formatted JSON
        </a>
        .
      </>
    ),
    type: 'json',
    group: 'Text',
    parent: null,
    defaultValue: '{"google":{"families":["Source Sans Pro:400,700","Poppins:700"]}}',
  },
  {
    id: 'textFontFamily',
    title: 'Font Family Text',
    description: () => (
      <>
        The font family for normal text. You may need to refer to where you source your fonts
        (Google, Adobe, etc) for the correct name.
      </>
    ),
    type: 'string',
    group: 'Text',
    parent: null,
    defaultValue: '"Source Sans Pro", sans-serif',
  },
  {
    id: 'headerFontFamily',
    title: 'Font Family Header',
    description: () => (
      <>
        The font family for header text. You may need to refer to where you source your fonts
        (Google, Adobe, etc) for the correct name.
      </>
    ),
    type: '',
    group: 'Text',
    parent: null,
    defaultValue: '"Poppins", sans-serif',
  },
  {
    id: 'baseFontSize',
    title: 'Font Size',
    description: () => (
      <>
        Font size for text for screens larger than tablet sizes. Header font sizes are calculated
        from this base font size.
      </>
    ),
    type: 'string',
    group: 'Text',
    parent: null,
    defaultValue: '18px',
  },
  {
    id: 'baseTabletFontSize',
    title: 'Font Size Tablet',
    description: () => (
      <>
        Font size for text on tablet size screens. Header font sizes are calculated from this base
        font size.
      </>
    ),
    type: 'string',
    group: 'Text',
    parent: 'baseFontSize',
    defaultValue: '16px',
  },
  {
    id: 'baseMobileFontSize',
    title: 'Font Size Mobile',
    description: () => (
      <>
        Font size for text on mobile size screens. Header font sizes are calculated from this base
        font size.
      </>
    ),
    type: 'string',
    group: 'Text',
    parent: null,
    defaultValue: '14px',
  },
  {
    id: 'textColor',
    title: 'Text Color',
    description: () => <>The color of text (on light backgrounds).</>,
    type: 'color',
    group: 'Text',
    parent: null,
    defaultValue: '#212121',
  },
  {
    id: 'textColorDark',
    title: 'Text Color Dark',
    description: () => <>The color of text (on dark backgrounds).</>,
    type: 'color',
    group: 'Text',
    parent: null,
    defaultValue: '#FFFFFF',
  },
  {
    id: 'headingColor',
    title: 'Heading Color',
    description: () => (
      <>
        The text color of{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-heading--default"
          target="_blank"
        >
          Headings
        </a>
        .
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'headingColorDark',
    title: 'Heading Color Dark',
    description: () => (
      <>
        The text color of{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-heading--default"
          target="_blank"
        >
          Headings
        </a>
        for use on dark backgrounds.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'textColorDark',
    defaultValue: null,
  },
  {
    id: 'headingScaleRatio',
    title: 'Heading Scale Ratio',
    description: () => <>The ratio used to calculate heading sizes. This is a modular scale.</>,
    type: 'text',
    group: 'Text',
    parent: null,
    defaultValue: '1.25',
  },
  {
    id: 'uppercaseTitles',
    title: 'Heading Uppercase Titles',
    description: () => <>Determines whether titles should be transformed to uppercaase.</>,
    type: 'boolean',
    group: 'Text',
    parent: null,
    defaultValue: false,
  },
  {
    id: 'textLetterSpacing',
    title: 'Letter Spacing',
    description: () => <>Text letter spacing.</>,
    type: 'string',
    group: 'Text',
    parent: null,
    defaultValue: '0',
  },
  {
    id: 'textLineHeight',
    title: 'Line Height',
    description: () => <>Text line height.</>,
    type: 'string',
    group: 'Text',
    parent: null,
    defaultValue: '1.5',
  },
  {
    id: 'linkStyle',
    title: 'Link Style',
    description: () => (
      <>
        The style of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-link--line-grow"
          target="_blank"
        >
          Link
        </a>{' '}
        component throughout your app. Used for most links in texts.
      </>
    ),
    type: 'select',
    group: 'Text',
    parent: null,
    defaultValue: 'lineGrow',
    options: [
      { value: 'basic', text: 'Basic' },
      { value: 'lineGrow', text: 'Line Grow' },
      { value: 'solidBackground', text: 'Solid Background' },
    ],
  },
  {
    id: 'linkPrimaryColor',
    title: 'Link Primary Color',
    description: () => (
      <>
        The primary color for the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-link--line-grow"
          target="_blank"
        >
          Link
        </a>{' '}
        component throughout your app. Used for most links in texts.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'linkSecondaryColor',
    title: 'Link Secondary Color',
    description: () => (
      <>
        The secondary color for the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-link--line-grow"
          target="_blank"
        >
          Link
        </a>{' '}
        component throughout your app. Used for most links in texts.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'secondaryColor',
    defaultValue: null,
  },
  {
    id: 'listItemSpacing',
    title: 'List Item Spacing',
    description: () => (
      <>
        The spacing between items in the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--ordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component.
      </>
    ),
    type: 'text',
    group: 'Text',
    parent: null,
    defaultValue: '0px',
  },
  {
    id: 'listMarkerColor',
    title: 'List Marker Color',
    description: () => (
      <>
        The color for the markers of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--ordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component, unless overriden specifically for the ordered or unordered list.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'listTextColor',
    title: 'List Text Color',
    description: () => (
      <>
        The text color for all instances of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--ordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component, unless overriden specifically for the ordered or unordered list.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'orderedListMarkerColor',
    title: 'Ordered List Marker Color',
    description: () => (
      <>
        The color for the markers of the ordered variant of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--ordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'listMarkerColor',
    defaultValue: null,
  },
  {
    id: 'orderedListTextColor',
    title: 'Ordered List Text Color',
    description: () => (
      <>
        The text color for the ordered variant of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--ordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'listTextColor',
    defaultValue: null,
  },
  {
    id: 'unorderedListMarkerColor',
    title: 'Unordered List Marker Color',
    description: () => (
      <>
        The color for the markers of the unordered variant of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--unordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'listMarkerColor',
    defaultValue: null,
  },
  {
    id: 'unorderedListTextColor',
    title: 'Unordered List Text Color',
    description: () => (
      <>
        The text color for the unordered variant of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-list--unordered-list"
          target="_blank"
        >
          List
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Text',
    parent: 'listTextColor',
    defaultValue: null,
  },
];
