import React from 'react';
import styled from 'styled-components';
import { Button, Intent } from '@blueprintjs/core';
import Input from '/components/Input';
import MediaInput from '/components/MediaInput';
import TextArea from '/components/TextArea';

const Actions = styled.div`
  margin-right: 0;
`;

export default ({ payload = {}, onChange, onMoveUp, onMoveDown, onRemove }) => (
  <div>
    <hr />
    <Input
      onChange={e => onChange({ ...payload, name: e.target.value })}
      placeholder="Namey McNameface"
      value={payload.name}
      labelText="Name"
      className="bp3-large bp3-fill"
    />
    <TextArea
      placeholder=""
      value={payload.description && payload.description.text}
      onChange={e => onChange({ ...payload, description: { text: e.target.value } })}
      labelText="Description"
    />
    <Input
      onChange={e =>
        onChange({
          ...payload,
          profiles: {
            ...(payload.profiles || {}),
            website: { url: e.target.value },
          },
        })
      }
      placeholder="https://example.com"
      value={(payload.profiles && payload.profiles.website && payload.profiles.website.url) || ''}
      labelText="Website URL"
      className="bp3-large bp3-fill"
    />
    <Input
      onChange={e =>
        onChange({
          ...payload,
          profiles: {
            ...(payload.profiles || {}),
            facebook: { url: e.target.value },
          },
        })
      }
      placeholder="https://www.facebook.com"
      value={(payload.profiles && payload.profiles.facebook && payload.profiles.facebook.url) || ''}
      labelText="Facebook profile URL"
      className="bp3-large bp3-fill"
    />
    <Input
      onChange={e =>
        onChange({
          ...payload,
          profiles: {
            ...(payload.profiles || {}),
            linkedIn: { url: e.target.value },
          },
        })
      }
      placeholder="https://www.linkedin.com"
      value={(payload.profiles && payload.profiles.linkedIn && payload.profiles.linkedIn.url) || ''}
      labelText="LinkedIn profile URL"
      className="bp3-large bp3-fill"
    />
    <Input
      onChange={e =>
        onChange({
          ...payload,
          profiles: {
            ...(payload.profiles || {}),
            twitter: { username: e.target.value },
          },
        })
      }
      placeholder="example_username"
      value={
        (payload.profiles && payload.profiles.twitter && payload.profiles.twitter.username) || ''
      }
      labelText="Twitter username"
      className="bp3-large bp3-fill"
    />
    <Input
      onChange={e =>
        onChange({
          ...payload,
          profiles: {
            ...(payload.profiles || {}),
            instagram: { username: e.target.value },
          },
        })
      }
      placeholder="example_username"
      value={
        (payload.profiles && payload.profiles.instagram && payload.profiles.instagram.username) ||
        ''
      }
      labelText="Instagram username"
      className="bp3-large bp3-fill"
    />
    <MediaInput
      mediaId={payload.image && payload.image._mediaId}
      onChange={m =>
        onChange({
          ...payload,
          image: { _mediaId: m.id, src: m.src, url: m.url },
        })
      }
    />
    <Actions>
      <Button onClick={onMoveUp} icon="arrow-up">
        Up
      </Button>
      <Button onClick={onMoveDown} icon="arrow-down">
        Down
      </Button>
      <Button intent={Intent.DANGER} onClick={onRemove} icon="delete">
        Remove
      </Button>
    </Actions>
  </div>
);
