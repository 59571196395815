import { Intent, Position, Toaster as BToaster } from '@blueprintjs/core';

class Toaster {
  toaster = BToaster.create({
    className: 'wings-toaster',
    position: Position.BOTTOM_RIGHT,
  });

  show = _opts => {
    const opts = typeof _opts === 'string' ? { message: _opts } : _opts;
    const { intent = Intent.PRIMARY, ...rest } = opts;
    return this.toaster.show({ intent, ...rest });
  };

  warning = message => this.show({ message, intent: Intent.WARNING });
  danger = message => this.show({ message, intent: Intent.DANGER });

  articleDeleted() {
    this.show({
      message: 'Article deleted.',
    });
  }
  entryDeleted() {
    this.show({
      message: 'Entry deleted.',
    });
  }

  loginSucceeded = () => this.show('Welcome 👋');
}

export default new Toaster();
