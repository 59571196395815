import React from 'react';
import { Breadcrumb, CollapsibleList, MenuItem, Classes } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';

const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export default compose(withRouter)(({ items = [], history, ...props }) => (
  <Wrapper {...props}>
    <CollapsibleList
      className="bp3-breadcrumbs"
      dropdownTarget={<span className={Classes.BREADCRUMBS_COLLAPSED} />}
      visibleItemRenderer={_props => <Breadcrumb {..._props} />}
    >
      {items.map(item => (
        <MenuItem
          href={item.url}
          onClick={e => {
            e.preventDefault();
            history.push(item.url);
          }}
          key={`menuitem${item.text}`}
          text={item.text}
          disabled={!item.url}
        />
      ))}
    </CollapsibleList>
  </Wrapper>
));
