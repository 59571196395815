import React from 'react';
import gql from 'graphql-tag';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import TabularResource from '/components/TabularResource';
import { ThemeFields, ThemeMutable } from '/data/fragments';
import ThemeProperties from './ThemeProperties';

const THEMES = gql`
  ${ThemeFields}
  query Themes {
    items: themes {
      ...ThemeFields
    }
  }
`;

const CREATE = gql`
  ${ThemeFields}
  mutation CreateTheme($input: CreateWebAppThemeInput!) {
    createWebAppTheme(input: $input) {
      theme {
        ...ThemeFields
      }
    }
  }
`;

const UPDATE = gql`
  ${ThemeFields}
  mutation UpdateTheme($input: UpdateWebAppThemeInput!) {
    updateWebAppTheme(input: $input) {
      theme {
        ...ThemeFields
      }
    }
  }
`;

const DELETE = gql`
  ${ThemeFields}
  mutation DeleteTheme($input: DeleteWebAppThemeInput!) {
    deleteWebAppTheme(input: $input) {
      theme {
        ...ThemeFields
      }
    }
  }
`;

export default function ThemesView() {
  return (
    <TabularResource
      resourceName="Theme"
      tabInfo={theme => ({
        title: theme.name,
      })}
      mutableFragment={ThemeMutable}
      queries={{
        create: {
          query: CREATE,
          variables: { input: { theme: { name: 'My Theme', properties: [] } } },
        },
        read: { query: THEMES },
        update: {
          query: UPDATE,
          variables: (id, variables) => ({
            input: {
              id,
              theme: {
                ...variables,
              },
            },
          }),
        },
        delete: { query: DELETE, variables: id => ({ input: { id } }) },
      }}
    >
      {(theme, { onChange }) => (
        <div>
          <FormGroup
            helperText="A good name helps you identify this theme (example: Light Theme)"
            label="Name"
            labelFor="name-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="name-input"
              value={theme.name || ''}
              onChange={e => onChange({ ...theme, name: e.target.value })}
            />
          </FormGroup>
          <hr />
          <ThemeProperties
            value={theme.properties}
            onChange={v => onChange({ ...theme, properties: v })}
          />
        </div>
      )}
    </TabularResource>
  );
}
