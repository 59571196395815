import React from 'react';
import Input from '../Input';
import filterProps from 'filter-invalid-dom-props';

export default class LocationInput extends React.Component {
  handleChange = field => e => {
    const value = e.target ? e.target.value : e;
    this.props.onChange({
      ...this.props.value,
      [field]: value,
    });
  };
  render() {
    const {
      value = {},
      onChange, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;
    return (
      <div {...filterProps(props)}>
        <Input
          placeholder="Name"
          onChange={this.handleChange('name')}
          value={value.name}
          className="bp3-fill"
        >
          Name
        </Input>
        <Input
          placeholder="Street"
          onChange={this.handleChange('street')}
          value={value.street}
          className="bp3-fill"
        >
          Street
        </Input>
        <Input
          placeholder="City"
          onChange={this.handleChange('city')}
          value={value.city}
          className="bp3-fill"
        >
          City
        </Input>
        <Input
          placeholder="Zipcode"
          onChange={this.handleChange('zip')}
          value={value.zip}
          className="bp3-fill"
        >
          Zipcode
        </Input>
        <Input
          placeholder="Country"
          onChange={this.handleChange('country')}
          value={value.country}
          className="bp3-fill"
        >
          Country
        </Input>
      </div>
    );
  }
}
