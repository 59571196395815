import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable as _HTMLTable, Button } from '@blueprintjs/core';
import { compose, setPropTypes, setStatic } from 'recompose';
import styled from 'styled-components';

const HTMLTable = styled(_HTMLTable)`
  width: 100%;
`;

const ContentView = compose(
  setPropTypes({
    fields: PropTypes.arrayOf(PropTypes.node),
    children: PropTypes.node,
    list: PropTypes.bool,
  }),
  setStatic('defaultProps', {
    fields: [],
    children: null,
    list: false,
  }),
)(({ fields, children, onCreate }) => {
  const handleOnCreate = e => {
    e.stopPropagation();
    onCreate();
  };

  return (
    <HTMLTable interactive striped small>
      <colgroup>
        <col width="100" />
      </colgroup>
      <thead>
        <tr>
          {fields.map((h, i) => (
            <th key={`ContentViewHeader${i}`}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {children}
        {onCreate && (
          <tr onClick={handleOnCreate}>
            <td />
            <td>
              <Button onClick={handleOnCreate}>Create</Button>
            </td>
            {[...new Array(fields.length - 2)].map((_, i) => (
              <td key={`contentview-col-${i}`} />
            ))}
          </tr>
        )}
      </tbody>
    </HTMLTable>
  );
});

export default ContentView;

ContentView.Item = ({ children, ...props }) => <tr {...props}>{children}</tr>;
ContentView.Field = ({ children, ...props }) => <td {...props}>{children}</td>;
