import React, { useState, useEffect } from 'react';
import { useAuth } from '/hooks';

export default function EnsureLoggedIn({ children, component: Loading }) {
  const [loggedIn, setLoggedIn] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    const interval = setInterval(async () => {
      const loggedIn = await auth.loggedIn();
      if (loggedIn === false) auth.login();
      setLoggedIn(loggedIn);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  });

  if (loggedIn === null) return Loading ? <Loading fill /> : 'loading';

  return children;
}
