import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Tooltip,
  Intent,
  Dialog,
  Classes,
  Menu,
  Divider,
  Popover,
  PopoverPosition,
  Position,
  Icon,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import toaster from '/lib/toaster';
import App from '/lib/models/App';
import Debug from '/components/Debug';
import withRouter from '/containers/withRouter';
import PlatformsSettings from './PlatformsSettings';
import CopySettings from './CopySettings';
import GeneralSettings from './GeneralSettings';
import MetaSettings from './MetaSettings';
import AppSettings from './AppSettings';
import SchemaSettings from './SchemaSettings';
import useApps from '/hooks/useApps';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class NodeEditor extends Component {
  static defaultProps = {
    tabs: [],
  };

  static getDerivedStateFromProps({ node }, { node: nodeState }) {
    if (!nodeState || node.updatedAt !== nodeState.updatedAt) return { node };
    return { node: nodeState };
  }

  state = { showDeleteDialog: false, unsavedChanges: false };

  ensureNavigationPrompt = () => {
    if (this.unblockNavPrompt) return;
    this.unblockNavPrompt = this.props.history.block(
      'You will lose any pending changes, are you sure?',
    );
  };

  clearNavigationPrompt = () => this.unblockNavPrompt && this.unblockNavPrompt();

  componentWillUnmount() {
    this.clearNavigationPrompt();
  }

  setNodeState = (n, cb) => this.setState(({ node }) => ({ node: { ...node, ...n } }), cb);

  setUnsavedChanges = state => {
    this.setState({ unsavedChanges: state }, () =>
      state ? this.ensureNavigationPrompt() : this.clearNavigationPrompt(),
    );
  };

  handleFieldChange = f => (v, cb = v => v) => {
    this.setNodeState(
      {
        [f]: v,
      },
      () => {
        this.setUnsavedChanges(true);
        cb();
      },
    );
  };

  handleDataChange = key => data => {
    this.handleFieldChange('data')(
      this.state.node.data.filter(({ key: k }) => k !== key).concat([{ key, data }]),
    );
  };

  dataForKey = key => {
    const item = this.state.node.data?.find(({ key: k }) => k === key);
    return item?.data || {};
  };

  handleDeleteClick = () => this.setState({ showDeleteDialog: true });
  handleDeleteDialogClose = () => this.setState({ showDeleteDialog: false });

  async save(node) {
    try {
      await this.props.onSave(node);
      toaster.show('Updated');
      this.setUnsavedChanges(false);
    } catch (err) {
      console.error(err);
      toaster.warning('Something went wrong');
    }
  }

  async delete() {
    try {
      await this.props.onDelete();
      toaster.show('Deleted');
    } catch (err) {
      console.error(err);
      toaster.warning('Something went wrong');
    }
  }

  render() {
    const { saving, deleting, children, locales, apps = [] } = this.props;
    const { node, showDeleteDialog, unsavedChanges } = this.state;
    const schemas = node.schemas.filter(s => !!s.definition);
    const isViewable = !(node.status === 'DRAFT');
    return (
      <div>
        <Dialog
          icon="warning-sign"
          onClose={this.handleDeleteDialogClose}
          title="Delete"
          isOpen={showDeleteDialog}
        >
          <div className={Classes.DIALOG_BODY}>Are you sure?</div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.handleDeleteDialogClose}>Cancel</Button>
              <Button
                intent={Intent.DANGER}
                onClick={() => {
                  this.clearNavigationPrompt();
                  this.delete();
                }}
                loading={deleting}
              >
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
        <Debug data={{ node, locales }} />
        <Tabs id="node-options-tabs">
          <Tab
            id="node-options-tab-general"
            title="General"
            panel={
              <GeneralSettings
                locales={locales}
                currentLocale={node.locale}
                title={node.title}
                slug={node.slug}
                inheritedSlug={node.inheritedSlug}
                image={node.image || {}}
                onTitleChange={this.handleFieldChange('title')}
                onSlugChange={this.handleFieldChange('slug')}
                onLocaleChange={this.handleFieldChange('locale')}
                onImageChange={m => {
                  this.handleFieldChange('image')(m);
                }}
              />
            }
          />
          <Tab
            id="node-options-tab-copy"
            title="Copy"
            panel={
              <CopySettings
                copyOverrides={node.copyOverrides}
                resourceType={node.resourceType}
                onCopyChange={this.handleFieldChange('copyOverrides')}
                localeId={node.locale.id}
              />
            }
          />
          <Tab
            id="node-options-tab-platforms"
            title="Platforms"
            panel={
              <PlatformsSettings node={node} onChange={f => v => this.handleFieldChange(f)(v)} />
            }
          />
          <Tab
            id="node-options-tab-meta"
            title="Meta"
            panel={<MetaSettings meta={node.meta} onChange={this.handleFieldChange('meta')} />}
          />
          <Tab
            id="node-options-tab-apps"
            title="Apps"
            panel={<AppSettings settings={node.apps} onChange={this.handleFieldChange('apps')} />}
          />
          {!!schemas.length && <Divider style={{ height: 16 }} />}
          {schemas.map(schema => (
            <Tab
              key={`node-options-tab-apps-${schema.key}`}
              id={`node-options-tab-apps-${schema.key}`}
              title={schema.name}
              panel={
                <SchemaSettings
                  definition={schema.definition}
                  data={this.dataForKey(schema.key)}
                  onChange={this.handleDataChange(schema.key)}
                />
              }
            />
          ))}
          <Tabs.Expander />
          <Actions>
            <ButtonGroup>
              <Popover
                disabled={!isViewable}
                enforceFocus={false}
                position={PopoverPosition.BOTTOM}
                content={
                  <Menu>
                    <div>
                      {apps
                        .map(app => new App(app))
                        .filter(app => app.url())
                        .map(app => {
                          return (
                            <Menu.Item
                              href={app.url(node)}
                              target="_blank"
                              labelElement={<Icon icon="share" />}
                              text={app.app.name}
                            />
                          );
                        })}
                    </div>
                  </Menu>
                }
              >
                <Tooltip content="View in app" position={Position.BOTTOM} disabled={!isViewable}>
                  <Button
                    disabled={!isViewable}
                    loading={saving}
                    intent={Intent.NONE}
                    icon="application"
                    rightIcon="chevron-down"
                  />
                </Tooltip>
              </Popover>
              <Popover
                enforceFocus={false}
                position={PopoverPosition.BOTTOM}
                content={
                  <DatePicker
                    label="Date"
                    value={node.publishedAt ? new Date(node.publishedAt) : new Date()}
                    onChange={selectedDate =>
                      this.handleFieldChange('publishedAt')(selectedDate.valueOf())
                    }
                    timePrecision
                    maxDate={new Date()}
                  />
                }
              >
                <Tooltip content="Change publish date" position={Position.BOTTOM}>
                  <Button
                    loading={saving}
                    intent={node.status === 'DRAFT' ? Intent.NONE : Intent.WARNING}
                    icon="calendar"
                  />
                </Tooltip>
              </Popover>
              <Button
                text={node.status === 'DRAFT' ? 'Publish' : 'Unpublish'}
                intent={node.status === 'DRAFT' ? Intent.PRIMARY : Intent.WARNING}
                onClick={() => {
                  this.clearNavigationPrompt();
                  const state =
                    node.status === 'DRAFT'
                      ? {
                          publishedAt: node.publishedAt || Date.now(),
                          status: 'PUBLISHED',
                        }
                      : {
                          status: 'DRAFT',
                        };
                  this.setNodeState(state, () => {
                    this.save(this.state.node);
                  });
                }}
                loading={saving}
              />
            </ButtonGroup>
            <ButtonGroup style={{ marginLeft: '8px' }}>
              <Button
                onClick={() => {
                  this.clearNavigationPrompt();
                  this.save(node);
                }}
                disabled={!unsavedChanges}
                loading={saving}
                text="Save"
                intent={Intent.SUCCESS}
                icon="floppy-disk"
              />
              <Button
                text="Delete"
                intent={Intent.DANGER}
                icon="trash"
                onClick={this.handleDeleteClick}
                loading={deleting}
              />
            </ButtonGroup>
          </Actions>
        </Tabs>
        {children({
          updateField: this.handleFieldChange,
          node,
        })}
      </div>
    );
  }
}

const withApps = Component => props => {
  const apps = useApps();
  return <Component apps={apps?.apps || []} {...props} />;
};

export default withRouter(withApps(NodeEditor));
