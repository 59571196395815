import React, { Component } from 'react';
import filterProps from 'filter-invalid-dom-props';
import styled from 'styled-components';
import { FormGroup, InputGroup, Colors } from '@blueprintjs/core';
import MenuItems from './MenuItems';

const Wrapper = styled.div`
  width: 100%;
`;

const ItemsWrapper = styled.div`
  background-color: ${Colors.GRAY5};
  padding: 16px 16px 0;
`;

export default class Menu extends Component {
  static propTypes = {};
  constructor({ menu = {} }) {
    super();
    this.state = {
      menu,
    };
  }

  setMenuState = state =>
    this.setState(
      ({ menu: m }) => ({ menu: { ...m, ...state } }),
      () => this.props.onChange(this.state.menu),
    );

  render() {
    const {
      onChange, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;
    const { menu } = this.state;
    return (
      <Wrapper {...filterProps(props)}>
        <FormGroup
          helperText="a good name helps you identify this menu (example: Main Navigation)"
          label="Name"
          labelFor="name-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="name-input"
            value={menu.name}
            onChange={e => this.setMenuState({ name: e.target.value })}
          />
        </FormGroup>
        <hr />
        <ItemsWrapper>
          <MenuItems items={menu.items} onChange={items => this.setMenuState({ items })} />
        </ItemsWrapper>
      </Wrapper>
    );
  }
}
