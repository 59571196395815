import React from 'react';
import { randomId } from '/lib/utils';
import classNames from 'classnames';

export default ({ placeholder, value, onChange, children, labelText, className }) => {
  const id = `input-${randomId()}`;
  return (
    <label className="bp3-label" htmlFor={id}>
      {children || labelText}
      <input
        className={classNames('bp3-input', className)}
        id={id}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </label>
  );
};
