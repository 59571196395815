import React from 'react';
import classNames from 'classnames';
import Card from '/components/Card';
import styled from 'styled-components';

const SettingsCard = styled(Card)`
  margin: 12px 0;
  background-image: url("${({ backgroundImage }) => (!backgroundImage ? '' : backgroundImage)}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  min-height: ${({ backgroundImage }) => (!backgroundImage ? 'initial' : '400px')};
`;

export default ({ className, ...props }) => (
  <SettingsCard className={classNames(className, 'bp3-dark')} {...props} />
);
