import React from 'react';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import gql from 'graphql-tag';
import TabularResource from '/components/TabularResource';
import { SchemaFields, SchemaMutable } from '/data/fragments';
import SchemaEditor from './SchemaEditor';
import wizard from '/lib/wizard';

const CREATE = gql`
  ${SchemaFields}
  mutation CreateSchema($input: CreateSchemaInput!) {
    createSchema(input: $input) {
      schema {
        ...SchemaFields
      }
    }
  }
`;

const UPDATE = gql`
  ${SchemaFields}
  mutation UpdateSchema($input: UpdateSchemaInput!) {
    updateSchema(input: $input) {
      schema {
        ...SchemaFields
      }
    }
  }
`;

const DELETE = gql`
  ${SchemaFields}
  mutation DeleteSchema($input: DeleteSchemaInput!) {
    deleteSchema(input: $input) {
      schema {
        ...SchemaFields
      }
    }
  }
`;

const READ = gql`
  ${SchemaFields}
  query Schemas {
    items: schemas {
      ...SchemaFields
    }
  }
`;

const SchemaSettings = () => (
  <TabularResource
    resourceName="Schema"
    tabInfo={schema => ({ title: schema.name })}
    mutableFragment={SchemaMutable}
    queries={{
      create: {
        query: CREATE,
        variables: async () => {
          const [{ name, key }] = await wizard.init({
            title: 'Add Schema',
            steps: [
              {
                title: 'Info',
                schema: {
                  type: 'object',
                  required: ['name', 'key'],
                  properties: {
                    name: { type: 'string' },
                    key: { type: 'string' },
                  },
                },
              },
            ],
          });
          return {
            input: { schema: { name, key, locations: [] } },
          };
        },
      },
      read: { query: READ },
      update: {
        query: UPDATE,
        variables: (id, { ...schema }) => ({
          input: {
            id,
            schema,
          },
        }),
      },
      delete: {
        query: DELETE,
        variables: id => ({ input: { id } }),
      },
    }}
  >
    {(schema, { onChange }) => <SchemaEditor value={schema} onChange={onChange} />}
  </TabularResource>
);

export default compose(setDisplayName('SchemaSettings'), setPropTypes({}))(SchemaSettings);
