import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { randomId } from '/lib/utils';
import Input from '/components/Input';
import Select from '/components/Select';
import Collection from './Collection';

import { createCard } from '@wingsplatform/react';
import CollectionCardView from './CollectionCardView';
import withClickableHighlight from '/styles/withClickableHighlight';
import { Dialog as _Dialog, Tabs, Tab } from '@blueprintjs/core';
import DialogStyle from '/styles/dialog';
import PersonEditor from './PersonEditor';
import OrgEditor from './OrgEditor';

const tabId = () => `tab-${randomId()}`;

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

const PEOPLE_EXAMPLE = {
  type: CollectionCardView.TYPE.PEOPLE,
  title: 'My People Collection',
  items: [
    // {
    //   name: 'Mister Example',
    //   profiles: {
    //     website: {
    //       url: 'https://example.com',
    //     },
    //     facebook: {
    //       url: 'https://facebook.com/example',
    //     },
    //     linkedIn: {
    //       url: 'https://www.linkedin.com/in/example/',
    //     },
    //     twitter: {
    //       username: 'example',
    //     },
    //     instagram: {
    //       username: 'example',
    //     },
    //   },
    //   image: {
    //     _mediaId: 'bmtoc0xmw0cmbr',
    //     src: 'http://via.placeholder.com/350x150.png',
    //     url: 'http://via.placeholder.com/350x150.png',
    //   },
    // },
  ],
};

// const ORG_EXAMPLE = {
//   type: CollectionCardView.TYPE.ORG,
//   title: 'My Org Collection',
//   description: {
//     text:
//       'This is the description. This is the description. This is the description. This is the description. ',
//   },
//   items: [
//     {
//       name: 'Example Corp',
//       profiles: {
//         website: {
//           url: 'https://example.com',
//         },
//         facebook: {
//           url: 'https://facebook.com/example',
//         },
//         linkedIn: {
//           url: 'https://www.linkedin.com/in/example/',
//         },
//         twitter: {
//           username: 'username',
//         },
//         instagram: {
//           username: 'username',
//         },
//       },
//       image: {
//         _mediaId: 'bmtoc0xmw0cmbr',
//         src: 'http://via.placeholder.com/350x150.png',
//         url: 'http://via.placeholder.com/350x150.png',
//       },
//     },
//   ],
// };

class CollectionCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    payload: this.props.payload,
  };

  generalTabId = tabId();
  collectionTabId = tabId();
  tabGroupId = tabId();
  editorId = tabId();

  eventToPayloadState = key => e => {
    const { value } = e.target;
    this.setPayloadState({ [key]: value });
  };

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));

  save = () => this.props.save(this.state.payload);

  render() {
    const { title, type, items } = this.state.payload;

    return (
      <Fragment>
        <CollectionCardView {...this.state.payload} />
        <Dialog
          title="Collection"
          icon="grid-view"
          isOpen
          onClose={this.save}
          canOutsideClickClose={false}
        >
          <div className="bp3-dialog-body">
            <Tabs id={this.tabGroupId}>
              <Tab
                id={this.generalTabId}
                title="General"
                panel={
                  <Fragment>
                    <Input
                      name="title"
                      placeholder="Title"
                      onChange={this.eventToPayloadState('title')}
                      value={title}
                      className="bp3-fill"
                    >
                      Title <span className="bp3-text-muted">(optional)</span>
                    </Input>
                    <Select
                      labelText="Collection type"
                      items={[
                        {
                          value: CollectionCardView.TYPE.PEOPLE,
                          text: 'People',
                        },
                        {
                          value: CollectionCardView.TYPE.ORG,
                          text: 'Organizations',
                        },
                      ]}
                      value={type}
                      onChange={e =>
                        this.setPayloadState({
                          type: parseInt(e.target.value, 10),
                          items: [],
                        })
                      }
                    />
                  </Fragment>
                }
              />
              <Tab
                id={this.collectionTabId}
                title="Items"
                panel={
                  <Collection
                    id={this.editorId}
                    items={items}
                    onChange={v => this.setPayloadState({ items: v })}
                    newItem={{}}
                    renderItem={({ payload, onChange, onMoveUp, onMoveDown, onRemove }) => {
                      const Editor =
                        type === CollectionCardView.TYPE.PEOPLE ? PersonEditor : OrgEditor;
                      return (
                        <Editor
                          payload={payload}
                          onChange={onChange}
                          onMoveUp={onMoveUp}
                          onMoveDown={onMoveDown}
                          onRemove={onRemove}
                        />
                      );
                    }}
                  />
                }
              />
            </Tabs>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default createCard({
  name: 'CollectionCard',
  renderWith: withClickableHighlight(CollectionCardView),
  editWith: CollectionCardEditor,
  buttonText: 'Collection',
  payload: PEOPLE_EXAMPLE,
});
