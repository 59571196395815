import React from 'react';
import { Checkbox, Tabs, Tab, FormGroup, InputGroup } from '@blueprintjs/core';
import NodeEditor from './NodeEditor';
import Card from './Card';
import MobiledocEditor from './MobiledocEditor';
import CampaignActionBar from './CampaignActionBar';
import CampaignSettings from './CampaignSettings';
import SubmissionFields from './SubmissionFields';
import MoneyInput from './MoneyInput';

export default ({ fundraiser, ...props }) => (
  <div>
    <NodeEditor node={fundraiser} {...props}>
      {({ node, updateField }) => (
        <Card style={{ marginTop: 12 }}>
          <Tabs id="fundraiser-tabs" renderActiveTabPanelOnly>
            <Tab
              id="fundraiser-info-tab"
              title="Info"
              panel={
                <>
                  <FormGroup label="Target">
                    <MoneyInput
                      id="fundraiser-info-target"
                      value={node.target.amount}
                      symbol={node.target.currency.symbol}
                      onChange={v => updateField('target')({ ...node.target, amount: v })}
                    />
                  </FormGroup>
                  <FormGroup label="Introduction" labelFor="fundraiser-info-intro">
                    <InputGroup
                      id="fundraiser-info-intro"
                      value={node.intro || ''}
                      onChange={e => updateField('intro')(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup label="Description">
                    <MobiledocEditor
                      mobiledoc={node.description}
                      onChange={updateField('description')}
                      autoFocus
                      mini
                    />
                  </FormGroup>
                </>
              }
            />
            <Tab
              id="fundraiser-fields-tab"
              title="Fields"
              panel={
                <SubmissionFields
                  settings={node.submissionFields}
                  onChange={updateField('submissionFields')}
                />
              }
            />
            <Tab
              id="fundraiser-donations-tab"
              title="Donations"
              panel={<CampaignActionBar campaign={node} />}
            />
            <Tab
              id="fundraiser-settings-tab"
              title="Settings"
              panel={
                <CampaignSettings settings={node.settings} onChange={updateField('settings')}>
                  <Tab
                    id="fundraiser-settings-tab"
                    title="Fundraiser Options"
                    style={{ width: '100%' }}
                    panel={
                      <div>
                        <InputGroup
                          defaultValue={node?.amounts?.options
                            .map(({ amount }) => amount.amount)
                            .join(',')}
                          onChange={e =>
                            updateField('amounts')({
                              ...node.amounts,
                              options: e.target.value
                                .split(',')
                                .filter(amount => !!amount)
                                .map(amount => ({
                                  amount: { amount: Number(amount) },
                                })),
                            })
                          }
                        />
                        <Checkbox
                          checked={node?.options?.disableRaised}
                          label="Disable money raised count"
                          onChange={() => {
                            updateField('options')({
                              ...node.options,
                              disableRaised: !node?.options?.disableRaised,
                            });
                          }}
                        />
                        <hr />
                        <FormGroup
                          helperText="Add a custom offset value to the money raised counter"
                          label="Amount raised offset"
                        >
                          <MoneyInput
                            id="fundraiser-offset"
                            value={node?.options?.raisedOffset?.amount}
                            symbol={node?.options?.raisedOffest?.currency?.symbol}
                            onChange={v =>
                              updateField('options')({
                                ...node.options,
                                raisedOffset: {
                                  ...node.options.raisedOffset,
                                  amount: Number(v),
                                },
                              })
                            }
                          />
                        </FormGroup>
                      </div>
                    }
                  />
                </CampaignSettings>
              }
            />
          </Tabs>
        </Card>
      )}
    </NodeEditor>
  </div>
);
