import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Button, ControlGroup, Tag, Intent } from '@blueprintjs/core';
import { useMutation } from '@apollo/react-hooks';
import { createAsyncIterable } from '/lib/utils';
import getClient from '/client';
import wizard from '/lib/wizard';

const STATUS_QUERY = gql`
  {
    currentProject {
      id
      settings {
        commerce {
          paymentGateways {
            mollie {
              apiKey
              status
            }
            stripe {
              secret
              publicKey
              status
            }
          }
        }
      }
    }
  }
`;

const getPaymentGatewayStatus = (id, expected) => {
  let shouldCancel = false;
  return {
    async fetch() {
      const client = await getClient();

      const fetchStatus = async () => {
        const { data } = await client
          .query({ query: STATUS_QUERY })
          .catch(err => console.error(err));
        const gateways = data?.currentProject?.settings?.commerce?.paymentGateways;
        return gateways?.[id].status;
      };

      const checkStatus = createAsyncIterable(fetchStatus);
      for await (const status of checkStatus) {
        if (shouldCancel) throw new Error('getPaymentGatewayStatus cancelled');
        if (expected) {
          if (status === expected) return status;
        } else {
          if (status !== 'PENDING') return status;
        }
      }
    },
    cancel() {
      shouldCancel = true;
    },
  };
};

const statusIntent = {
  SUCCESS: Intent.SUCCESS,
  PENDING: Intent.WARNING,
  FAILED: Intent.DANGER,
  NONE: Intent.NONE,
};

export default ({ name, id, mutation, schema, refetch, children, settings }) => {
  const [setPaymentGateway] = useMutation(mutation);
  const cleanups = [];
  useEffect(() => () => cleanups.forEach(cleanup => cleanup()), []);
  const initWizard = () =>
    wizard.init({
      title: `Set up ${name}`,
      steps: [
        {
          title: `${name} credentials`,
          processInput: async input => {
            await setPaymentGateway({
              variables: { input },
            });

            const { cancel, fetch } = getPaymentGatewayStatus(id);
            cleanups.push(cancel);

            const status = await fetch().catch();

            if (status !== 'SUCCESS') {
              throw new Error(
                'Something went wrong. Please verify that the input is correct and try again.',
              );
            }

            return true;
          },
          schema,
          uiSchema: {
            url: {
              'ui:autofocus': true,
            },
          },
        },
      ],
    });
  const initDelete = async () => {
    await setPaymentGateway({
      variables: { input: null },
    });
    const { cancel, fetch } = getPaymentGatewayStatus(id, 'NONE');
    cleanups.push(cancel);
    await fetch().catch();
  };
  const status = settings?.paymentGateways?.[id]?.status || 'NONE';
  return (
    <>
      <h3>{name}</h3>
      <ControlGroup style={{ marginBottom: '20px' }}>
        <Button
          onClick={async () => {
            await initWizard();
            refetch();
          }}
        >
          Set up {name}
        </Button>
        {status === 'NONE' ? null : <Tag intent={statusIntent[status]}>{status.toLowerCase()}</Tag>}
      </ControlGroup>
      {children}
      {!(status === 'SUCCESS') ? null : (
        <Button
          onClick={async () => {
            await initDelete();
            refetch();
          }}
          intent={Intent.DANGER}
        >
          Disable {name}
        </Button>
      )}
    </>
  );
};
