import React from 'react';

export default [
  {
    id: 'appBarBackgroundColor',
    title: 'App Bar Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-appbar--default"
          target="_blank"
        >
          AppBar
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'surfaceBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'appMenuBarBackgroundColor',
    title: 'App Menu Bar Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-appbar--default"
          target="_blank"
        >
          AppBar
        </a>{' '}
        used in the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          AppMenu
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'appBarBackgroundColor',
    defaultValue: null,
  },
  {
    id: 'appMenuBarBurgerColor',
    title: 'App Menu Bar Burger Color',
    description: () => (
      <>
        The background color of the burger used in the bar of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          AppMenu
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'burgerColor',
    defaultValue: null,
  },
  {
    id: 'appMenuBarBurgerHoverColor',
    title: 'App Menu Bar Burger Hover Color',
    description: () => (
      <>
        The hover background color of the burger used in the bar of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          AppMenu
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'appMenuDrawerBurgerColor',
    title: 'App Menu Drawer Burger Color',
    description: () => (
      <>
        The background color of the burger used in the drawer of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          AppMenu
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'burgerColor',
    defaultValue: null,
  },
  {
    id: 'appMenuDrawerBurgerHoverColor',
    title: 'App Menu Drawer Burger Hover Color',
    description: () => (
      <>
        The hover background color of the burger used in the drawer of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          AppMenu
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'appMenuButtonBackgroundColor',
    title: 'App Menu Button Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          Button
        </a>{' '}
        components in the app menu.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'appMenuButtonBackgroundHoverColor',
    title: 'App Menu Button Background Hover Color',
    description: () => (
      <>
        The hover background color of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          Button
        </a>{' '}
        components in the app menu.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'appMenuButtonTextColor',
    title: 'App Menu Button Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          Button
        </a>{' '}
        components in the app menu.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'appMenuButtonTextHoverColor',
    title: 'App Menu Button Text Hover Color',
    description: () => (
      <>
        The hover text color of the{' '}
        <a href="https://components.wings.dev/?path=/story/react-appmenu--default" target="_blank">
          Button
        </a>{' '}
        components in the app menu.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'textColor',
    defaultValue: null,
  },
  // {
  //   id: 'appBarHeight',
  //   title: 'App Bar Height',
  //   description: () => (
  //     <>
  //       The height of the{' '}
  //       <a
  //         href="https://components.wings.dev/?path=/story/components-appbar--default"
  //         target="_blank"
  //       >
  //         AppBar
  //       </a>{' '}
  //       component.
  //     </>
  //   ),
  //   type: 'string',
  //   group: 'Menus',
  //   parent: 'largeSpacing',
  //   defaultValue: null,
  // },
  {
    id: 'burgerColor',
    title: 'Burger Color',
    description: () => (
      <>
        A general color for all the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component. Used unless overriden for specific instances of the component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'iconColor',
    defaultValue: null,
  },
  {
    id: 'burgerHoverColor',
    title: 'Burger Hover Color',
    description: () => (
      <>
        The hover color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component. Used unless overriden for specific instances of the component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'iconHoverColor',
    defaultValue: null,
  },
  {
    id: 'burgerColorDark',
    title: 'Burger Color Dark',
    description: () => (
      <>
        A general color for all the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component used with dark backgrounds. Used unless overriden for specific instances of the
        component.
      </>
    ),
    type: 'color',
    group: 'Menus',
    parent: 'iconColorDark',
    defaultValue: null,
  },
  {
    id: 'burgerHeight',
    title: 'Burger Height',
    description: () => (
      <>
        The height (in pixels) of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component.
      </>
    ),
    type: 'number',
    group: 'Menus',
    parent: null,
    defaultValue: 30,
  },
  {
    id: 'burgerWidth',
    title: 'Burger Width',
    description: () => (
      <>
        The width (in pixels) of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component.
      </>
    ),
    type: 'number',
    group: 'Menus',
    parent: null,
    defaultValue: 40,
  },
  {
    id: 'burgerBarHeight',
    title: 'Burger Bar Height',
    description: () => (
      <>
        The height (in pixels) of the bars of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component.
      </>
    ),
    type: 'number',
    group: 'Menus',
    parent: null,
    defaultValue: 5,
  },
  {
    id: 'burgerBarBorderRadius',
    title: 'Burger Bar Border Radius',
    description: () => (
      <>
        The border radius (in pixels) of the bars of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-burger--default"
          target="_blank"
        >
          Burger
        </a>{' '}
        component.
      </>
    ),
    type: 'number',
    group: 'Menus',
    parent: null,
    defaultValue: 0,
  },
];
