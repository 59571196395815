import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import Tabs from '/components/Tabs';
import usePaginationQuery from '/hooks/pagination';

import Breadcrumb from '/components/Breadcrumb';
import withRouter from '/containers/withRouter';
import Loading from '/components/Loading';
import OrderList from '/components/OrderList';
import ErrorCallout from '/components/ErrorCallout';
import withProjects from '/containers/withProjects';

const Pagination = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const QUERY = gql`
  query Orders($after: String) {
    items: orders(first: 20, after: $after) {
      pageInfo {
        ...PageInfo
      }
      edges {
        order: node {
          id
          status
          total {
            amount
            currency {
              id
              name
              symbol
            }
          }
          customer {
            billing {
              firstName
              lastName
            }
          }
          transactions {
            id
            status
            gateway {
              externalTransactionId
              externalTransactionUrl
            }
          }
          createdAt
        }
      }
    }
  }
`;

function Commerce({ projectUrl }) {
  const query = usePaginationQuery({
    query: QUERY,
    options: {
      fetchPolicy: 'cache-and-network',
      // pollInterval: 5000,
    },
  });

  const { data: { items } = {}, loading, error, PaginationControls } = query;

  return loading && !items?.edges.length ? (
    <Loading fill />
  ) : error ? (
    <ErrorCallout message="Something went wrong." />
  ) : (
    <>
      <Breadcrumb
        items={[
          {
            text: 'Commerce',
          },
        ]}
      />
      <Tabs titles={['Orders']}>
        <div>
          <OrderList
            orders={items.edges.map(edge => ({
              ...edge.order,
              editUrl: projectUrl('/commerce'),
            }))}
          />
          <Pagination>
            <PaginationControls />
          </Pagination>
        </div>
      </Tabs>
    </>
  );
}

export default compose(withRouter, withProjects)(Commerce);
