import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import { randomId } from '/lib/utils';

const withoutIndex = (i, arr) => [...arr.slice(0, i), ...arr.slice(i + 1)];
const move = (array, index, delta) => {
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return array;
  const indexes = [index, newIndex].sort((a, b) => a - b);
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
  return array;
};
const moveUp = (index, array) => move(array, index, -1);
const moveDown = (index, array) => move(array, index, 1);

class Collection extends Component {
  static defaultProps = {
    items: [],
  };

  updatedRowAtIndex = (i, payload) =>
    this.props.items.map((v, _i) => (i === _i ? { ...v, ...payload } : v));

  handleNewRowClick = () =>
    this.props.onChange([...this.props.items, { _id: randomId(), ...this.props.newItem }]);

  handleItemChange = row => value => {
    console.log('handleItemChange', value, this.updatedRowAtIndex(row, value));
    this.props.onChange(this.updatedRowAtIndex(row, value));
  };

  handleRemove = i => () => this.props.onChange(withoutIndex(i, this.props.items));
  handleUp = i => () => this.props.onChange(moveUp(i, [...this.props.items]));
  handleDown = i => () => this.props.onChange(moveDown(i, [...this.props.items]));

  render() {
    const { items } = this.props;

    return (
      <div>
        <div>
          {items.map((item, row) => (
            <React.Fragment key={`collection-item-${item._id}`}>
              {this.props.renderItem({
                payload: item,
                onChange: this.handleItemChange(row),
                onMoveUp: this.handleUp(row),
                onMoveDown: this.handleDown(row),
                onRemove: this.handleRemove(row),
              })}
            </React.Fragment>
          ))}
        </div>
        <hr />
        <Button onClick={this.handleNewRowClick}>Add Item</Button>
      </div>
    );
  }
}

export default Collection;
