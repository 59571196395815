import React from 'react';

export default [
  {
    id: 'footerBackgroundColor',
    title: 'Footer Background Color',
    description: () => (
      <>
        The background color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-footer--default"
          target="_blank"
        >
          Footer
        </a>{' '}
        component.
      </>
    ),
    type: 'color',
    group: 'Footer',
    parent: 'primaryColor',
    defaultValue: null,
  },
  {
    id: 'footerTextColor',
    title: 'Footer Text Color',
    description: () => (
      <>
        The text color of the{' '}
        <a
          href="https://components.wings.dev/?path=/story/components-footer--default"
          target="_blank"
        >
          Footer
        </a>{' '}
        component. Default calculated from text colors and the Footer background.
      </>
    ),
    type: 'color',
    group: 'Footer',
    parent: 'textColor',
    defaultValue: null,
  },
  {
    id: 'footerConfig',
    title: 'Footer Config',
    description: () => (
      <>
        Configuration object for the footer. See information about usage{' '}
        <a href="https://docs.wings.dev/docs/dev/hummingbird/config#footer-1" target="_blank">
          here
        </a>
        . Should be{' '}
        <a href="https://www.w3schools.com/js/js_json_syntax.asp" target="_blank">
          correctly formatted JSON
        </a>
        .
      </>
    ),
    type: 'json',
    group: 'Footer',
    parent: null,
    defaultValue: '',
  },
];
