/* global FormData,fetch */
import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, mapProps } from 'recompose';
import ClipboardInput from '/components/ClipboardInput';
import styled from 'styled-components';
import withProjects from '/containers/withProjects';
import Input from '/components/Input';
import Select from '/components/Select';
import Debug from '/components/Debug';
import Loading from '/components/Loading';

const ImageContainer = styled.div`
  width: 192px;
  height: 192px;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  margin: 2px;
`;

const Q = gql`
  query Medium($selector: FileSelectorInput!) {
    medium: file(selector: $selector) {
      id
      name
      caption
      alt
      key
      license
      updatedAt
      createdAt
      url
      ... on ImageFile {
        url192: transform(width: 192, height: 192)
      }
    }
  }
`;

const _withData = Comp => props => (
  <Query
    query={Q}
    fetchPolicy="network-only"
    variables={{ selector: { id: { eq: props.match.params.id } } }}
  >
    {({ data }) => <Comp medium={data?.medium} {...props} />}
  </Query>
);
const withData = compose(
  _withData,
  mapProps(({ data, loading, ...rest }) => ({
    medium: data?.medium,
    loading,
    ...rest,
  })),
);

const UPDATE = gql`
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      file {
        id
        name
        caption
        alt
        key
        license
        updatedAt
        createdAt
        url
        ... on ImageFile {
          url192: transform(width: 192, height: 192)
          urlMedium: transform(width: 400)
          urlLarge: transform(width: 850)
          urlExtraLarge: transform(width: 1200)
        }
      }
    }
  }
`;

const withUpdate = Comp => props => (
  <Mutation mutation={UPDATE}>{updateFile => <Comp {...props} updateFile={updateFile} />}</Mutation>
);

class MediaDetailView extends Component {
  static defaultProps = {
    medium: null,
  };

  state = {
    loaded: false,
    medium: {
      name: '',
      license: 'ALL_RIGHTS_RESERVED',
      caption: '',
      alt: '',
    },
  };

  componentDidUpdate() {
    if (this.state.loaded || this.props.loading) return;

    const { medium } = this.props;
    if (medium) this.setState({ medium, loaded: true });
  }

  update = async () => {
    const { id: mediaId, name = '', caption = '', license, alt = '' } = this.state.medium;
    await this.props.updateFile({
      variables: {
        input: {
          id: mediaId,
          file: {
            name,
            caption,
            alt,
            license,
          },
        },
      },
    });
  };

  debouncedUpdate() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.update, 500);
  }

  eventToMediumState = key => e => this.setMediumState({ [key]: e.target.value });

  setMediumState = state => {
    this.setState(({ medium }) => ({ medium: { ...medium, ...state } }), this.debouncedUpdate);
  };

  render() {
    const { medium, loading } = this.props;
    const { name = '', license, caption = '', alt = '' } = this.state.medium;
    return (
      <div>
        {loading ? (
          <Loading fill />
        ) : (
          <div>
            <ImageContainer>
              <Image src={medium?.url192} />
            </ImageContainer>
            <div className="bp3-form-group bp3-inline" style={{ clear: 'both' }}>
              <Input
                placeholder="my-image"
                onChange={this.eventToMediumState('name')}
                value={name}
                labelText="Name"
              />
              <Input
                placeholder="Your caption here..."
                onChange={this.eventToMediumState('caption')}
                value={caption || ''}
                labelText="Caption"
              />
              <Input
                placeholder="Your alt-text here..."
                onChange={this.eventToMediumState('alt')}
                value={alt || ''}
                labelText="Alt-text"
              />
              <Select
                onChange={e => this.setMediumState({ license: e.target.value })}
                labelText="License"
                items={[
                  { value: 'ALL_RIGHTS_RESERVED', text: 'All rights reserved' },
                  { value: 'CC_BY', text: 'Attribution (CC BY)' },
                  {
                    value: 'CC_BY_SA',
                    text: 'Attribution-ShareAlike (CC BY-SA)',
                  },
                  {
                    value: 'CC_BY_ND',
                    text: 'Attribution-NoDerivs (CC BY-ND)',
                  },
                  {
                    value: 'CC_BY_NC',
                    text: 'Attribution-NonCommercial (CC BY-NC)',
                  },
                  {
                    value: 'CC_BY_NC_SA',
                    text: 'Attribution-NonCommercial-ShareAlike CC BY-NC-SA',
                  },
                  {
                    value: 'CC_BY_NC_ND',
                    text: 'Attribution-NonCommercial-NoDerivs CC BY-NC-ND',
                  },
                  { value: 'PUBLIC_DOMAIN', text: 'Public domain' },
                ]}
                value={license}
              />
            </div>
            <div>
              <hr />
              <ClipboardInput value={medium?.url} />
              <Debug data={medium} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withProjects, withData, withUpdate)(MediaDetailView);
