import React from 'react';
import filterProps from 'filter-invalid-dom-props';
import { compose, setDisplayName } from 'recompose';
import { Callout, Intent } from '@blueprintjs/core';
import styled from 'styled-components';

const Wrapper = styled.div``;

const ErrorCallout = ({ message, ...props }) => (
  <Wrapper {...filterProps(props)}>
    <Callout intent={Intent.DANGER}>{message}</Callout>
  </Wrapper>
);

export default compose(setDisplayName('ErrorCallout'))(ErrorCallout);
