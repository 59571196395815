import React from 'react';
import styled from 'styled-components';
import { Card, Tabs, Tab, FormGroup, TextArea as _TextArea } from '@blueprintjs/core';
import NodeEditor from './NodeEditor';
import MoneyInput from './MoneyInput';
import ScheduleInput from './ScheduleInput';
import LocationInput from './LocationInput';
import MobiledocEditor from './MobiledocEditor';
import CampaignActionBar from './CampaignActionBar';
import CampaignSettings from './CampaignSettings';
import SubmissionFields from './SubmissionFields';

const TextArea = styled(_TextArea)`
  min-height: 80px;
`;

export default ({ event, ...props }) => (
  <div>
    <NodeEditor node={event} {...props}>
      {({ node, updateField }) => (
        <Card>
          <Tabs renderActiveTabPanelOnly>
            <Tab
              id="event-info-tab"
              title="Info"
              panel={
                <React.Fragment>
                  <FormGroup label="Introduction" labelFor="intro-input">
                    <TextArea
                      id="intro-input"
                      className="bp3-fill"
                      large
                      onChange={e => updateField('intro')(e.target.value)}
                      value={node.intro || ''}
                    />
                  </FormGroup>
                  <FormGroup label="Description" labelFor="description-input">
                    <MobiledocEditor
                      id="description-input"
                      mini
                      mobiledoc={node.description}
                      onChange={updateField('description')}
                    />
                  </FormGroup>

                  <FormGroup label="Schedule Start" labelFor="schedule-start-input">
                    <ScheduleInput
                      onChange={updateField('schedule')}
                      value={node.schedule}
                      timePrecision={true}
                    />
                  </FormGroup>

                  <FormGroup label="Fee" labelFor="fee-input">
                    <MoneyInput
                      onChange={v =>
                        updateField('fee')({
                          ...node.fee,
                          amount: {
                            ...node.fee.amount,
                            amount: v,
                          },
                        })
                      }
                      value={node.fee.amount.amount}
                      symbol={node.fee.amount.currency.symbol}
                    />
                  </FormGroup>

                  <FormGroup label="Location" labelFor="location-input">
                    <LocationInput onChange={updateField('location')} value={node.location} />
                  </FormGroup>
                </React.Fragment>
              }
            />
            <Tab
              id="event-attendees-tab"
              title={`Attendees`}
              panel={<CampaignActionBar campaign={node} />}
            />
            <Tab
              id="event-fields-tab"
              title="Fields"
              panel={
                <SubmissionFields
                  settings={node.submissionFields}
                  onChange={updateField('submissionFields')}
                />
              }
            />
            <Tab
              id="event-settings-tab"
              title="Settings"
              panel={
                <CampaignSettings settings={node.settings} onChange={updateField('settings')} />
              }
            />
          </Tabs>
        </Card>
      )}
    </NodeEditor>
  </div>
);
