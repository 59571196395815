import auth0 from 'auth0-js';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '/config';
import history from '../data/history';

const POST_LOGIN_REDIRECT_PATH_KEY = 'postLoginRedirectPath';

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    audience: 'urn:wings:api',
    responseType: 'token id_token',
    scope: 'openid',
  });

  login = () => {
    this.setPostLoginRedirectPath();
    this.auth0.authorize();
  };

  setPostLoginRedirectPath() {
    const { pathname, hash } = window.location;
    localStorage.setItem(POST_LOGIN_REDIRECT_PATH_KEY, `${pathname}${hash}`);
  }

  consumePostLoginRedirectPath() {
    const path = localStorage.getItem(POST_LOGIN_REDIRECT_PATH_KEY);
    localStorage.removeItem(POST_LOGIN_REDIRECT_PATH_KEY);
    return path || '/';
  }

  handleAuthentication = () => {
    this.authenticating = true;
    this.authPromise = new Promise((r, rej) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          r();
        } else if (err) {
          rej(err);
          this.auth0.authorize();
        }
        this.authenticating = false;
      });
    });
    return this.authPromise;
  };

  getAccessToken() {
    return {
      token: localStorage.getItem('accessToken'),
      expiresAt: Number(localStorage.getItem('expiresAt')),
    };
  }

  authHeaders = () => ({
    Authorization: `Bearer ${this.getAccessToken().token}`,
    'X-Wings-Project': this.getProjectId(),
  });

  getProjectId = () => {
    const path = history.location.pathname;
    const [, projectId] = path.split('/');
    return projectId;
  };

  getUser() {
    const { token } = this.getAccessToken();
    if (!token) return null;
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      return {
        id: tokenData?.sub,
        roles: tokenData?.['https://wings-platform.com/app_metadata']?.roles,
      };
    } catch (e) {
      return {};
    }
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('expiresAt', authResult.expiresIn * 1000 + Date.now());
  }

  logout = () => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('expiresAt');
  };

  isAuthenticated = async () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    if (this.authPromise) await this.authPromise;
    const expiresAt = JSON.parse(localStorage.getItem('expiresAt'));
    return new Date().getTime() < expiresAt;
  };
}
export default new Auth();
