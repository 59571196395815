import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as _Container, Editor as _Editor } from 'react-mobiledoc-editor';
import { StickyContainer, Sticky } from 'react-sticky';
import classNames from 'classnames';
import LinkButton from './LinkButton';
import { Sections, Markups, HeadingDropdown, CardsDropdown } from './Toolbar';
import {
  CampaignCard,
  ChapterCard,
  CollectionCard,
  CTACard,
  DataCard,
  EmbedCard,
  HeaderCard,
  ImageCard,
  InsightCard,
  NodesCard,
  QACard,
  QuoteCard,
  TestimonialCard,
  TextCard,
} from './cards';

const MOBILEDOC_VERSION = '0.3.1';
const MINI_CARDS = [];
const DEFAULT_CARDS = [
  CampaignCard,
  ChapterCard,
  CollectionCard,
  CTACard,
  DataCard,
  EmbedCard,
  HeaderCard,
  ImageCard,
  InsightCard,
  NodesCard,
  QACard,
  QuoteCard,
  TestimonialCard,
  TextCard,
];

const Container = styled(_Container)`
  width: 100%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  &.mini {
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
    margin-top: initial;
  }
`;

const Editor = styled(_Editor)`
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;
  &.mini {
    margin-top: 0;
    height: auto;
    padding: 0.5rem 1rem;
    width: 100%;
  }
`;

const MenuBar = styled.div`
  width: 100%;
  max-width: 55rem;
  text-align: center;
  .wrap {
    display: inline-block;
  }
  &.sticky {
    position: fixed;
    top: 0;
  }
`;

const EMPTY_MOBILEDOC =
  '{"version":"0.3.1","atoms":[],"cards":[],"markups":[],"sections":[[1,"p",[]]]}';

export default class MobiledocEditor extends Component {
  static EMPTY_MOBILEDOC = EMPTY_MOBILEDOC;
  static propTypes = {
    mobiledoc: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    mini: PropTypes.bool,
    version: PropTypes.string,
    autoFocus: PropTypes.bool,
  };
  static defaultProps = {
    mobiledoc: '',
    mini: false,
    version: MOBILEDOC_VERSION,
    autoFocus: false,
  };
  getMobiledoc() {
    const { mobiledoc } = this.props;
    try {
      return mobiledoc ? JSON.parse(mobiledoc) : undefined;
    } catch (e) {
      return undefined;
    }
  }
  getCards() {
    const { mini } = this.props;
    return mini ? MINI_CARDS : DEFAULT_CARDS;
  }
  getChapterCard() {
    const md = this.getMobiledoc();
    if (!md) return ChapterCard;
    const cardNames = md.cards.map(c => c[0]);
    const hasHeaderCards = cardNames.some(cn => cn === 'HeaderCard');
    return hasHeaderCards ? HeaderCard : ChapterCard;
  }
  componentWillUnmount() {
    delete window.editor;
  }
  render() {
    const { onChange, mini, version, autoFocus, ...props } = this.props;
    const ChapterCard = this.getChapterCard();
    return (
      <Container
        className={classNames({ mini })}
        onChange={v => onChange(JSON.stringify(v))}
        serializeVersion={version}
        cards={this.getCards()}
        autofocus={autoFocus}
        {...props}
        mobiledoc={this.getMobiledoc()}
        didCreateEditor={editor => (window.editor = editor)}
      >
        {mini ? (
          <React.Fragment>
            <MenuBar>
              <div className="wrap">
                <div className="bp3-button-group">
                  <HeadingDropdown className="bp3-small bp3-minimal" />
                  <Sections className="bp3-small bp3-minimal" />
                  <Markups className="bp3-small bp3-minimal" />
                  <LinkButton className="bp3-button bp3-icon-link bp3-small bp3-minimal" />
                </div>
              </div>
            </MenuBar>
            <Editor
              className={classNames({
                'bp3-input bp3-fill mini': mini,
              })}
            />
          </React.Fragment>
        ) : (
          <StickyContainer>
            <Sticky>
              {({ isSticky: sticky }) => (
                <MenuBar className={classNames({ sticky })}>
                  <div className="wrap">
                    <div className="bp3-button-group bp3-dark">
                      <HeadingDropdown />
                      <Sections />
                      <Markups />
                      <LinkButton className="bp3-button bp3-icon-link" />
                      <CardsDropdown ChapterCard={ChapterCard} />
                    </div>
                  </div>
                </MenuBar>
              )}
            </Sticky>
            <Editor />
          </StickyContainer>
        )}
      </Container>
    );
  }
}
