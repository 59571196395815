import React, { useState } from 'react';
import CopyEditor from '/components/CopyEditor';
import Select from '/components/Select';

export default function TranslationOverridesEditor({ onChange, locales, value }) {
  const [currentLocale, setCurrentLocale] = useState(locales.find(l => l.primary).locale.id);
  return (
    <div>
      <Select
        value={currentLocale}
        filterable={false}
        items={locales.map(({ locale }) => ({
          value: locale.id,
          text: `${locale.name} (${locale.id})`,
        }))}
        itemRenderer={item => item.locale.id}
        onChange={e => setCurrentLocale(e.target.value)}
      />
      <hr style={{ marginBottom: 20 }} />
      <CopyEditor
        resourceType={'webapp'}
        overrides={value.overrides.filter(o => o.localeId === currentLocale)}
        onChange={onChange(currentLocale)}
        localeId={currentLocale}
      />
    </div>
  );
}
