import React, { Component } from 'react';
import { enumerate } from '/lib/utils';
import People from './People';
import Orgs from './Orgs';

const TYPE = enumerate('PEOPLE', 'ORG');

export default class TestimonialCardView extends Component {
  static TYPE = TYPE;
  render() {
    const { type } = this.props;
    switch (type) {
      case TYPE.PEOPLE:
        return <People {...this.props} />;
      case TYPE.ORG:
        return <Orgs {...this.props} />;
      default:
        return null;
    }
  }
}
