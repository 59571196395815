import createHistory from 'history/createBrowserHistory';

function useBeforeUnload(createHistory) {
  return options => {
    const history = createHistory(options);

    const pendingPrompts = [];

    window.addEventListener('beforeunload', e => {
      const activePrompts = pendingPrompts
        .map(prompt => (typeof prompt === 'function' ? prompt(null, undefined) : prompt))
        .filter(message => message !== true);

      if (activePrompts.length === 0) {
        return undefined;
      }

      const message = activePrompts[0];

      e.preventDefault();
      e.returnValue = message;
      return message;
    });

    const originalBlock = history.block;
    history.block = prompt => {
      pendingPrompts.push(prompt);
      const unblock = originalBlock(prompt);

      return () => {
        pendingPrompts.pop();
        return unblock();
      };
    };

    return history;
  };
}

export default useBeforeUnload(createHistory)();
