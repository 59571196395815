import React, { useState } from 'react';
import styled from 'styled-components';
import { Collapse as _Collapse } from '@blueprintjs/core';

const GroupButton = styled.div`
  width: 100;
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.2s all linear;
  user-select: none;
  &:hover {
    background-color: #ebf1f5;
  }
`;

const GroupWrap = styled.div`
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  border-radius: 3px;
  margin-bottom: 20px;
`;

const GroupSettingsWrap = styled.div`
  padding: 20px;
  user-select: none;
`;

const Collapse = styled(_Collapse)`
  padding: 0;
  > .bp3-collapse-body {
    z-index: 0;
    transform: none !important;
  }
`;

export default function ThemeSettingsGroup({
  children,
  name,
  settings,
  onChange,
  theme,
  themeSettings,
  ...props
}) {
  const [open, setOpen] = useState(false);
  return (
    <GroupWrap {...props}>
      <GroupButton onClick={() => setOpen(!open)}>{name}</GroupButton>
      <Collapse isOpen={open}>
        <GroupSettingsWrap>{children}</GroupSettingsWrap>
      </Collapse>
    </GroupWrap>
  );
}
