import React, { Component } from 'react';
import filterProps from 'filter-invalid-dom-props';
import { Button, FormGroup, InputGroup, ControlGroup, Intent } from '@blueprintjs/core';

export default class LegalSettings extends Component {
  handleFieldChange = field => v => {
    this.props.onChange({
      ...this.props.settings,
      [field]: v,
    });
  };
  render() {
    const { onChange, settings, ...props } = this.props; // eslint-disable-line no-unused-vars
    const termsDisabled = settings?.terms?.disabled;
    return (
      <div {...filterProps(props)} style={{ width: 460 }}>
        <FormGroup
          helperText="URL (or path) to the terms & conditions of this campaign"
          label="Terms & Conditions"
          labelFor="terms-input"
        >
          <ControlGroup>
            <Button
              intent={Intent[termsDisabled ? 'PRIMARY' : 'WARNING']}
              onClick={() =>
                this.handleFieldChange('terms')({
                  ...settings?.terms,
                  disabled: !termsDisabled,
                  url: '',
                })
              }
            >
              {termsDisabled ? 'Enable' : 'Disable'}
            </Button>
            <InputGroup
              id="terms-input"
              value={settings?.terms?.url || ''}
              disabled={termsDisabled}
              onChange={e =>
                this.handleFieldChange('terms')({
                  ...settings?.terms,
                  url: e.target.value,
                })
              }
            />
          </ControlGroup>
        </FormGroup>
        <FormGroup
          helperText="URL (or path) to the privacy policy of this campaign"
          label="Privacy Policy"
          labelFor="privacy-input"
        >
          <InputGroup
            id="privacy-input"
            value={settings?.privacyPolicy?.url || ''}
            onChange={e =>
              this.handleFieldChange('privacyPolicy')({
                ...settings?.privacyPolicy,
                url: e.target.value,
              })
            }
          />
        </FormGroup>
      </div>
    );
  }
}
