import React, { useState } from 'react';
import styled from 'styled-components';
import {
  InputGroup,
  Checkbox,
  Dialog,
  Tooltip,
  Icon,
  ButtonGroup,
  Button,
  Intent,
} from '@blueprintjs/core';
import ColorInput from '/components/ColorInput';
import MediaPicker from '/components/MediaPicker';
import Select from '/components/Select';
import withMedia from '/containers/withMedia';
import ThemeSettingInputValidation from './ThemeSettingInputValidation';

const Setting = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SettingLabel = styled.label`
  display: block;
  padding-bottom: 10px;
`;

const Info = styled.p`
  font-size: 0.8em;
  color: #5c7080;
`;

function SettingInput({ value, inheritedValue, onChange, type, options, ...props }) {
  const [showMediaSelect, setShowMediaSelect] = useState(false);
  switch (type) {
    case 'color':
      return (
        <ColorInput
          color={value || inheritedValue}
          onChange={c => {
            const { r, g, b, a } = c.rgb;
            onChange(`rgba(${r},${g},${b},${a})`);
          }}
          showClear={![null, undefined].includes(value)}
          onClear={() => onChange(null)}
          {...props}
        />
      );
    case 'select':
      console.log(options);
      return (
        <Select
          items={options}
          value={value || inheritedValue}
          onChange={e => console.log(e) || onChange(e.target.value)}
        />
      );
    case 'boolean':
      return <Checkbox checked={value} onChange={() => onChange(!value)} {...props} />;
    case 'image':
      return (
        <>
          <InputGroup
            value={value}
            placeholder={inheritedValue}
            type="text"
            onChange={e => onChange(e.target.value)}
            rightElement={
              <>
                <Tooltip content="Choose an image from the media library">
                  <ButtonGroup>
                    <Button
                      icon="media"
                      intent={Intent.PRIMARY}
                      onClick={() => setShowMediaSelect(true)}
                    />
                  </ButtonGroup>
                </Tooltip>
                <Dialog
                  isOpen={showMediaSelect}
                  onClose={() => setShowMediaSelect(false)}
                  title="Select media"
                  isCloseButtonShown
                  canOutsideClickClose
                >
                  <div style={{ padding: '20px', margin: '0 auto' }}>
                    <MediaPicker
                      onSelect={v => {
                        onChange(v?.url);
                        setShowMediaSelect(false);
                      }}
                    />
                  </div>
                </Dialog>
              </>
            }
            {...props}
          />
        </>
      );
    default:
      return (
        <InputGroup
          value={value}
          placeholder={inheritedValue}
          type="text"
          onChange={e => onChange(e.target.value)}
          {...props}
        />
      );
  }
}

function ThemeSettingInput({
  id,
  type,
  description: Description,
  title,
  value,
  inheritedValue,
  inheritedSettings,
  setting,
  options,
  onChange,
  ...props
}) {
  const elementId = `${id}-input`;
  return (
    <Setting {...props}>
      <SettingLabel htmlFor={elementId}>{title}</SettingLabel>
      <SettingInput {...{ value, inheritedValue, onChange, id: elementId, type, options }} />
      <ThemeSettingInputValidation type={type} value={value} />
      <Info>
        <Description />
      </Info>
      {inheritedSettings.length ? (
        <Info>
          Inherits{' '}
          <Tooltip
            content={`If ${title} is not set, the value will be determined by the inheritence structure shown.`}
          >
            <Icon icon="info-sign" iconSize={14} style={{ cursor: 'help' }} />
          </Tooltip>
          :{' '}
          {inheritedSettings.length
            ? inheritedSettings.map((dep, i) => {
                return `${dep?.title} ${i + 1 < inheritedSettings.length ? ' → ' : ''}`;
              })
            : null}
        </Info>
      ) : null}
    </Setting>
  );
}

export default withMedia(ThemeSettingInput);
