import React, { Component } from 'react';
import filterProps from 'filter-invalid-dom-props';
import { FormGroup, InputGroup, Button, ControlGroup, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { enumerate } from '/lib/utils';

const OPTIONS = enumerate('NONE', 'SOURCE', 'VALUE');
const LABELS = {
  [OPTIONS.NONE]: 'No Mapping',
  [OPTIONS.SOURCE]: 'Submission Field',
  [OPTIONS.VALUE]: 'Value',
};

const isDefined = v => v !== null && v !== undefined;

export default class MailChimpMapping extends Component {
  static propTypes = {};
  getActiveOption = () => {
    const { source, value } = this.props;
    return isDefined(source) ? OPTIONS.SOURCE : isDefined(value) ? OPTIONS.VALUE : OPTIONS.NONE;
  };
  handleOptionChange = v => {
    switch (v) {
      case OPTIONS.SOURCE:
        return this.props.onChange({ source: '', value: null });
      case OPTIONS.VALUE:
        return this.props.onChange({
          source: null,
          value: this.props.boolean ? false : '',
        });
      default:
        return this.props.onChange({ source: null, value: null });
    }
  };
  isSource = () => this.getActiveOption() === OPTIONS.SOURCE;
  isValue = () => this.getActiveOption() === OPTIONS.VALUE;
  handleValueChange = value => this.props.onChange({ source: null, value });
  handleSourceChange = ({ value: sourceValue }) => {
    this.props.onChange({ source: sourceValue, value: null });
  };
  render() {
    const { name, source, value, boolean, sourceFields, ...props } = this.props;
    return (
      <FormGroup {...filterProps(props)} label={name} labelFor="text-input">
        <ControlGroup>
          <Select
            filterable={false}
            items={[OPTIONS.NONE, OPTIONS.SOURCE, OPTIONS.VALUE]}
            itemRenderer={(i, { handleClick, modifiers: { active } }) => (
              <MenuItem
                key={`map-item-option-${i}`}
                text={LABELS[i]}
                onClick={handleClick}
                active={active}
              />
            )}
            onItemSelect={this.handleOptionChange}
          >
            <Button text={LABELS[this.getActiveOption()]} rightIcon="caret-down" />
          </Select>
          {this.isValue() &&
            (boolean ? (
              <Select
                filterable={false}
                items={[
                  { label: 'True', value: true },
                  { label: 'False', value: false },
                ]}
                itemRenderer={(item, { handleClick }) => (
                  <MenuItem
                    key={`map-item-option-${item.value}`}
                    text={item.label}
                    onClick={handleClick}
                  />
                )}
                onItemSelect={item => this.handleValueChange(item.value)}
              >
                <Button text={value ? 'True' : 'False'} rightIcon="caret-down" />
              </Select>
            ) : (
              <InputGroup value={value} onChange={e => this.handleValueChange(e.target.value)} />
            ))}
          {this.isSource() && (
            <Select
              filterable={false}
              items={sourceFields}
              itemRenderer={(sourceField, { handleClick }) => (
                <MenuItem
                  key={`map-item-option-${sourceField.value}`}
                  text={sourceField.label}
                  onClick={handleClick}
                />
              )}
              onItemSelect={this.handleSourceChange}
            >
              <Button
                text={(sourceFields.find(({ value }) => value === source) || {}).label || 'Field'}
                rightIcon="caret-down"
              />
            </Select>
          )}
        </ControlGroup>
      </FormGroup>
    );
  }
}
