import React, { useState } from 'react';
import styled from 'styled-components';
import Color from 'color';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { SketchPicker } from 'react-color';

const Swatch = styled.div`
  height: 30px;
  padding: 6px;
  background-color: #f5f8fa;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: #ebf1f5;
  }
`;

const SwatchColor = styled.div`
  width: 60px;
  height: 100%;
  border-radius: 2px;
  background: ${({ color }) =>
    color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : 'transparent'};
`;

const Popover = styled.div`
  position: absolute;
  z-index: 10;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default function ColorInput({ color, onChange, onClear, showClear }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const _color = Color(color);
  const colorObj = {
    ..._color.object(),
    a: _color.alpha(),
  };

  return (
    <ButtonGroup>
      <Swatch onClick={() => setDisplayColorPicker(!displayColorPicker)}>
        <SwatchColor color={color ? colorObj : null} />
      </Swatch>
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={() => setDisplayColorPicker(false)} />
          <SketchPicker color={colorObj} onChange={onChange} />
        </Popover>
      ) : null}
      {showClear ? <Button icon="cross" onClick={onClear} /> : null}
    </ButtonGroup>
  );
}
