import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Dialog as _Dialog,
  InputGroup,
  Tooltip,
  ButtonGroup,
  Button,
  Intent,
} from '@blueprintjs/core';
import MediaPicker from '/components/MediaPicker';
import { createCard } from '@wingsplatform/react';
import CTACardView from './CTACardView';
import DialogStyle from '/styles/dialog';
import _Input from '/components/Input';
import Select from '/components/Select';
import withClickableHighlight from '/styles/withClickableHighlight';

const CTA_TYPES = [
  {
    value: 'vertical',
    text: 'Vertical',
  },
  {
    value: 'horizontal',
    text: 'Horizontal',
  },
];

const ALIGN_TYPES = [
  {
    value: 'left',
    text: 'Left',
  },
  {
    value: 'center',
    text: 'Center',
  },
  {
    value: 'right',
    text: 'Right',
  },
];

const SPACING_TYPES = [
  {
    value: 'small',
    text: 'Small',
  },
  {
    value: 'medium',
    text: 'Medium',
  },
  {
    value: 'large',
    text: 'Large',
  },
];

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

const Input = styled(_Input)`
  width: 100%;
`;

function CTACardEditor({ save, payload }) {
  const [data, setData] = useState(payload);
  const [showMediaSelect, setShowMediaSelect] = useState(false);
  const {
    align,
    actionText,
    actionUrl,
    backgroundImage,
    buttonText,
    buttonUrl,
    type,
    spacing,
    text,
    title,
  } = data;

  const updateData = newData => setData({ ...data, ...newData });

  return (
    <div>
      <CTACardView />
      <Dialog
        title="Call To Action"
        icon="control"
        isOpen
        onClose={() => save(data)}
        canOutsideClickClose={false}
        enforceFocus={false}
      >
        <div className="bp3-dialog-body">
          <Input
            name="title"
            placeholder="title"
            onChange={e => updateData({ title: e.target.value })}
            value={title}
          >
            Title
          </Input>
          <Input
            name="text"
            placeholder="text"
            onChange={e => updateData({ text: e.target.value })}
            value={text}
          >
            Text
          </Input>
          <label htmlFor="backgroundImage">
            Background Image
            <InputGroup
              value={backgroundImage}
              placeholder="background image"
              type="text"
              onChange={e => updateData({ backgroundImage: e.target.value })}
              rightElement={
                <>
                  <Tooltip content="Choose an image from the media library">
                    <ButtonGroup>
                      <Button
                        icon="media"
                        intent={Intent.PRIMARY}
                        onClick={() => setShowMediaSelect(true)}
                      />
                    </ButtonGroup>
                  </Tooltip>
                  <Dialog
                    isOpen={showMediaSelect}
                    onClose={() => setShowMediaSelect(false)}
                    title="Select media"
                    isCloseButtonShown
                    canOutsideClickClose
                  >
                    <div style={{ padding: '20px', margin: '0 auto' }}>
                      <MediaPicker
                        onSelect={v => {
                          updateData({ backgroundImage: v.url });
                          setShowMediaSelect(false);
                        }}
                      />
                    </div>
                  </Dialog>
                </>
              }
            />
          </label>
          <Select
            labelText="Type"
            items={CTA_TYPES}
            value={type}
            onChange={e => updateData({ type: e.target.value })}
          />
          <Select
            labelText="Spacing"
            items={SPACING_TYPES}
            value={spacing}
            onChange={e => updateData({ spacing: e.target.value })}
          />
          <Select
            labelText="Align"
            items={ALIGN_TYPES}
            value={align}
            onChange={e => updateData({ align: e.target.value })}
          />
          {/* Button options */}
          <Input
            name="buttonText"
            placeholder="buttonText"
            onChange={e => updateData({ buttonText: e.target.value })}
            value={buttonText || actionText}
          >
            Button Text
          </Input>
          <Input
            name="buttonUrl"
            placeholder="buttonUrl"
            onChange={e => updateData({ buttonUrl: e.target.value })}
            value={buttonUrl || actionUrl}
          >
            Button Url
          </Input>
        </div>
      </Dialog>
    </div>
  );
}

export default createCard({
  name: 'CTACard',
  renderWith: withClickableHighlight(CTACardView),
  editWith: CTACardEditor,
  buttonText: 'CTA',
  payload: {
    title: 'Title Here',
    text: 'Your text here.',
    actionText: 'Click Me',
    actionUrl: 'https://example.com',
  },
});
