/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import {
  ButtonGroup,
  Button,
  Intent,
  Dialog as _Dialog,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { createCard } from '@wingsplatform/react';
import { withApolloProvider } from '../../../../data/provider';
import Select from '/components/Select';
import DialogStyle from '/styles/dialog';
import withClickableHighlight from '/styles/withClickableHighlight';
import NodesCardView from './NodesCardView';
import SortableList from './SortableList';

const RESOURCE_TYPES = [
  {
    value: 'node.entry.article',
    text: 'Article',
  },
  {
    value: 'node.entry.page',
    text: 'Page',
  },
  {
    value: 'node.petition',
    text: 'Petition',
  },
  {
    value: 'node.event',
    text: 'Event',
  },
  {
    value: 'node.signup',
    text: 'Signup',
  },
  {
    value: 'node.fundraiser',
    text: 'Fundraiser',
  },
];

const Dialog = styled(_Dialog)`
  ${DialogStyle};
`;

const SELECTION_PAYLOAD = {
  type: 'selection',
  items: [],
};

const ARCHIVE_PAYLOAD = {
  type: 'archive',
  selector: {
    resourceType: { eq: 'node.entry.article' },
  },
};

const replaceAtIndex = (arr, index, item) =>
  arr
    .slice(0, index)
    .concat([item])
    .concat(arr.slice(index + 1));

class NodesCardEditor extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      payload: props.payload,
    };
  }

  eventToPayloadState = key => e => this.setPayloadState({ [key]: e.target.value });

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));

  save = () => this.props.save(this.postProcessPayload());

  postProcessPayload() {
    const { payload } = this.state;
    const base = {
      type: payload.type,
    };
    switch (payload.type) {
      case 'archive':
        return { ...base, selector: payload.selector };
      case 'selection':
        return { ...base, items: payload.items.filter(item => item.nodeId) };
      default:
        return payload;
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) =>
    this.setPayloadState({
      items: arrayMove(this.state.payload.items, oldIndex, newIndex),
    });

  onSelect = ({ id: nodeId }, index) => {
    const { items } = this.state.payload;
    this.setPayloadState({
      items: replaceAtIndex(items, index, { nodeId }),
    });
  };

  addItem = () =>
    this.setPayloadState({
      items: [...(this.state.payload.items || []), { nodeId: '' }],
    });

  setArchiveType = type => this.setPayloadState({ selector: { resourceType: { eq: type } } });
  getArchiveType = () => this.state.payload?.selector?.resourceType?.eq;

  deleteItem = (index, items) => {
    this.setPayloadState({
      items: items.slice(0, index).concat(items.slice(index + 1)),
    });
  };

  changeCardType = type => {
    switch (type) {
      case 'archive':
        return this.setPayloadState({
          ...ARCHIVE_PAYLOAD,
          selector: this.state.payload.selector || ARCHIVE_PAYLOAD.selector,
        });
      default:
        return this.setPayloadState({
          ...SELECTION_PAYLOAD,
          items: this.state.payload.items || SELECTION_PAYLOAD.items,
        });
    }
  };

  render() {
    const {
      payload: { type },
      payload,
    } = this.state;

    return (
      <>
        <NodesCardView {...payload} />
        <Dialog
          title="Nodes"
          icon="control"
          isOpen
          onClose={this.save}
          canOutsideClickClose={false}
          enforceFocus={false}
        >
          <div className="bp3-dialog-body">
            <RadioGroup
              onChange={e => this.changeCardType(e.target.value)}
              selectedValue={payload.type}
              inline
            >
              <Radio label="Selection" value="selection" />
              <Radio label="Archive" value="archive" />
            </RadioGroup>
            <hr style={{ marginBottom: 18 }} />
            {!(type === 'selection') ? null : (
              <>
                <SortableList
                  useDragHandle
                  items={payload.items}
                  onSortEnd={this.onSortEnd}
                  onSelect={this.onSelect}
                  deleteItem={this.deleteItem}
                />
                <ButtonGroup style={{ marginTop: '20px' }}>
                  <Button onClick={this.addItem} icon="add" intent={Intent.PRIMARY}>
                    Add Node
                  </Button>
                </ButtonGroup>
              </>
            )}
            {!(type === 'archive') ? null : (
              <>
                <Select
                  labelText="Resource Type"
                  items={RESOURCE_TYPES}
                  value={this.getArchiveType()}
                  onChange={e => this.setArchiveType(e.target.value)}
                />
              </>
            )}
          </div>
        </Dialog>
      </>
    );
  }
}

export default createCard({
  name: 'NodesCard',
  renderWith: withClickableHighlight(withApolloProvider(NodesCardView)),
  editWith: withApolloProvider(NodesCardEditor),
  buttonText: 'Nodes',
  payload: SELECTION_PAYLOAD,
});
