import gql from 'graphql-tag';
import React from 'react';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import Gateway from './Gateway';

const schema = {
  type: 'object',
  required: ['secret', 'publicKey'],
  properties: {
    publicKey: { title: 'Public Key', type: 'string' },
    secret: { title: 'Secret', type: 'string' },
  },
};

const SET_PAYMENT_GATEWAY_STRIPE_MUTATION = gql`
  mutation SetStripePaymentGateway($input: SetStripePaymentGatewayInput) {
    setStripePaymentGateway(input: $input) {
      stripe {
        secret
        publicKey
        status
      }
    }
  }
`;

export default ({
  settings: {
    paymentGateways: { stripe: { status = 'NONE', secret = '', publicKey = '' } = {} } = {},
  } = {},
  settings,
  ...props
}) => (
  <Gateway
    {...props}
    name="Stripe"
    id="stripe"
    mutation={SET_PAYMENT_GATEWAY_STRIPE_MUTATION}
    schema={schema}
    settings={settings}
  >
    {status === 'NONE' ? null : (
      <>
        <FormGroup label="Public Key" labelFor="stripe-public-key">
          <InputGroup
            id="stripe-public-key"
            value={publicKey}
            disabled
            style={{ marginBottom: 20 }}
          />
        </FormGroup>
        <FormGroup label="Secret" labelFor="stripe-secret">
          <InputGroup id="stripe-secret" value={secret} disabled style={{ marginBottom: 20 }} />
        </FormGroup>
      </>
    )}
  </Gateway>
);
