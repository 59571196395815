import React from 'react';
import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';
import { modularScale } from 'polished';
import { withSchemas } from './withSchemas';

const Wrapper = styled.div`
  background: #f5f8fa;
  padding: 1rem;
  text-align: center;
  * {
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: ${modularScale(3)};
  font-weight: 700;
  margin: 0.5rem 0;
`;

const Text = styled.p`
  font-size: ${modularScale(1)};
  font-weight: 700;
  line-height: 36px;
`;

function DataCardView({ getSchemaForKey, loading, schema: key, ...props }) {
  if (loading) return '';
  const schema = getSchemaForKey(key);
  const hasSchema = !!schema;

  return (
    <Wrapper {...filterProps(props)}>
      {hasSchema ? (
        <>
          <Icon icon="database" iconSize={Icon.SIZE_LARGE} />
          <Title>{schema.name}</Title>
        </>
      ) : (
        <>
          <Icon icon="database" iconSize={Icon.SIZE_LARGE} />
          <Text>JSON</Text>
        </>
      )}
    </Wrapper>
  );
}

export default withSchemas(DataCardView);
