import React, { Component } from 'react';
import { NonIdealState as _NonIdealState, Button, Intent } from '@blueprintjs/core';
import styled from 'styled-components';

const NonIdealState = styled(_NonIdealState)`
  margin: 60px 0;
`;

export default class ResourceNotFound extends Component {
  render() {
    const { name, onGoBackClick } = this.props;
    return (
      <NonIdealState
        icon="path-search"
        title={`${name} not found`}
        description="Whoops, we couldn't find what you were looking for."
        action={
          <Button icon="arrow-left" intent={Intent.PRIMARY} onClick={onGoBackClick}>
            Go Back
          </Button>
        }
      />
    );
  }
}
