import React, { Component } from 'react';
import { createCard, Content } from '@wingsplatform/react';
import styled from 'styled-components';
import MobiledocEditor from '/components/MobiledocEditor';
import ActionGroup from '/components/ActionGroup';

import withClickableHighlight from '/styles/withClickableHighlight';

const TextWrapper = styled.div`
  padding: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #f5f8fa;
`;

const TextView = ({ content, ...props }) => (
  <TextWrapper {...props}>{content ? <Content content={content} /> : 'Text...'}</TextWrapper>
);

class TextCardEditor extends Component {
  state = {
    payload: this.props.payload,
  };

  valueToPayloadState = key => v => this.setPayloadState({ [key]: v });

  setPayloadState = state =>
    this.setState(({ payload }) => ({ payload: { ...payload, ...state } }));
  render() {
    const { save } = this.props;
    return (
      <TextWrapper>
        <MobiledocEditor
          mobiledoc={this.state.payload.content}
          onChange={this.valueToPayloadState('content')}
          mini
        />
        <ActionGroup
          onCancel={() => save(this.props.payload)}
          onSave={() => save(this.state.payload)}
        />
      </TextWrapper>
    );
  }
}

export default createCard({
  name: 'TextCard',
  renderWith: withClickableHighlight(TextView),
  editWith: TextCardEditor,
  buttonText: 'Text',
  payload: {
    content: '',
  },
});
