import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setStatic } from 'recompose';
import classNames from 'classnames';
import styled from 'styled-components';
import filterProps from 'filter-invalid-dom-props';

const Title = styled.h1`
  text-align: center;
`;

export default compose(
  setPropTypes({
    title: PropTypes.string,
    children: PropTypes.node,
    href: PropTypes.string,
    elevation: PropTypes.number.isRequired,
    interactive: PropTypes.bool.isRequired,
    dark: PropTypes.bool,
  }),
  setStatic('defaultProps', {
    elevation: 0,
    interactive: false,
    dark: false,
  }),
)(({ title, children, href, elevation, interactive, className, dark, ...props }) => (
  <div
    className={classNames(className, 'bp3-card', `bp3-elevation-${elevation}`, {
      'bp3-interactive': interactive,
      'bp3-dark': dark,
    })}
    {...filterProps(props)}
  >
    <Title>{href ? <a href={href}>{title}</a> : title}</Title>
    {children}
  </div>
));
