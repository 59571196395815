import React, { Component } from 'react';
import { Tabs, Tab } from '@blueprintjs/core';
import LegalSettings from './LegalSettings';
import MailChimpSettings from './MailChimpSettings';

export default class CampaignSettings extends Component {
  handleFieldChange = field => v => this.props.onChange({ ...this.props.settings, [field]: v });
  render() {
    const { settings, children } = this.props;

    return (
      <Tabs id="campaign-settings-tabs" vertical>
        <Tab
          id="legal-settings-tab"
          title="Legal"
          style={{ width: '100%' }}
          panel={
            <LegalSettings settings={settings.legal} onChange={this.handleFieldChange('legal')} />
          }
        />
        <Tab
          id="mailchimp-settings-tab"
          title="MailChimp"
          style={{ width: '100%' }}
          panel={
            <MailChimpSettings
              settings={settings.mailchimp}
              onChange={this.handleFieldChange('mailchimp')}
            />
          }
        />
        {children}
      </Tabs>
    );
  }
}
