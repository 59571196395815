import { RESOURCE_TYPE } from '/constants';

const RESOURCE_TYPE_PATH_PART = {
  [RESOURCE_TYPE.ARTICLE]: 'articles',
  [RESOURCE_TYPE.PAGE]: '',
  [RESOURCE_TYPE.PETITION]: 'petitions',
  [RESOURCE_TYPE.EVENT]: 'events',
  [RESOURCE_TYPE.SIGNUP]: 'signups',
  [RESOURCE_TYPE.FUNDRAISER]: 'fundraisers',
};

export default class AppRouting {
  constructor(app) {
    this.app = app;
  }

  getBaseUrl() {
    const { domain, wingsDomain } = this.app;
    if (domain) return domain === 'localhost' ? 'http://localhost:666' : `https://${domain}`;
    return wingsDomain ? `https://${wingsDomain}` : null;
  }

  getUrl({ resourceType, publicSlug: slug, locale: nodeLocale, id } = {}, localeId) {
    const baseUrl = this.getBaseUrl();
    if (!baseUrl) return null;
    const parts = [];
    let query = '';
    let locale = localeId;

    if (id) {
      if (!nodeLocale.primary) locale = locale || nodeLocale.id;
      parts.push(RESOURCE_TYPE_PATH_PART[resourceType], slug);
    }

    if (locale) {
      query = `${!parts.length ? '/' : ''}?lang=${locale}`;
    }

    return [baseUrl].concat(parts.filter(part => !!part)).join('/') + query;
  }
}
