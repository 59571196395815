import React from 'react';
import styled from 'styled-components';
import { FormGroup, InputGroup } from '@blueprintjs/core';

const GeneralSettings = styled.div`
  .bp3-label {
    width: 160px;
  }
`;

export default ({ settings, onChange }) => (
  <GeneralSettings>
    <FormGroup
      labelFor="mailchimp-api-key-input"
      label="MailChimp API Key"
      helperText="enter your MailChimp API key to enable newsletter signups"
      inline
    >
      <InputGroup
        id="mailchimp-api-key-input"
        placeholder="01234567890abcdefghijklmnopqrstuv-us1"
        value={settings.mailchimp ? settings.mailchimp.apiKey || '' : ''}
        onChange={e => onChange({ ...settings, mailchimp: { apiKey: e.target.value } })}
      />
    </FormGroup>
  </GeneralSettings>
);
