import styled, { css } from 'styled-components';

export default styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  max-width: 1200px;

  ${({ stretch }) =>
    stretch &&
    css`
      position: relative;
      min-height: calc(100vh - 50px);
    `}

  ${({ theme }) => theme.media.tablet`
    max-width: 100%;
  `}
  ${({ theme }) => theme.media.desktop`
    max-width: 700px;
  `}
  ${({ theme }) => theme.media.giant`
    max-width: 930px;
  `}
`;
