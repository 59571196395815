import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import Breadcrumb from '/components/Breadcrumb';
import SignupEditor from '/components/SignupEditor';
import Debug from '/components/Debug';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import withProjects from '/containers/withProjects';
import withRouter from '/containers/withRouter';
import ResourceNotFound from '/components/ResourceNotFound';
import {
  SignupFields,
  SignupMutable,
  ProjectLocales,
  filter,
  prepareNodeMutation,
} from '/data/fragments';

const QUERY = gql`
  query Signup($id: String) {
    signup(selector: { id: { eq: $id } }) {
      ...SignupFields
    }
    project: currentProject {
      ...ProjectLocales
    }
  }
  ${SignupFields}
  ${ProjectLocales}
`;

const UPDATE = gql`
  mutation UpdateSignup($input: UpdateSignupInput!) {
    updateSignup(input: $input) {
      signup {
        ...SignupFields
      }
    }
  }
  ${SignupFields}
`;

const DELETE = gql`
  mutation DeleteSignup($input: DeleteSignupInput!) {
    deleteSignup(input: $input) {
      signup {
        ...SignupFields
      }
    }
  }
  ${SignupFields}
`;

const withUpdate = Comp => props => (
  <Mutation mutation={UPDATE}>
    {(updateSignup, { loading }) => (
      <Comp {...props} saving={loading} updateSignup={updateSignup} />
    )}
  </Mutation>
);

const withDelete = Comp => props => (
  <Mutation mutation={DELETE}>
    {(deleteSignup, { loading }) => (
      <Comp {...props} deleting={loading} deleteSignup={deleteSignup} />
    )}
  </Mutation>
);

class SignupView extends Component {
  goBack = () => this.props.history.push(this.props.projectUrl(`/campaigns`));

  handleGoBackClick = () => this.goBack();

  render() {
    const {
      match: {
        params: { id },
      },
      historyPush,
      projectUrl,
      saving,
      deleting,
    } = this.props;
    return (
      <Query query={QUERY} variables={{ id }} pollInterval={10000}>
        {({ data: { signup = {}, project = {} } = {}, loading, error }) =>
          loading ? (
            <Loading fill />
          ) : error ? (
            <ErrorCallout message="Something went wrong" />
          ) : !signup ? (
            <ResourceNotFound name="Signup" onGoBackClick={this.handleGoBackClick} />
          ) : (
            <React.Fragment>
              <Breadcrumb
                items={[
                  { text: 'Campaigns', url: projectUrl('/campaigns') },
                  { text: 'Signups' },
                  { text: signup.title || id },
                ]}
              />
              <SignupEditor
                signup={signup}
                locales={project?.settings?.i18n?.locales}
                saving={saving}
                deleting={deleting}
                onSave={e =>
                  this.props.updateSignup({
                    variables: {
                      input: {
                        id,
                        signup: prepareNodeMutation(filter(SignupMutable, e)),
                      },
                    },
                  })
                }
                onDelete={async () => {
                  await this.props.deleteSignup({
                    variables: {
                      input: { id },
                    },
                  });
                  historyPush(projectUrl(`/campaigns`));
                }}
              />
              <hr />
              <Debug data={signup} />
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default compose(withDelete, withUpdate, withProjects, withRouter)(SignupView);
