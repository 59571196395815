import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';

const withState = connect(
  () => ({}),
  dispatch => ({
    historyPush: url => dispatch(push(url)),
  }),
);

export default compose(withState, withRouter);
