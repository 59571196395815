import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, MultiSelect } from '@blueprintjs/select';
import { MenuItem, Button } from '@blueprintjs/core';

const GeneralSettings = styled.div`
  .bp3-label {
    width: 160px;
  }
`;

const transformLocales = locales => ({
  mainLocale: locales.find(locale => locale.primary),
  locales,
});

export default props => {
  const { availableLocales = [] } = props;
  const { mainLocale, locales = [] } = transformLocales(props.i18n.locales);

  const [selectedLocales, setSelectedLocales] = useState(
    locales.filter(x => !x.primary).map(x => x.locale.id),
  );
  const [selectedMainLocale, setSelectedMainLocale] = useState(mainLocale.locale.id);

  const updateSelectedLocales = _locales => {
    setSelectedLocales(_locales).filter(locale => locale.id !== selectedMainLocale);
  };

  useEffect(() => {
    const formattedLocales = selectedLocales
      .map(_locale => ({
        locale: availableLocales.find(l => l.id === _locale),
        primary: false,
      }))
      .concat([{ locale: availableLocales.find(l => l.id === selectedMainLocale), primary: true }]);
    props.onChange({ locales: formattedLocales });
  }, [selectedMainLocale, selectedLocales]);

  return (
    <GeneralSettings>
      <p>Primary language</p>
      {
        <Select
          items={availableLocales
            .map(locale => locale.id)
            .filter(locale => locale !== selectedMainLocale)}
          itemRenderer={(item, { handleClick, active }) => (
            <MenuItem onClick={handleClick} active={active} text={item} />
          )}
          onItemSelect={x => {
            setSelectedMainLocale(x);
            setSelectedLocales(selectedLocales.filter(locale => locale !== x));
          }}
          itemPredicate={(query, item) =>
            item.toLowerCase().indexOf((query || '').toLowerCase()) >= 0
          }
          noResults={<MenuItem disabled text="No results." />}
          filterable
        >
          <Button>{selectedMainLocale}</Button>
        </Select>
      }
      <p>Other languages</p>
      <MultiSelect
        items={availableLocales
          .map(locale => locale.id)
          .filter(locale => !selectedLocales.includes(locale))
          .filter(locale => locale !== selectedMainLocale)}
        selectedItems={selectedLocales}
        itemRenderer={(item, { handleClick, active }) => (
          <MenuItem onClick={handleClick} active={active} text={item} />
        )}
        onItemSelect={item =>
          selectedLocales.filter((_item, i, arr) => arr[i] === item).length > 0
            ? null
            : updateSelectedLocales([...selectedLocales, item])
        }
        itemPredicate={(query, item) =>
          item.toLowerCase().indexOf((query || '').toLowerCase()) >= 0
        }
        filterable
        resetOnSelect
        noResults={<MenuItem disabled text="No results." />}
        tagRenderer={item => item}
        tagInputProps={{
          onRemove: item => updateSelectedLocales(selectedLocales.filter(_item => _item !== item)),
        }}
      />
    </GeneralSettings>
  );
};
