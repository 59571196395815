import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import Breadcrumb from '/components/Breadcrumb';
import EventEditor from '/components/EventEditor';
import Debug from '/components/Debug';
import Loading from '/components/Loading';
import ErrorCallout from '/components/ErrorCallout';
import withProjects from '/containers/withProjects';
import withRouter from '/containers/withRouter';
import ResourceNotFound from '/components/ResourceNotFound';
import {
  EventFields,
  EventMutable,
  ProjectLocales,
  filter,
  prepareNodeMutation,
} from '/data/fragments';

const QUERY = gql`
  query Event($id: String!) {
    event(selector: { id: { eq: $id } }) {
      ...EventFields
    }
    project: currentProject {
      ...ProjectLocales
    }
  }
  ${EventFields}
  ${ProjectLocales}
`;

const UPDATE = gql`
  mutation UpdateEvent($id: String!, $event: EventInput!) {
    updateEvent(input: { id: $id, event: $event }) {
      event {
        ...EventFields
      }
    }
  }
  ${EventFields}
`;

const DELETE = gql`
  mutation DeleteEvent($id: String!) {
    deleteEvent(input: { id: $id }) {
      event {
        ...EventFields
      }
    }
  }
  ${EventFields}
`;

const withUpdate = Comp => props => (
  <Mutation mutation={UPDATE}>
    {updateEvent => <Comp {...props} updateEvent={updateEvent} />}
  </Mutation>
);

const withDelete = Comp => props => (
  <Mutation mutation={DELETE}>
    {(deleteEvent, { loading }) => <Comp {...props} deleting={loading} deleteEvent={deleteEvent} />}
  </Mutation>
);

class EventView extends Component {
  goBack = () => this.props.history.push(this.props.projectUrl(`/campaigns`));

  handleGoBackClick = () => this.goBack();

  render() {
    const {
      match: {
        params: { id },
      },
      historyPush,
      projectUrl,
      deleting,
    } = this.props;
    return (
      <Query query={QUERY} variables={{ id }} pollInterval={10000}>
        {({ data: { event = {}, project = {} } = {}, loading, error }) =>
          loading ? (
            <Loading fill />
          ) : error ? (
            <ErrorCallout message="Something went wrong" />
          ) : !event ? (
            <ResourceNotFound name="Event" onGoBackClick={this.handleGoBackClick} />
          ) : (
            <React.Fragment>
              <Breadcrumb
                items={[
                  { text: 'Campaigns', url: projectUrl('/campaigns') },
                  { text: 'Events' },
                  { text: event.title || id },
                ]}
              />
              <EventEditor
                event={event}
                locales={project?.settings?.i18n?.locales}
                deleting={deleting}
                attendees={event.attendees}
                onSave={e =>
                  this.props.updateEvent({
                    variables: {
                      id,
                      event: prepareNodeMutation(filter(EventMutable, e)),
                    },
                  })
                }
                onDelete={async () => {
                  await this.props.deleteEvent({
                    variables: {
                      id,
                    },
                  });
                  historyPush(projectUrl(`/campaigns`));
                }}
              />
              <hr />
              <Debug data={event} />
            </React.Fragment>
          )
        }
      </Query>
    );
  }
}

export default compose(withDelete, withUpdate, withProjects, withRouter)(EventView);
